import React, { useEffect, useState } from "react";
import "./TopBar.css";
import logo from "../../assets/Agrigate.png";
import { notification, Badge, Card, Button, Tooltip } from "antd";
import { BellOutlined } from "@ant-design/icons";
import Pusher from "pusher-js";
import axios from "axios";
import agrigateLogo from "../../assets/Agrigate.png";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import LogoutIcon from "@mui/icons-material/Logout";
import authService from "../Authentication/authService";

export default function TopBar() {
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage?.getItem("userProfile"))
  );
  const [dateTime, setDateTime] = useState(new Date());
  const [api, contextHolder] = notification.useNotification();
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const formatDateTime = (date) => {
    const dateString = date.toLocaleDateString([], {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${dateString} - ${timeString}`;
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${baseUrl}/employee/notifications/`, {
        headers: authHeader(),
      });
      setNotifications(response.data.slice(0, 10));
      setIsLoaded(true);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const toggleNotifications = () => {
    if (!showNotifications && !isLoaded) {
      fetchNotifications();
    }
    setShowNotifications((prev) => !prev);
    setHasNewNotifications(false);
  };

  useEffect(() => {
    const pusher = new Pusher("ee73ced07cdb62f71da8", {
      cluster: "ap1",
    });
    const channel = pusher.subscribe(`user-${userProfile?.id}`);
    channel.bind("notification", (data) => {
      api.open({
        message: <span style={{ fontWeight: "bold" }}>{data?.message}</span>,
        icon: <img src={agrigateLogo} width={30} alt="logo" />,
      });
      setHasNewNotifications(true);
    });

    return () => {
      pusher.unsubscribe(`user-${userProfile?.id}`);
    };
  }, [api, userProfile?.id]);

  const logoutHandle = () => {
    authService.logout();
  };

  return (
    <>
      {contextHolder}
      <div
        className="topBar"
        style={{ backgroundColor: "#57775B", color: "white" }}
      >
        <div className="ps-4 d-flex justify-content-center align-items-center">
          <img src={logo} alt="" width={30} />
          <p className="m-0 px-1">
            <strong>Agrigate Network Limited</strong>
          </p>
        </div>
        <div>{formatDateTime(dateTime)}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <p className="m-0 pe-3">
            Welcome!{" "}
            <strong className="">
              {userProfile?.first_name} {userProfile?.last_name}
            </strong>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid lightgray",
              borderRadius: "50%",
              height: "35px",
              width: "35px",
              backgroundColor: "white",
              borderColor: "#57775B",
            }}
          >
            <Tooltip title="Notification">
              <Badge
                dot={hasNewNotifications && !showNotifications}
                style={{ color: "white", borderColor: "gray" }}
              >
                <BellOutlined
                  onClick={toggleNotifications}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "#46604A",
                  }}
                />
              </Badge>
            </Tooltip>
          </div>

          {showNotifications && (
            <Card
              style={{
                position: "absolute",
                top: "40px",
                right: "0",
                width: "600px",
                maxHeight: "200px",
                overflowY: "auto",
                zIndex: 10,
                border: "solid 1px lightgray",
              }}
            >
              {notifications.length ? (
                <>
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                        padding: "5px",
                        border: "solid 1px lightgray",
                      }}
                    >
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#718A74",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        {notification?.sender?.first_name[0]}
                        {notification?.sender?.last_name[0]}
                      </div>

                      <div>
                        <p className="m-0" style={{ fontWeight: "bold" }}>
                          {notification?.sender?.first_name}{" "}
                          {notification?.sender?.last_name}
                        </p>
                        <p className="m-0">{notification?.message}</p>
                        {notification?.link && (
                          <a
                            href={notification?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#46604A" }}
                          >
                            View Details
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                  <Button
                    type="link"
                    style={{
                      padding: 0,
                      color: "#46604A",
                      fontWeight: "bolder",
                    }}
                  >
                    See All Notification
                  </Button>
                </>
              ) : (
                <p>No notifications</p>
              )}
            </Card>
          )}
          <div className="ps-4">
            <Tooltip title="Logout">
              <Button
                onClick={logoutHandle}
                style={{
                  color: "#46604A",
                  backgroundColor: "white",
                  borderColor: "#57775B",
                }}
              >
                <LogoutIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
