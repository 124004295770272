import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Button, Image, Input, Modal } from "antd";
import Swal from "sweetalert2";
import baseUrl from "../../config";

export default function RetailerProfile() {
  const [retailerProfileDetails, setRetailerProfileDetails] = useState([]);
  const [printBtnVisible, setPrintBtnVisible] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { retailer_id } = useParams();
  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/retailerProfile/${retailer_id}/`)
      .then((data) => {
        console.log(data?.data);
        setRetailerProfileDetails(data?.data);
      });
  }, [retailer_id]);

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberCheck = () => {
    if (parseInt(phoneNumber) === parseInt(retailerProfileDetails?.phone)) {
      setOpenModal(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Phone number didn't match",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handlePrint = () => {
    setPrintBtnVisible(false);
    document.title = `Farmer Profile -${retailerProfileDetails?.record_id}`;
    setTimeout(() => {
      window.print();
      window.close();
      document.title = "Agrigate Dashboard";
      setPrintBtnVisible(true);
    }, 5000);
  };

  return (
    <>
      <div></div>
      <Modal
        title="Please enter registered retailer phone number (রেজিস্টার্ড রিটেলের এর মোবাইল নম্বর )"
        centered
        closable={false}
        open={openModal}
        footer={[
          <Button key="submit" style={{backgroundColor:"#46604A", color:"white"}} onClick={handlePhoneNumberCheck}>
            OK
          </Button>,
        ]}
      >
        <Input
          placeholder="Retailer Phone Number"
          inputMode="numeric"
          onChange={handlePhoneNumber}
        />
      </Modal>
      <div
        className="p-5 container"
        style={{ display: openModal ? "none" : "block" }}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Retailer Information
        </div>
        <div className="d-flex justify-content-end">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <th className="p-2 w-25">RID Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.retailer_id}
                  </td>
                </tr>
                <tr>
                  <th className="p-2 w-25">Retailer Name</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Retailer's Phone Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {"0" + retailerProfileDetails?.phone}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">National ID</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.nid}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">District</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.district?.district_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Upazila</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.upazila?.upazila_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Union</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.union?.union_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Village</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {retailerProfileDetails?.village}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="w-50 ms-5">
            <p style={{ color: "#46604A" }}>
              <strong>Retailer Image:</strong>
            </p>
            <Image
              className="border"
              width={350}
              height={250}
              src={retailerProfileDetails?.profile_image}
              alt="Retailer Image"
            />
          </div>
        </div>
        {/* <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          KYC Info
        </div> */}

       
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Product Buying Info
        </div>
        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Date</th>
              <th>Invoice</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Amount</th>
             
            </tr>
            {retailerProfileDetails?.product_buying_info?.map((product, index) => (
              <tr key={index}>
                <td className="py-2">{(product?.created_at)?.slice(0, 10)}</td>
                <td>{product?.sales_id}</td>
                <td>{product?.invoice_products__product__product_name}</td>
                <td>{product?.invoice_products__quantity}</td>
                <td>{(product?.invoice_products__product_total_price)?.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
