import { Button, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import { EyeOutlined } from "@ant-design/icons";
import CropTicketModal from "./ConsultationModals/CropTicketModal";
import LivestockTicketModel from "./ConsultationModals/LivestockTicketModel";
import CropFollowupModal from "./ConsultationModals/CropFollowupModal";
import LivestockFollowupModal from "./ConsultationModals/LivestockFollowupModal";

export default function ConsultantDashboard() {
  const [cropTicketAssigned, setCropTicketAssigned] = useState([]);
  const [livestockTicketAssigned, setLivestockTicketAssigned] = useState([]);
  const [livestockFollowupTicketList, setLivestockFollowupTicketList] =
    useState([]);
  const [cropFollowupTicketList, setCropFollowupTicketList] = useState([]);

  const [currentPageCropTicket, setCurrentPageCropTicket] = useState(1);
  const [currentPageCropFollowup, setCurrentPageCropFollowup] = useState(1);
  const [currentPageLivestockTicket, setCurrentPageLivestockTicket] =
    useState(1);
  const [currentPageLivestockFollowup, setCurrentPageLivestockFollowup] =
    useState(1);
  const [totalItemsCropTicket, setTotalItemsCropTicket] = useState(0);
  const [totalItemsLivestockTicket, setTotalItemsLivestockTicket] = useState(0);
  const [totalItemsCropFollowup, setTotalItemsCropFollowup] = useState(0);
  const [totalItemsLivestockFollowup, setTotalItemsLivestockFollowup] =
    useState(0);
  const [cropTicketModal, setCropTicketModal] = useState(false);
  const [livestockTicketModal, setLivestockTicketModal] = useState(false);
  const [cropFollowupModal, setCropFollowupModal] = useState(false);
  const [livestockFollowupModal, setLivestockFollowupModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const fetchCropTicket = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/consultantCropTicket/${userProfile?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: currentPageCropTicket,
          },
        }
      );
      setTotalItemsCropTicket(response?.data?.count);
      setCropTicketAssigned(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLivestockTicket = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/consultantLivestockTicket/${userProfile?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: currentPageLivestockTicket,
          },
        }
      );
      setTotalItemsLivestockTicket(response?.data?.count);
      setLivestockTicketAssigned(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFollowupLivestockList = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/followupLivestockList/${userProfile?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: currentPageLivestockFollowup,
          },
        }
      );
      setTotalItemsLivestockFollowup(response?.data?.count);
      setLivestockFollowupTicketList(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFollowupCropList = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/consultation/followupCropList/${userProfile?.employee_id}`,
        {
          headers: authHeader(),
          params: {
            page: currentPageLivestockFollowup,
          },
        }
      );
      setTotalItemsCropFollowup(response?.data?.count);
      setCropFollowupTicketList(response?.data?.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //============use effects=====================//

  useEffect(() => {
    fetchCropTicket();
    if (userProfile && userProfile.employee_id) {
      fetchCropTicket();
    }
  }, [currentPageCropTicket, userProfile]);

  useEffect(() => {
    fetchLivestockTicket();
    if (userProfile && userProfile.employee_id) {
      fetchLivestockTicket();
    }
  }, [currentPageLivestockTicket, userProfile]);

  useEffect(() => {
    fetchFollowupLivestockList();
    if (userProfile && userProfile.employee_id) {
      fetchFollowupLivestockList();
    }
  }, [currentPageLivestockFollowup, userProfile]);

  useEffect(() => {
    fetchFollowupCropList();
    if (userProfile && userProfile.employee_id) {
      fetchFollowupCropList();
    }
  }, [currentPageLivestockFollowup, userProfile]);

  const handleCropTicketPageChange = (value) => {
    setCurrentPageCropTicket(value);
  };
  const handleCropFollowUpPageChange = (value) => {
    setCurrentPageCropFollowup(value);
  };
  const handleLivestockTicketPageChange = (value) => {
    setCurrentPageLivestockTicket(value);
  };
  const handleLivestockFollowUpPageChange = (value) => {
    setCurrentPageLivestockFollowup(value);
  };

  const handleCropTicketModal = (record) => {
    setCropTicketModal(true);
    setSelectedData(record);
  };
  const handleCropModalCancel = () => {
    setCropTicketModal(false);
    setSelectedData(null);
  };
  const handleLivestockTicketModal = (record) => {
    setLivestockTicketModal(true);
    setSelectedData(record);
  };
  const handleLivestockModalCancel = () => {
    setLivestockTicketModal(false);
    setSelectedData(null);
  };

  const handleCropFollowupModal = (record) => {
    setCropFollowupModal(true);
    setSelectedData(record);
  };
  const handleCropFollowupModalCancel = () => {
    setCropFollowupModal(false);
    setSelectedData(null);
  };
  const handleLivestockFollowupModal = (record) => {
    setLivestockFollowupModal(true);
    setSelectedData(record);
  };
  const handleLivestockFollowupModalCancel = () => {
    setLivestockFollowupModal(false);
    setSelectedData(null);
  };

  const cropTicketAssignedColumn = [
    {
      title: "Date",
      dataIndex: "opening_date",
      width: "15%",
    },
    {
      title: "Ticket",
      dataIndex: "ticket_id",
      width: "20%",
    },
    {
      title: "FO Name",
      render: (record) =>
        record?.employee?.user_info?.first_name +
        " " +
        record?.employee?.user_info?.last_name,
      width: "20%",
    },
    {
      title: "FO Phone",
      render: (record) => "0" + record?.employee?.phone,
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button
            className="d-flex align-items-center"
            onClick={() => handleCropTicketModal(record)}
          >
            view <EyeOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];
  const livestockTicketAssignedColumn = [
    {
      title: "Date",
      dataIndex: "opening_date",
      width: "15%",
    },
    {
      title: "Ticket",
      dataIndex: "ticket_id",
      width: "20%",
    },
    {
      title: "FO Name",
      render: (record) =>
        record?.employee?.user_info?.first_name +
        " " +
        record?.employee?.user_info?.last_name,
      width: "20%",
    },
    {
      title: "FO Phone",
      render: (record) => "0" + record?.employee?.phone,
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button
            className="d-flex align-items-center"
            onClick={() => handleLivestockTicketModal(record)}
          >
            view <EyeOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const cropFollowUpColumn = [
    {
      title: "Follow Up",
      dataIndex: "followup_date",
      width: "10%",
    },
    {
      title: "Ticket",
      render: (record) => record?.ticket?.ticket_id,
      width: "10%",
    },
    {
      title: "Followup Status",
      dataIndex: "followup_status",
      width: "15%",
    },
    {
      title: "FO Name",
      render: (record) =>
        record?.ticket?.employee?.user_info?.first_name +
        " " +
        record?.ticket?.employee?.user_info?.last_name,
      width: "20%",
    },
    {
      title: "FO Phone",
      render: (record) => "0" + record?.ticket?.employee?.phone,
      width: "15%",
    },
    {
      title: "Status",
      render: (record) => record?.ticket?.status,
      width: "15%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button
            className="d-flex align-items-center"
            onClick={() => handleCropFollowupModal(record)}
          >
            view <EyeOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const livestockFollowUpColumn = [
    {
      title: "Follow Up",
      dataIndex: "followup_date",
      width: "10%",
    },
    {
      title: "Ticket",
      render: (record) => record?.ticket?.ticket_id,
      width: "10%",
    },
    {
      title: "Followup Status",
      dataIndex: "followup_status",
      width: "15%",
    },
    {
      title: "FO Name",
      render: (record) =>
        record?.ticket?.employee?.user_info?.first_name +
        " " +
        record?.ticket?.employee?.user_info?.last_name,
      width: "20%",
    },
    {
      title: "FO Phone",
      render: (record) => "0" + record?.ticket?.employee?.phone,
      width: "15%",
    },
    {
      title: "Status",
      render: (record) => record?.ticket?.status,
      width: "15%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button
            className="d-flex align-items-center"
            onClick={() => handleLivestockFollowupModal(record)}
          >
            view <EyeOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const items = [
    {
      key: "Crop",
      label: (
        <div className="text-center" style={{ width: "710px", color:'#46604A'}}>
          Crop
        </div>
      ),
      children: (
        <>
          <div
            className="pe-2 pb-3"
            style={{ overflow: "auto", height: "460px" }}
          >
            <p
              className="text-center py-2 border"
              style={{ backgroundColor: "#46604A" , color:'white'}}
            >
              TICKET ASSIGNED
            </p>
            <Table
              className="mt-4 border"
              columns={cropTicketAssignedColumn}
              dataSource={cropTicketAssigned}
              rowKey={(record) => record.ticket_id}
              scroll={{ y: 250 }}
              pagination={{
                current: currentPageCropTicket,
                total: totalItemsCropTicket,
                pageSize: 10,
                onChange: handleCropTicketPageChange,
              }}
            />
          </div>
          <div
            className="pe-2 pb-3"
            style={{ overflow: "auto", height: "460px" }}
          >
            <p
              className="text-center py-2 border"
              style={{ backgroundColor: "#46604A", color:'white'}}
            >
              FOLLOW UP
            </p>
            <Table
              className="mt-4 border"
              columns={cropFollowUpColumn}
              dataSource={cropFollowupTicketList}
              rowKey={(record) => record.created_at}
              scroll={{ y: 250 }}
              pagination={{
                current: currentPageCropFollowup,
                total: totalItemsCropFollowup,
                pageSize: 10,
                onChange: handleCropFollowUpPageChange,
              }}
            />
          </div>
        </>
      ),
    },
    {
      key: "Livestock",
      label: (
        <div className="text-center" style={{ width: "710px",color:'#46604A'}}>
          Livestock
        </div>
      ),
      children: (
        <>
          <div
            className="pe-2 pb-3"
            style={{ overflow: "auto", height: "460px" }}
          >
            <p
              className="text-center py-2 border"
              style={{ backgroundColor: "#46604A", color:'white'}}
            >
              TICKET ASSIGNED
            </p>
            <Table
              className="mt-4 border"
              columns={livestockTicketAssignedColumn}
              dataSource={livestockTicketAssigned}
              rowKey={(record) => record.ticket_id}
              //   loading={consultantLoading}
              scroll={{ y: 250 }}
              pagination={{
                current: currentPageLivestockTicket,
                total: totalItemsLivestockTicket,
                pageSize: 10,
                onChange: handleLivestockTicketPageChange,
              }}
            />
          </div>
          <div
            className="pe-2 pb-3"
            style={{ overflow: "auto", height: "460px" }}
          >
            <p
              className="text-center py-2 border"
              style={{ backgroundColor: "#46604A", color:'white' }}
            >
              FOLLOW UP
            </p>
            <Table
              className="mt-4 border"
              columns={livestockFollowUpColumn}
              dataSource={livestockFollowupTicketList}
              rowKey={(record) => record.created_at}
              scroll={{ y: 250 }}
              pagination={{
                current: currentPageLivestockFollowup,
                total: totalItemsLivestockFollowup,
                pageSize: 10,
                onChange: handleLivestockFollowUpPageChange,
              }}
            />
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <CropTicketModal
        visible={cropTicketModal}
        onCancel={handleCropModalCancel}
        data={selectedData}
        fetchCropTicket={fetchCropTicket}
        fetchFollowupCropList={fetchFollowupCropList}
      />
      <LivestockTicketModel
        visible={livestockTicketModal}
        onCancel={handleLivestockModalCancel}
        data={selectedData}
        fetchLivestockTicket={fetchLivestockTicket}
        fetchFollowupLivestockList={fetchFollowupLivestockList}
      />
      <CropFollowupModal
        visible={cropFollowupModal}
        onCancel={handleCropFollowupModalCancel}
        data={selectedData}
        fetchFollowupCropList={fetchFollowupCropList}
      />
      <LivestockFollowupModal
        visible={livestockFollowupModal}
        onCancel={handleLivestockFollowupModalCancel}
        data={selectedData}
        fetchFollowupLivestockList={fetchFollowupLivestockList}
      />
      <div className="consultationDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>CONSULTANT DASHBOARD</h5>
        </div>
        <div className="consultationDashboardContainer shadow-sm rounded">
          <div className="mainView">
            <Tabs
              className="px-4 mt-4 custom-tabs"
              defaultActiveKey="projects"
              items={items}
            />
          </div>
        </div>
      </div>
    </>
  );
}
