import React, { useEffect, useRef, useState } from "react";
import "./CashSalesDashboard.css";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Divider, Modal, Select, Table, Tooltip } from "antd";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";
import { DateRangePicker } from "rsuite";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { PrinterOutlined, EyeOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

export default function CashSalesDashboard() {
  const { Option } = Select;
  const [centerList, setCenterList] = useState();
  const [invoiceLists, setInvoiceLists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPurchaseRevenueData, setTotalPurchaseRevenueData] =
    useState(null);

  const [loading, setLoading] = useState(false);
  const [orderTakingLoading, setOrderTakingLoading] = useState(false);
  const [foListLoading, setFoListLoading] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [stockLoading, setStockLoading] = useState(false);
  const [salesLoading, setSalesLoading] = useState(false);

  const [orderTakenList, setOrderTakenList] = useState([]);
  const [currentPageOrderTaken, setCurrentPageOrderTaken] = useState(1);
  const [totalItemsOrderTaken, setTotalItemsOrderTaken] = useState(0);

  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [currentPagePurchase, setCurrentPagePurchase] = useState(1);
  const [totalItemsPurchase, setTotalItemsPurchase] = useState(0);

  const [stockList, setStockList] = useState([]);
  const [currentPageStock, setCurrentPageStock] = useState(1);
  const [totalItemsStock, setTotalItemsStock] = useState(0);

  const [distinctValues, setDistinctValue] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [data, setData] = useState(null);

  const [orderTakenSelectedDateRange, setOrderTakenSelectedDateRange] =
    useState(null);
  const [orderTakenSelectedDistrict, setOrderTakenSelectedDistrict] =
    useState("");
  const [orderTakenSelectedProduct, setOrderTakenSelectedProduct] =
    useState("");

  const [foList, setFoList] = useState([]);
  const [foTargetList, setFoTargetList] = useState([]);
  const [selectedFO, setSelectedFO] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  const [revenueSelectedDateRange, setRevenueSelectedDateRange] =
    useState(null);

  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const handleOrderTakenDateRage = (value) => {
    setOrderTakenSelectedDateRange(value);
  };
  const handleOrderTakenDistrict = (value) => {
    setOrderTakenSelectedDistrict(value);
  };
  const handleOrderTakenProduct = (value) => {
    setOrderTakenSelectedProduct(value);
  };
  const handleOrderTakenPageChange = (value) => {
    setCurrentPageOrderTaken(value);
  };

  const [purchaseSelectedDateRange, setPurchaseSelectedDateRange] =
    useState(null);

  const [purchaseSelectedProduct, setPurchaseSelectedProduct] = useState("");

  const handlePurchaseDateRage = (value) => {
    setPurchaseSelectedDateRange(value);
  };
  const handleRevenueDateRage = (value) => {
    setRevenueSelectedDateRange(value);
  };
  const handlePurchasePageChange = (value) => {
    setCurrentPagePurchase(value);
  };
  const handlePurchaseProduct = (value) => {
    setPurchaseSelectedProduct(value);
  };

  const [stockSelectedCenter, setStockSelectedCenter] = useState("");
  const [stockSelectedProduct, setStockSelectedProduct] = useState("");

  const handleStockCenter = (value) => {
    setStockSelectedCenter(value);
  };
  const handleStockProduct = (value) => {
    setStockSelectedProduct(value);
  };
  const handleStockPageChange = (value) => {
    setCurrentPageStock(value);
  };

  const [totalSalesSelectedDateRange, setTotalSalesSelectedDateRange] =
    useState([]);
  const [totalSalesSelectedCenter, setTotalSalesSelectedCenter] = useState("");
  const [totalSalesSelectedType, setTotalSalesSelectedType] = useState("");

  const handleTotalSalesDateRage = (value) => {
    setTotalSalesSelectedDateRange(value);
  };
  const handleTotalSalesCenter = (value) => {
    setTotalSalesSelectedCenter(value);
  };
  const handleTotalSalesType = (value) => {
    setTotalSalesSelectedType(value);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleViewModal = (value) => {
    setViewModal(true);
    setData(value);
  };
  const handleInvoiceModalClose = () => {
    setViewModal(false);
  };
  const modalRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    onAfterPrint: () => {
      handleInvoiceModalClose();
    },
  });

  const handleFoSelection = (value) => {
    setSelectedFO(value);
  };
  const handleMonthSelection = (value) => {
    setSelectedMonth(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSalesLoading(true);
        const response = await axios.get(
          `${baseUrl}/inventory/full_sales_list/`,
          {
            headers: authHeader(),
            params: {
              page: currentPage,
              center: totalSalesSelectedCenter,
              type: totalSalesSelectedType,
              dateRange: totalSalesSelectedDateRange,
            },
          }
        );
        setInvoiceLists(response?.data?.results);
        setTotalItems(response?.data?.count);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setSalesLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    totalSalesSelectedType,
    totalSalesSelectedDateRange,
    totalSalesSelectedCenter,
  ]);

  const fetchPurchaseList = () => {
    setPurchaseLoading(true);
    axios
      .get(`${baseUrl}/inventory/purchase_list/${userProfile?.employee_id}/`, {
        headers: authHeader(),
        params: {
          dateRange: purchaseSelectedDateRange,
          page: currentPagePurchase,
          product_name: purchaseSelectedProduct,
        },
      })
      .then((response) => {
        setPurchaseDetails(response?.data?.results);
        setTotalItemsPurchase(response?.data?.count);
        setPurchaseLoading(false);
      })
      .catch((error) => {
        setPurchaseLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchPurchaseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagePurchase, purchaseSelectedDateRange, purchaseSelectedProduct]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCenterList(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    setOrderTakingLoading(true);
    axios
      .get(`${baseUrl}/retailer/orderTakingData/`, {
        headers: authHeader(),
        params: {
          page: currentPageOrderTaken,
          dateRange: orderTakenSelectedDateRange,
          district: orderTakenSelectedDistrict,
          product_name: orderTakenSelectedProduct,
        },
      })
      .then((response) => {
        setOrderTakenList(response?.data?.results);
        setTotalItemsOrderTaken(response?.data?.count);
        setOrderTakingLoading(false);
      })
      .catch((error) => {
        setOrderTakingLoading(false);
        console.log(error);
      });
  }, [
    orderTakenSelectedDateRange,
    currentPageOrderTaken,
    orderTakenSelectedDistrict,
    orderTakenSelectedProduct,
  ]);

  useEffect(() => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/inventory/stock_consolidated_list/`, {
        headers: authHeader(),
        params: {
          page: currentPageStock,
          center_id: stockSelectedCenter,
          product_name: stockSelectedProduct,
        },
      })
      .then((response) => {
        setStockList(response?.data?.results);
        setTotalItemsStock(response?.data?.count);
        setStockLoading(false);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
      });
  }, [currentPageStock, stockSelectedCenter, stockSelectedProduct]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/inventory_distinct_data/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setDistinctValue(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/purchase_revenue_data/`, {
        headers: authHeader(),
        params: {
          dateRange: revenueSelectedDateRange,
        },
      })
      .then((response) => {
        setTotalPurchaseRevenueData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [revenueSelectedDateRange]);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycby6lFsVpCdFbxcHHGZoOhxOrvWiJkiNQ3Rwi8vmU2TFU218ijmM-V3ajKRFrGD2GqkF/exec`
      )
      .then((response) => {
        setFoList(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    setFoListLoading(true);
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbwhj8WlZwIEmfjU4Jm9o75BPPtMvD36XA9oOIvqBXeSkvdtFgnp_i9ERn0AF7UmL7ng/exec?foName=${selectedFO}&&month=${selectedMonth}`
      )
      .then((response) => {
        setFoListLoading(false);
        setFoTargetList(response?.data?.data);
      })
      .catch((error) => {
        setFoListLoading(false);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, [selectedFO, selectedMonth]);

  const purchaseColumn = [
    {
      title: "Date",
      width: "20%",
      render: (record) =>
        record?.created_at
          ? new Date(record.created_at).toISOString().slice(0, 10)
          : "",
    },
    {
      title: (
        <div className="row">
          <div className="col-sm">Product</div>
          <div className="col-sm">Quantity</div>
        </div>
      ),
      width: "50%",
      render: (record) => {
        if (record?.stock_products) {
          return record.stock_products.map((product, index) => (
            <div className="row" key={index}>
              <div className="col-sm border">
                {product?.product?.product_name}
              </div>
              <div className="col-sm border">{product?.stock_in}</div>
            </div>
          ));
        }
        return null; // or return a placeholder message
      },
    },
    {
      title: "Total Paid",
      render: (record) => record?.total_paid?.toLocaleString(),
      width: "30%",
    },
  ];

  const orderTakenColumn = [
    {
      title: "Date",
      dataIndex: "order_date",
      width: "30%",
    },
    {
      title: (
        <div className="row">
          <div className="col-sm">Product</div>
          <div className="col-sm">Quantity</div>
          <div className="col-sm">Price</div>
        </div>
      ),
      width: "70%",
      render: (record) => {
        if (record?.ordered_products) {
          return record.ordered_products.map((product, index) => (
            <div className="row" key={index}>
              <div className="col-sm border">
                {product?.product?.product_name_english}
              </div>
              <div className="col-sm border">{product?.quantity}</div>
              <div className="col-sm border" key={index + 3}>
                {product?.product_total_price}
              </div>
            </div>
          ));
        }
        return null;
      },
    },
  ];

  function calculateNetTotal(data) {
    let netTotal = 0;

    data?.invoice_products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      const productTotalPrice = parseInt(product.product_total_price);
      const productNetTotal = productTotalPrice + discount;
      netTotal += productNetTotal;
    });

    return parseInt(netTotal).toLocaleString();
  }

  function calculateTotalDiscount(data) {
    let totalDiscount = 0;

    data?.invoice_products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      totalDiscount += discount;
    });

    return parseInt(totalDiscount).toLocaleString();
  }

  const stockColumn = [
    {
      title: "Product",
      dataIndex: "product__product_name",
      width: "25%",
    },
    {
      title: "Stock",
      render: (record) => parseInt(record?.total_quantity),
      width: "15%",
    },
  ];
  const salesColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
    },
    {
      title: "Location",
      render: (record) => record?.center?.center_name,
    },
    {
      title: "Sale ID",
      dataIndex: "sales_id",
    },
    {
      title: "Customer",
      render: (record) =>
        record?.farmer?.name ||
        record?.retailer?.name ||
        record?.institutional?.institutional_buyer_name,
    },
    {
      title: "Customer Phone",
      render: (record) =>
        "0" +
        (record?.farmer?.phone ||
          record?.retailer?.phone ||
          record?.institutional?.institutional_buyer_phone),
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type",
    },
    {
      title: "View",
      render: (record) => (
        <div>
          <Tooltip title="View Invoice">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const productTableColumn = [
    {
      title: "Product",
      render: (record) => record?.product?.product_name,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
    },
    {
      title: "MRP",
      dataIndex: "sale_rate",
    },
    {
      title: "DISCOUNT",
      render: (record) => record?.discount || 0,
    },
    {
      title: "AMOUNT",
      dataIndex: "product_total_price",
    },
  ];

  const foTargetColumn = [
    {
      title: "Location",
      dataIndex: "Location",
      width: "25%",
    },
    {
      title: "FO Name",
      dataIndex: "FO",
      width: "25%",
    },
    {
      title: "Year",
      dataIndex: "Year",
      width: "25%",
    },
    {
      title: "Month",
      dataIndex: "Months",
      width: "25%",
    },
    {
      title: "Target",
      dataIndex: "Monthly_Target",
      width: "25%",
    },
    {
      title: "Total Achieved",
      dataIndex: "Total_Achieved",
      width: "25%",
    },
    {
      title: "Achievement",
      render: (record) => `${(record?.Achievement * 100).toFixed(1)}%`,
      width: "25%",
    },
    {
      title: "Days Worked",
      dataIndex: "No_of_Days_Worked",
      width: "25%",
    },
    {
      title: "Evaluation",
      dataIndex: "Performance_Evaluation",
      width: "25%",
    },
    {
      title: "Comment",
      dataIndex: "Comments",
      width: "25%",
    },
  ];

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h5 style={{ color: "#46604A" }}>SALES DETAILS</h5>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="ms-3">
          <DateRangePicker
            className="mt-3 mb-0 pb-0"
            onChange={handleRevenueDateRage}
            value={revenueSelectedDateRange}
            style={{ width: "250px" }}
          />
        </div>
        <div className="totalBoxSection">
          <div className="totalCardDesign">
            <p className="ms-3 mt-2" style={{ fontSize: "16px" }}>
              Total Purchase (BDT)
            </p>
            <p className="ms-3" style={{ fontSize: "20px" }}>
              {totalPurchaseRevenueData?.total_purchase_amount?.toLocaleString()}
            </p>
          </div>
          <div className="totalCardDesign">
            <p className="ms-3 mt-2" style={{ fontSize: "16px" }}>
              Total Revenue (BDT)
            </p>
            <p className="ms-3" style={{ fontSize: "20px" }}>
              {totalPurchaseRevenueData?.total_revenue?.toLocaleString()}
            </p>
          </div>
        </div>
        <div
          className="pt-3 m-2 pb-3"
          style={{
            boxShadow: "#46604A 1px 3px 5px",
          }}
        >
          <div className="cardFilterDesign mb-2">
            <div className="d-flex mb-4">
              <div className="ms-3 mb-1">
                <p className="m-0">Field Officer: </p>
                <Select
                  style={{ width: "200px" }}
                  showSearch
                  placeholder="Select FO"
                  onChange={handleFoSelection}
                >
                  <Option value="">All FO</Option>
                  {foList?.distinctFO?.map((fo, index) => (
                    <Option value={fo} key={index}>
                      {fo}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mb-1">
                <p className="m-0">Month: </p>
                <Select
                  style={{ width: "200px" }}
                  showSearch
                  placeholder="Select Month"
                  onChange={handleMonthSelection}
                >
                  <Option value="">All Months</Option>
                  {foList?.distinctMonth?.map((month, index) => (
                    <Option value={month} key={index}>
                      {month}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Table
              columns={foTargetColumn}
              dataSource={foTargetList}
              loading={foListLoading}
              scroll={{ y: 210 }}
              rowKey={(record, index) => index}
            />
          </div>
          <div className="totalTableSection">
            <div className="salesCardDesign">
              <p className="m-3" style={{ fontSize: "18px" }}>
                Order Taken
              </p>
              <div className="d-flex border-bottom">
                <div className="ms-3 mb-1">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    onChange={handleOrderTakenDateRage}
                    value={orderTakenSelectedDateRange}
                    style={{ width: "190px" }}
                    showOneCalendar
                  />
                </div>
                <div className="ms-1 mb-1">
                  <p className="m-0">Location: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select District"
                    showSearch
                    onChange={handleOrderTakenDistrict}
                    value={orderTakenSelectedDistrict}
                  >
                    <Option value="">All Districts</Option>
                    {distinctValues?.order_taken_districts?.map(
                      (district, index) => (
                        <Option value={district} key={index}>
                          {district}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className="ms-1 mb-1">
                  <p className="m-0">Product: </p>
                  <Select
                    style={{ width: "120px" }}
                    showSearch
                    placeholder="Select Product"
                    onChange={handleOrderTakenProduct}
                    value={orderTakenSelectedProduct}
                  >
                    <Option value="">All Products</Option>
                    {distinctValues?.order_taken_products?.map(
                      (product, index) => (
                        <Option value={product} key={index}>
                          {product}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
              </div>
              <Table
                columns={orderTakenColumn}
                dataSource={orderTakenList}
                loading={orderTakingLoading}
                scroll={{ y: 210 }}
                rowKey={(record) => record?.order_id}
                pagination={{
                  current: currentPageOrderTaken,
                  pageSize: 10,
                  total: totalItemsOrderTaken,
                  onChange: handleOrderTakenPageChange,
                }}
              />
              <Link to="orderTaking">
                <Button className="mt-2 ms-3">View More</Button>
              </Link>
            </div>
            <div className="salesCardDesign">
              <p className="m-3" style={{ fontSize: "18px" }}>
                Purchase List
              </p>
              <div className="d-flex border-bottom mb-4">
                <div className="ms-3 mb-1">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    onChange={handlePurchaseDateRage}
                    value={purchaseSelectedDateRange}
                    style={{ width: "190px" }}
                    showOneCalendar
                  />
                </div>
                <div className="ms-1 mb-1">
                  <p className="m-0">Product: </p>
                  <Select
                    style={{ width: "120px" }}
                    showSearch
                    placeholder="Select Product"
                    onChange={handlePurchaseProduct}
                    value={purchaseSelectedProduct}
                  >
                    <Option value="">All Products</Option>
                    {distinctValues?.products?.map((product, index) => (
                      <Option value={product} key={index}>
                        {product}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <Table
                columns={purchaseColumn}
                dataSource={purchaseDetails}
                loading={purchaseLoading}
                scroll={{ y: 210 }}
                rowKey={(record) => record.purchase_id}
                pagination={{
                  current: currentPagePurchase,
                  pageSize: 10,
                  total: totalItemsPurchase,
                  onChange: handlePurchasePageChange,
                }}
              />
              <Link to="managePurchase">
                <Button className="mt-2 ms-3">View More</Button>
              </Link>
            </div>
            <div className="salesCardDesign">
              <p className="m-3" style={{ fontSize: "18px" }}>
                Stock List
              </p>
              <div className="d-flex border-bottom mb-4">
                <div className="ms-2 mb-1">
                  <p className="m-0">Centers: </p>
                  <Select
                    style={{ width: "120px" }}
                    placeholder="Select location"
                    showSearch
                    onChange={handleStockCenter}
                    value={stockSelectedCenter}
                  >
                    <Option value="">All Centers</Option>
                    {centerList?.map((center, index) => (
                      <Option
                        value={center?.center?.employee_id}
                        key={center?.center?.employee_id}
                      >
                        {center?.center_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="ms-1 mb-1">
                  <p className="m-0">Product: </p>
                  <Select
                    style={{ width: "120px" }}
                    showSearch
                    placeholder="Select Product"
                    onChange={handleStockProduct}
                    value={stockSelectedProduct}
                  >
                    <Option value="">All Products</Option>
                    {distinctValues?.products?.map((product, index) => (
                      <Option value={product} key={index}>
                        {product}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <Table
                columns={stockColumn}
                dataSource={stockList}
                loading={stockLoading}
                scroll={{ y: 210 }}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPageStock,
                  pageSize: 10,
                  total: totalItemsStock,
                  onChange: handleStockPageChange,
                }}
              />
              <Link to="stock">
                <Button className="mt-2 ms-3">View More</Button>
              </Link>
            </div>
          </div>
        </div>
        <section
          className="mx-2 mt-5"
          style={{ boxShadow: "#46604A 1px 3px 5px" }}
        >
          <h5 className="text-center pt-4 pb-2 border-bottom">Sales Details</h5>
          <div className="d-flex border-bottom mb-4">
            <div className="ms-3 mb-1">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleTotalSalesDateRage}
                value={totalSalesSelectedDateRange}
                style={{ width: "190px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-1">
              <p className="m-0">Center: </p>
              <Select
                style={{ width: "200px" }}
                placeholder="Select center"
                showSearch
                onChange={handleTotalSalesCenter}
                value={totalSalesSelectedCenter}
              >
                <Option value="">All Locations</Option>
                {centerList?.map((center, index) => (
                  <Option
                    value={center?.center?.employee_id}
                    key={center?.center?.employee_id}
                  >
                    {center?.center_name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-1 mb-1">
              <p className="m-0">Type: </p>
              <Select
                style={{ width: "200px" }}
                showSearch
                placeholder="Select Type"
                onChange={handleTotalSalesType}
                value={totalSalesSelectedType}
              >
                <Option value="">All Types</Option>
                {distinctValues?.sales_customer_type?.map((type, index) => (
                  <Option value={type} key={index}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={salesColumn}
            dataSource={invoiceLists}
            loading={salesLoading}
            rowKey={(record) => record?.sales_id}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: totalItems,
              onChange: handlePageChange,
            }}
          />
        </section>
        <Modal
          className="mb-5"
          style={{ marginTop: "80px" }}
          open={viewModal}
          onCancel={handleInvoiceModalClose}
          footer={false}
          centered
        >
          <div ref={modalRef} className="p-3">
            <h5 className="text-center" style={{ color: "#46604A" }}>
              <strong>Agrigate Network Limited</strong>
            </h5>
            <h6 className="text-center" style={{ color: "#46604A" }}>
              Center - {data?.center?.center_name}
            </h6>
            <div
              className="d-flex justify-content-around my-4 pt-3 fw-medium"
              style={{
                color: "#46604A",
                fontSize: "16px",
                border: "solid 1px #46604A",
              }}
            >
              <div className="w-50 ms-3">
                <p className="mb-0">Invoice:</p>
                <p>Date:</p>

                <p className="mb-0">Customer Name:</p>
                <p>Customer Number:</p>
              </div>
              <div className="w-50">
                <p className="mb-0">
                  <strong>{data?.sales_id}</strong>
                </p>
                <p>{new Date(data?.created_at).toLocaleString()}</p>
                <p className="mb-0">
                  <strong>
                    {data?.farmer?.name ||
                      data?.retailer?.name ||
                      data?.institutional?.institutional_buyer_name}
                  </strong>
                </p>
                <p>
                  {"0" +
                    (data?.farmer?.phone ||
                      data?.retailer?.phone ||
                      data?.institutional?.institutional_buyer_phone)}
                </p>
              </div>
            </div>
            <Divider
              style={{
                background: "transparent",
                border: "1px dashed #808080",
              }}
            />
            <Table
              columns={productTableColumn}
              dataSource={data?.invoice_products}
              pagination={false}
              rowKey={(record) => record?.batch_id}
              size="small"
            />
            <div className="text-end me-2 mt-3">
              <p>Total MRP: {calculateNetTotal(data)} BDT</p>
              <p>Total Discount: {calculateTotalDiscount(data)} BDT</p>
              <p className="fw-bold">
                Net Payable: {parseInt(data?.total_price)} BDT
              </p>
              <p className="fw-bold">
                Total Paid: {parseInt(data?.total_paid)} BDT
              </p>
              <p>Due: {parseInt(data?.due)} BDT</p>
            </div>
            <Divider
              style={{
                background: "transparent",
                border: "1px dashed #808080",
              }}
            />
            <p className="text-center">
              যে কোন পরামর্শ এবং সহায়তার জন্য এগ্রিগেট নেটওয়ার্ক লিমিটেড এর
              হটলাইনে যোগাযোগ করুনঃ ০১৩২৪৭৪১৬১৯,০১৩২৪৭৪১৬২০
            </p>
            <Divider
              style={{
                background: "transparent",
                border: "1px dashed #808080",
              }}
            />
            <p className="text-center">
              Powered by <strong>Agrigate Network Limited</strong>
            </p>
          </div>
          <Button
            className="d-flex align-items-center fw-bold"
            style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            onClick={handlePrint}
          >
            <PrinterOutlined />
            Print
          </Button>
        </Modal>
      </div>
    </div>
  );
}
