import axios from "axios";
import baseUrl from "../../config";

const AuthService = {
  async login(payload) {
    const response = await axios.post(`${baseUrl}/api/token/`, {
      username: payload.loginUsername,
      password: payload.loginPassword,
    });
    const results = response.data;
    if (results.access && results.refresh) {
      const now = Math.floor(Date.now() / 1000); // Current time in seconds
      const refreshTokenExpiration = now + 86400; // 24 hours in seconds
      localStorage.setItem("access_token", JSON.stringify(results.access));
      localStorage.setItem("refresh_token", JSON.stringify(results.refresh));
      localStorage.setItem(
        "token_expiration_time",
        JSON.stringify(refreshTokenExpiration)
      ); 
      localStorage.setItem("isLoggedIn", "true");
    }
    return response;
  },
  logout() {
    localStorage.clear();
    const cookieNames = [
      "ot_access_id",
      "ot_refresh_id",
      "REACT_APP_COOKIE_DOMAIN",
    ];
    cookieNames.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.agrigate.network; path=/;`;
    });
    window.location.reload(false);
  },

  async refreshToken() {
    try {
      const refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
      if (!refresh_token) {
        this.logout();
        throw new Error("No refresh token available.");
      }

      const response = await axios.post(`${baseUrl}/api/token/refresh/`, {
        refresh: refresh_token,
      });

      const { access, refresh } = response.data;

      if (access && refresh) {
        localStorage.setItem("access_token", JSON.stringify(access));
        localStorage.setItem("refresh_token", JSON.stringify(refresh));
      }

      return response;
    } catch (error) {
      this.logout();
      throw error;
    }
  },
};

export default AuthService;
