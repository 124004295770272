import React, { useEffect, useState } from "react";
import { Button, Input, Select, Table } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { DateRangePicker } from "rsuite";
import TransferStockModal from "./TransferStockModal";

export default function TransferStock() {
  const { Option } = Select;
  const { Search } = Input;
  const [transferredList, setTransferredList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [centerIds, setCenterIds] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState(null);
  const [transferStockModal, setTransferStockModal] = useState(false);
  const [transferredListLoading, setTransferredListLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCenterList(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const fetchTransferredList = () => {
    setTransferredListLoading(true);
    axios
      .get(`${baseUrl}/inventory/transferred_list/`, {
        headers: authHeader(),
        params: {
          searched_center: selectedCenter,
          dateRange: selectedDateRange,
          page: currentPage,
          product: searchedProduct,
        },
      })
      .then((response) => {
        setTransferredListLoading(false);
        setTransferredList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setTransferredListLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchTransferredList();
  }, [currentPage, selectedCenter, selectedDateRange, searchedProduct]);

  const handleDateRage = (value) => {
    if (value?.length === 2) {
      const startDate = new Date(value[0]);
      const endDate = new Date(value[1]);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      const updatedDateRange = [startDate, endDate];
      setSelectedDateRange(updatedDateRange);
    } else {
      setSelectedDateRange(null);
    }
  };

  const handleCenter = (value) => {
    setSelectedCenter(value);
  };
  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };
  const handleTransferStockModal = () => {
    setTransferStockModal(!transferStockModal);
  };
  const handleSearch = (value) => {
    setSearchedProduct(value);
  };

  const transferredColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toLocaleDateString()
          : "",
      width: "15%",
    },

    {
      title: "From",
      render: (record) => record?.center_from?.center_name,
      width: "20%",
    },
    {
      title: "To",
      render: (record) => record?.center_to?.center_name,
      width: "20%",
    },
    {
      title: "Batch ID",
      render: (record) => record?.batch_id,
      width: "15%",
    },
    {
      title: "Product",
      render: (record) => record?.product?.product_name,
      width: "20%",
    },
    {
      title: "Transfer Quantity",
      render: (record) => record?.transfer_quantity,
      width: "10%",
    },
  ];
  return (
    <>
      <TransferStockModal
        visible={transferStockModal}
        modalClose={handleTransferStockModal}
        fetchTransferredList={fetchTransferredList}
      />
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>TRANSFER STOCK DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <div>
                  <Select
                    placeholder="Select Center"
                    className="me-3"
                    onChange={handleCenter}
                    style={{ width: "200px" }}
                  >
                    <Option value="">All Center</Option>
                    {centerList?.map((center) => (
                      <Option
                        value={center?.center?.employee_id}
                        key={center?.center?.employee_id}
                      >
                        {center?.center_name}
                      </Option>
                    ))}
                  </Select>
                  <DateRangePicker
                    onChange={handleDateRage}
                    value={selectedDateRange}
                    style={{ width: "230px" }}
                    showOneCalendar
                  />
                  <Search
                    className="ms-2"
                    style={{ width: "280px" }}
                    placeholder="Search product ..."
                    allowClear
                    onSearch={handleSearch}
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#46604A",
                          borderColor: "#46604A",
                          color: "white",
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#46604A",
                      borderColor: "#46604A",
                      color: "white",
                    }}
                    onClick={handleTransferStockModal}
                  >
                    Transfer Stock
                  </Button>
                </div>
              </div>
              <Table
                className="border"
                columns={transferredColumn}
                dataSource={transferredList}
                loading={transferredListLoading}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
