import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
} from "antd";
import axios from "axios";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";

export default function TicketUpdateModal({
  visible,
  onCancel,
  data,
  issueTypeList,
  employeeList,
  consultantList,
  fetchTicketList,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      opening_date: data?.opening_date,
      ticket_id: data?.ticket_id,
      farmer_name: data?.farmer?.name,
      farmer_contact: "0" + data?.farmer?.phone,
      farmer: data?.farmer?.farmer_id,
      district: data?.district,
      consultation_type: data?.consultation_type,
      issue_type: data?.issue_type?.issue_type_id,
      consultant: data?.consultant?.consultant_id,
      employee: data?.employee?.employee_id,
    });
  }, [data, form]);

  const handleCancel = () => {
    onCancel();
  };

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
            district: data?.data?.district?.district_name,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const onFinish = (data) => {
    axios
      .patch(`${baseUrl}/consultation/ticketUpdate/${data?.ticket_id}/`, data, {
        headers: authHeader(),
      })
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Ticket Info Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            onCancel();
            fetchTicketList();
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <Modal
      title="Ticket Update"
      centered
      open={visible}
      onCancel={handleCancel}
      footer={null}
      width="40%"
    >
      <Form
        className="mt-2"
        onFinish={onFinish}
        form={form}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Ticket Date"
          name="opening_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket date required",
            },
          ]}
        >
          <Input placeholder="Ticket Date" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Ticket"
          name="ticket_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket ID required",
            },
          ]}
        >
          <Input placeholder="Ticket" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Name"
          name="farmer_name"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer name required",
            },
          ]}
        >
          <Input placeholder="Farmer Name" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Contact"
          name="farmer_contact"
          onChange={handleFarmerInfo}
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please enter farmer's phone number",
            },
            {
              pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
              message: "Please enter a valid phone number",
            },
          ]}
        >
          <Input placeholder="Phone Number" />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Farmer ID"
          name="farmer"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer ID required",
            },
          ]}
        >
          <Input placeholder="Farmer ID" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="District"
          name="district"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "District name required",
            },
          ]}
        >
          <Input placeholder="District Name" readOnly />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Consultation Type"
          name="consultation_type"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Consultation type required",
            },
          ]}
        >
          <Select placeholder="Select consultation type" showSearch>
            <Option value="Livestock">Livestock</Option>
            <Option value="Crop">Crop</Option>
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Crop/Livestock Name"
          name="crop_or_livestock_name"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Crop/Livestock name required",
            },
          ]}
        >
         <Input placeholder="Crop/Livestock Name"/>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Issue Type"
          name="issue_type"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Issue type required",
            },
          ]}
        >
          <Select placeholder="Select issue" showSearch>
            {issueTypeList?.map((issueType, index) => (
              <Option value={issueType?.issue_type_id} key={index}>
                {issueType?.issue_type}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Consultant Assign"
          name="consultant"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please select consultant",
            },
          ]}
        >
          <Select placeholder="Select consultant" showSearch>
            {consultantList?.map((consultant, index) => (
              <Option value={consultant?.consultant_id} key={index}>
                {consultant?.employee?.user_info?.first_name +
                  " " +
                  consultant?.employee?.user_info?.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="FO Assign"
          name="employee"
          style={{ marginBottom: 8 }}
        >
          <Select placeholder="Select FO" showSearch>
            {employeeList?.map((employee, index) => (
              <Option key={index} value={employee?.employee_id}>
                {employee?.user_info?.first_name +
                  " " +
                  employee?.user_info?.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: "right", margin: 0 }}>
          <Button
            style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            htmlType="submit"
            disabled={loading}
          >
            Update
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
