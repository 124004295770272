import React, { useState } from "react";
import { Form, Input, Button, Spin, Modal } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";

export default function CompanyCreateModal({
  visible,
  handleAddCompanyModalClose,
}) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (data) => {
    axios
      .post(
        `${baseUrl}/ip/ip_create/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
            handleAddCompanyModalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  return (
    <Modal
      title="Add Company"
      open={visible}
      onCancel={handleAddCompanyModalClose}
      footer={false}
    >
      <Form
        className="register-form"
        form={form}
        onFinish={handleSubmit}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Company Name"
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please enter company name",
            },
          ]}
          labelCol={{ span: 8 }}
        >
          <Input placeholder="Company Name" />
        </Form.Item>

        <div className="d-flex justify-content-end">
          <Form.Item>
            <Button
              htmlType="submit"
              disabled={loading}
              style={{
                backgroundColor: "#46604A",
                color: "#ffffff",
              }}
            >
              Submit
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
