import "./ProductSourcingDashboard.css";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Select, Table, Input, Tooltip } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import { DateRangePicker } from "rsuite";
import WorkOrderCreateModal from "./WorkOrderCreateModal";
import { EyeOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import SourcingBuyingModal from "./SourcingBuyingModal";
import WorkOrderEditModal from "./WorkOrderEditModal";
import TransactionSalesModal from "./TransactionSalesModal";
import WorkOrderDetailsModal from "./WorkOrderDetailsModal";
import TransactionSalesUpdateModal from "./TransactionSalesUpdateModal";
import CompanyCreateModal from "./CompanyCreateModal";

export default function ProductSourcingDashboard() {
  const { Option } = Select;
  const { Search } = Input;
  const chartRef = useRef(null);
  const [sourcingInfo, setSourcingInfo] = useState(null);
  const [sourcingProductsList, setSourcingProductsList] = useState([]);
  const [district, setDistrict] = useState([]);
  const [workOrderInfo, setWorkOrderInfo] = useState([]);
  const [allWorkOrder, setAllWorkOrder] = useState([]);
  const [workOrderModal, setWorkOrderModal] = useState(false);
  const [marketPriceModal, setMarketPriceModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [todaysData, setTodaysData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [marketPriceData, setMarketPriceData] = useState([]);
  const [sourcingBuyingInfo, setSourcingBuyingInfo] = useState([]);
  const [buyingPageNumber, setBuyingPageNumber] = useState(1);
  const [totalBuyingItems, setTotalBuyingItems] = useState(0);
  const [transactionSalesPageNumber, setTransactionSalesPageNumber] =
    useState(1);
  const [transactionSalesTotalItems, setTransactionSalesTotalItems] =
    useState(0);
  const [transactionSalesInfo, setTransactionSalesInfo] = useState([]);
  const [filter, setFilter] = useState("");
  const [transactionSalesFilter, setTransactionSalesFilter] = useState("");
  const [workOrderFilter, setWorkOrderFilter] = useState("");
  const [sourcingBuyingModal, setSourcingBuyingModal] = useState(false);
  const [sourcingBuyingModalData, setSourcingBuyingModalData] = useState(null);

  const [addCompanyModal, setAddCompanyModal] = useState(false);

  const [workOrderEditModal, setWorkOrderEditModal] = useState(false);
  const [workOrderEditData, setWorkOrderEditData] = useState(null);

  const [workOrderDetailsModal, setWorkOrderDetailsModal] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const [transactionSalesModal, setTransactionSalesModal] = useState(false);

  const [transactionSalesUpdateModal, setTransactionSalesUpdateModal] =
    useState(false);
  const [transactionSalesUpdateModalData, setTransactionSalesUpdateModalData] =
    useState(null);

  const [sourcingInfoSelectedDateRange, setSourcingInfoSelectedDateRange] =
    useState(null);
  const [sourcingInfoSelectedProduct, setSourcingInfoSelectedProduct] =
    useState("");
  const [sourcingInfoSelectedDistrict, setSourcingInfoSelectedDistrict] =
    useState("");

  const fetchWorkOrder = async () => {
    await axios
      .get(`${baseUrl}/ip/work_order_info`, {
        headers: authHeader(),
        params: {
          page: pageNumber,
          filter: workOrderFilter,
        },
      })
      .then((data) => {
        setWorkOrderInfo(data?.data?.results);
        setTotalItems(data?.data?.count);
      });
  };

  const fetchSourcingBuying = async () => {
    axios
      .get(`${baseUrl}/sourcing/sourcing_buying_info`, {
        headers: authHeader(),
        params: {
          page: buyingPageNumber,
          filter: filter,
        },
      })
      .then((data) => {
        setSourcingBuyingInfo(data?.data?.results);
        setTotalBuyingItems(data?.data?.count);
      });
  };

  const fetchIPTransaction = async () => {
    axios
      .get(`${baseUrl}/ip/ip_selling_info`, {
        headers: authHeader(),
        params: {
          page: transactionSalesPageNumber,
          filter: transactionSalesFilter,
        },
      })
      .then((data) => {
        console.log(data?.data);
        setTransactionSalesInfo(data?.data?.results);
        setTransactionSalesTotalItems(data.data?.count);
      });
  };

  const dailyProductPrice = (data) => {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });

    const distinctProductData = {};

    filteredData.forEach((item) => {
      const productName = item.product_name;

      if (!distinctProductData[productName]) {
        distinctProductData[productName] = {
          product_name: productName,
          total_spot_price: item.spot_price,
          count: 1,
          date: item.date,
          id: item.id,
          district: item.district.district_name,
        };
      } else {
        distinctProductData[productName].total_spot_price += item.spot_price;
        distinctProductData[productName].count++;
      }
    });

    // Calculate the average spot_price for each product
    const averageProductData = Object.values(distinctProductData).map(
      (productData) => ({
        ...productData,
        avg_spot_price: (
          productData.total_spot_price / productData.count
        ).toFixed(2),
      })
    );

    setTodaysData(averageProductData);
  };

  const handleBuyingPageChange = (value) => {
    setBuyingPageNumber(value);
  };

  useEffect(() => {
    fetchWorkOrder();
  }, [pageNumber, workOrderFilter]);

  useEffect(() => {
    fetchSourcingBuying();
  }, [buyingPageNumber, filter]);

  useEffect(() => {
    fetchIPTransaction();
  }, [transactionSalesPageNumber, transactionSalesFilter]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/`, {
        headers: authHeader(),
      })
      .then((response) => setDistrict(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductsList(data?.data));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/ip/all_work_orders`, {
        headers: authHeader(),
      })
      .then((data) => setAllWorkOrder(data?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/api/price_app/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setLoading(false);
        dailyProductPrice(
          response?.data?.filter((item) => item?.created_at !== null)
        );
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/sourcingInfo`, {
        headers: authHeader(),
        params: {
          dateRange: sourcingInfoSelectedDateRange,
          district: sourcingInfoSelectedDistrict,
          product: sourcingInfoSelectedProduct,
        },
      })
      .then((response) => {
        setSourcingInfo(response?.data);
      });
  }, [
    sourcingInfoSelectedDateRange,
    sourcingInfoSelectedDistrict,
    sourcingInfoSelectedProduct,
  ]);

  useEffect(() => {
    const processedData = {};

    if (marketPriceData) {
      marketPriceData.forEach((item) => {
        const { product_name, date, spot_price } = item;
        const formattedDate = new Date(date).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        if (!processedData[product_name]) {
          processedData[product_name] = { name: product_name, data: [] };
        }

        const dateIndex = processedData[product_name].data.findIndex(
          (entry) => entry[0] === formattedDate
        );

        if (dateIndex === -1) {
          processedData[product_name].data.push([formattedDate, spot_price]);
        } else {
          processedData[product_name].data[dateIndex][1] =
            (processedData[product_name].data[dateIndex][1] + spot_price) / 2;
        }
      });

      // Sort the data by date using a custom sorting function
      Object.values(processedData).forEach((item) => {
        item.data.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      });

      setChartData(Object.values(processedData));
    }
  }, [marketPriceData]);
  const handleSourcingDateRange = (value) => {
    console.log(value);
    setSourcingInfoSelectedDateRange(value);
  };
  const handleSourcingDistrict = (value) => {
    setSourcingInfoSelectedDistrict(value);
  };
  const handleSourcingProduct = (value) => {
    setSourcingInfoSelectedProduct(value);
  };

  const handleWorkOrderModal = () => {
    setWorkOrderModal(true);
  };
  const handleWorkOrderModalClose = () => {
    setWorkOrderModal(false);
    fetchWorkOrder();
  };

  const handleCompanyModal = () => {
    setAddCompanyModal(true);
  };
  const handleCompanyModalClose = () => {
    setAddCompanyModal(false);
  };

  const handlePageChange = (value) => {
    setPageNumber(value);
  };

  const handleTransactionSalesPageChange = (value) => {
    setTransactionSalesPageNumber(value);
  };
  const handleMarketPriceModal = (record) => {
    setMarketPriceModal(true);
    axios
      .get(`${baseUrl}/api/marketPrice/${record?.product_name}`)
      .then((response) => {
        setMarketPriceData(response.data);
      });
  };
  const handleMarketPriceModalClose = () => {
    setMarketPriceModal(false);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const handleTransactionSalesFilter = (value) => {
    setTransactionSalesFilter(value);
  };
  const handleWorkOrderFilter = (value) => {
    setWorkOrderFilter(value);
  };

  const handleSourcingBuyingModal = (record) => {
    console.log(record);
    setSourcingBuyingModalData(record);
    setSourcingBuyingModal(true);
  };

  const handleSourcingBuyingModalClose = () => {
    setSourcingBuyingModal(false);
  };

  const handleWorkOrderEditModal = (record) => {
    setWorkOrderEditModal(true);
    setWorkOrderEditData(record);
  };
  const handleWorkOrderDetailsModal = (record) => {
    setWorkOrderDetailsModal(true);
    setSelectedWorkOrder(record);
  };

  const handleWorkOrderEditModalClose = () => {
    setWorkOrderEditModal(false);
  };
  const handleWorkOrderDetailsModalClose = () => {
    setWorkOrderDetailsModal(false);
  };

  const handleTransactionSalesModal = () => {
    setTransactionSalesModal(true);
  };

  const handleTransactionSalesModalClose = () => {
    setTransactionSalesModal(false);
  };

  const handleTransactionSalesUpdateModal = (record) => {
    setTransactionSalesUpdateModal(true);
    setTransactionSalesUpdateModalData(record?.work_order);
  };

  const handleTransactionSalesUpdateModalClose = () => {
    setTransactionSalesUpdateModal(false);
  };

  const workOrderColumn = [
    {
      title: "WO",
      dataIndex: "work_order_id",
      width: "10%",
    },
    {
      title: "PO",
      dataIndex: "purchase_order",
      width: "10%",
    },
    {
      title: "Date",
      dataIndex: "work_order_date",
      width: "12%",
    },
    {
      title: "Product",
      render: (record) => record?.sourcing_product?.product_name,
      width: "15%",
    },
    {
      title: "Qty (kg)",
      dataIndex: "product_quantity_kg",
      width: "10%",
    },
    {
      title: "Rate (tk/kg)",
      dataIndex: "rate_tk_per_kg",
      width: "14%",
    },
    {
      title: "Expected Delivery",
      dataIndex: "deadline",
      width: "17%",
    },
    {
      title: "Action",
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Edit">
            <Button
              size="small"
              onClick={() => handleWorkOrderEditModal(record)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Details">
            <Button
              size="small"
              onClick={() => handleWorkOrderDetailsModal(record)}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
      width: "15%",
    },
  ];

  const sourcingBuyingColumn = [
    {
      title: "GDN",
      dataIndex: "gdn_number",
      width: "10%",
    },
    {
      title: "WO",
      render: (record) => record?.work_order?.work_order_id,
      width: "12%",
    },
    {
      title: "PO",
      render: (record) => record?.work_order?.purchase_order,
      width: "12%",
    },
    {
      title: "District",
      render: (record) =>
        record?.farmer
          ? record?.farmer?.district?.district_name
          : record?.trader?.district?.district_name,
      width: "13%",
    },
    {
      title: "Seller",
      render: (record) =>
        record?.farmer ? record?.farmer?.name : record?.trader?.name,
      width: "13%",
    },
    {
      title: "Product",
      dataIndex: "product_name",
      width: "13%",
    },
    {
      title: "Purchase (qty)",
      dataIndex: "purchase_quantity_kg",
      width: "10%",
    },
    {
      title: "Purchase Rate (tk)",
      dataIndex: "purchase_rate_tk_per_kg",
      width: "10%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button onClick={() => handleSourcingBuyingModal(record)}>
            <EyeOutlined />
          </Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const dailyPriceDataColumns = [
    {
      title: "Date",
      dataIndex: "date",
      width: "20%",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      width: "25%",
    },
    {
      title: "District",
      dataIndex: "district",
      width: "20%",
    },
    {
      title: "Price (avg)",
      dataIndex: "avg_spot_price",
      width: "15%",
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <Button onClick={() => handleMarketPriceModal(record)}>View</Button>
        </div>
      ),
      width: "15%",
    },
  ];

  const saleTransactionColumn = [
    {
      title: "WO",
      render: (record) => record?.work_order?.work_order_id,
      width: "12%",
    },
    {
      title: "PO",
      render: (record) => record?.work_order?.purchase_order,
      width: "12%",
    },
    {
      title: "Company",
      render: (record) => record?.work_order?.company?.company_name,
      width: "18%",
    },
    {
      title: "Received (qty)",
      render: (record) => record?.received_quantity?.toLocaleString(),
      width: "17%",
    },
    {
      title: "Total Received",
      render: (record) => record?.total_received?.toLocaleString(),
      width: "17%",
    },
    {
      title: "GDN",
      width: "11%",
      render: (record) => record?.direct_gdn_number?.gdn_number || record?.warehouse_gdn_number?.gdn_number,
    },
    {
      title: "GRN",
      width: "11%",
      dataIndex: "grn",
    },
    {
      title: "Action",
      render: (record) => (
        <Tooltip title="Details">
          <Button
            size="small"
            onClick={() => handleTransactionSalesUpdateModal(record)}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>
      ),
      width: "15%",
    },
  ];
  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "Product Price Timeline",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Price",
      },
    },
    series: chartData,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF"],
          symbol: "download",
          text: "Export",
        },
      },
    },
  };
  return (
    <>
      <CompanyCreateModal
        visible={addCompanyModal}
        handleAddCompanyModalClose={handleCompanyModalClose}
      />
      <WorkOrderCreateModal
        visible={workOrderModal}
        handleWorkOrderModalClose={handleWorkOrderModalClose}
      />
      <SourcingBuyingModal
        visible={sourcingBuyingModal}
        data={sourcingBuyingModalData}
        handleSourcingBuyingModalClose={handleSourcingBuyingModalClose}
        workOrderInfo={allWorkOrder}
        fetchSourcingBuying={fetchSourcingBuying}
      />
      <Modal
        open={marketPriceModal}
        onCancel={handleMarketPriceModalClose}
        footer={false}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </Modal>
      <WorkOrderEditModal
        visible={workOrderEditModal}
        handleWorkOrderEditModalClose={handleWorkOrderEditModalClose}
        data={workOrderEditData}
        fetchWorkOrder={fetchWorkOrder}
      />
      <TransactionSalesModal
        visible={transactionSalesModal}
        handleTransactionSalesModalClose={handleTransactionSalesModalClose}
        workOrderInfo={allWorkOrder}
        fetchIPTransaction={fetchIPTransaction}
      />
      {workOrderDetailsModal && (
        <WorkOrderDetailsModal
          visible={workOrderDetailsModal}
          handleWorkOrderDetailsModalClose={handleWorkOrderDetailsModalClose}
          data={selectedWorkOrder}
        />
      )}
      {transactionSalesUpdateModal && (
        <TransactionSalesUpdateModal
          visible={transactionSalesUpdateModal}
          handleTransactionSalesUpdateModalClose={
            handleTransactionSalesUpdateModalClose
          }
          workOrder={transactionSalesUpdateModalData}
          fetchIPTransaction={fetchIPTransaction}
        />
      )}
      <div className="sourcing">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>PRODUCT SOURCING DASHBOARD</h5>
        </div>

        <div className="sourcingContainer">
          <div className="sourcingBoxSectionTop">
            <div className="sourcingCardDesign">
              <p
                className="mt-3 mx-1 pb-2"
                style={{
                  fontSize: "16px",
                  color: "#46604A",
                  borderBottom: "1px solid #46604A",
                }}
              >
                <strong>Sourcing Info</strong>
              </p>
              <div className="d-flex">
                <div className="ms-2">
                  <p className="m-0">Date: </p>
                  <DateRangePicker
                    style={{ width: "190px" }}
                    showOneCalendar
                    value={sourcingInfoSelectedDateRange}
                    onChange={handleSourcingDateRange}
                  />
                </div>
                <div className="ms-2">
                  <p className="m-0">Location: </p>
                  <Select
                    placeholder="Select District"
                    style={{ width: "130px" }}
                    showSearch
                    value={sourcingInfoSelectedDistrict}
                    onChange={handleSourcingDistrict}
                  >
                    <Option value="">All Districts</Option>
                    {district?.map((district, index) => (
                      <Option key={index} value={district.district_name}>
                        {district.district_name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="ms-2">
                  <p className="m-0">Product: </p>
                  <Select
                    placeholder="Select product"
                    showSearch
                    style={{ width: "130px" }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={sourcingInfoSelectedProduct}
                    onChange={handleSourcingProduct}
                  >
                    <Option value="">All Products</Option>
                    {sourcingProductsList?.map((ip) => (
                      <Option
                        key={ip.sourcing_product_id}
                        value={ip.product_name}
                      >
                        {ip.product_name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sourcingInfo mt-3">
                <div className="border sourcingInfoTotalData">
                  <h6 className="font-weight-bold">
                    <strong>Purchase Qty</strong>
                  </h6>
                  <h6>
                    {sourcingInfo?.total_purchase_quantity?.toLocaleString() ||
                      0}
                    Kg
                  </h6>
                </div>
                <div className="border sourcingInfoTotalData">
                  <h6 className="font-weight-bold">
                    <strong>Purchase Amount</strong>
                  </h6>
                  <h6>
                    {sourcingInfo?.total_purchase_amount?.toLocaleString() || 0}{" "}
                    BDT
                  </h6>
                </div>
                <div className="border sourcingInfoTotalData">
                  <h6 className="font-weight-bold">
                    <strong>Sales Amount</strong>
                  </h6>
                  <h6>
                    {sourcingInfo?.total_sale_amount?.toLocaleString() || 0} BDT
                  </h6>
                </div>
                <div className="border sourcingInfoTotalData">
                  <h6 className="font-weight-bold">
                    <strong>Sales Quantity</strong>
                  </h6>
                  <h6>
                    {sourcingInfo?.total_sale_quantity?.toLocaleString() || 0}{" "}
                    Kg
                  </h6>
                </div>
              </div>
            </div>

            <div className="sourcingCardDesign">
              <p
                className="mt-3 mx-1 pb-2"
                style={{
                  fontSize: "16px",
                  color: "#46604A",
                  borderBottom: "1px solid #46604A",
                }}
              >
                <strong>Work Order</strong>
              </p>
              <div className="d-flex justify-content-between mx-3 mb-2">
                <div className="d-flex">
                  <Button
                    className="d-flex align-items-center me-2"
                    onClick={handleCompanyModal}
                    style={{
                      backgroundColor: "#46604A",
                      color: "#ffffff",
                    }}
                  >
                    <PlusOutlined style={{ fontWeight: "bolder" }} /> Company
                  </Button>

                  <Button
                    className="d-flex align-items-center"
                    onClick={handleWorkOrderModal}
                    style={{
                      backgroundColor: "#46604A",
                      color: "#ffffff",
                    }}
                  >
                    <PlusOutlined style={{ fontWeight: "bolder" }} /> WO
                  </Button>
                </div>

                <Search
                  className="w-50"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleWorkOrderFilter}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color:"white"
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </div>

              <Table
                className="ms-1"
                columns={workOrderColumn}
                dataSource={workOrderInfo}
                scroll={{ y: 190 }}
                size="small"
                rowKey={(record) => record?.work_order_id}
                pagination={{
                  current: pageNumber,
                  total: totalItems,
                  pageSize: 10,
                  onChange: handlePageChange,
                }}
              />
            </div>
            <div className="sourcingCardDesign">
              <p
                className="mt-3 mx-1 pb-2"
                style={{
                  fontSize: "16px",
                  color: "#46604A",
                  borderBottom: "1px solid #46604A",
                }}
              >
                <strong>Market Price</strong>
              </p>
              <Table
                className="mx-2"
                columns={dailyPriceDataColumns}
                dataSource={todaysData}
                loading={loading}
                rowKey={(record) => record.id}
                size="small"
                scroll={{ y: 210 }}
                pagination={{
                  pageSize: 5,
                }}
              />
            </div>
          </div>
          <div className="sourcingBoxSectionBottom">
            <div className="sourcingCardDesign">
              <p
                className="mt-3 mx-1 pb-2"
                style={{
                  fontSize: "16px",
                  color: "#46604A",
                  borderBottom: "1px solid #46604A",
                }}
              >
                <strong>Transaction (Purchase)</strong>
              </p>
              <div className="d-flex justify-content-end me-3 mb-2">
                <Search
                  className="w-50"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleFilterChange}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color:"white"
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </div>
              <Table
                className="ms-1"
                columns={sourcingBuyingColumn}
                dataSource={sourcingBuyingInfo}
                scroll={{ y: 180 }}
                size="small"
                rowKey={(record) => record?.gdn_number}
                pagination={{
                  current: buyingPageNumber,
                  total: totalBuyingItems,
                  pageSize: 10,
                  onChange: handleBuyingPageChange,
                }}
              />
            </div>
            <div className="sourcingCardDesign">
              <p
                className="mt-3 mx-1 pb-2"
                style={{
                  fontSize: "16px",
                  color: "#46604A",
                  borderBottom: "1px solid #46604A",
                }}
              >
                <strong>Transaction (Sale)</strong>
              </p>
              <div className="d-flex justify-content-between mx-3 mb-2">
                <Button
                  onClick={handleTransactionSalesModal}
                  style={{
                    backgroundColor: "#46604A",
                    color: "#ffffff",
                  }}
                >
                  Add Transaction
                </Button>
                <Search
                  className="w-50"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleTransactionSalesFilter}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color:"white"
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </div>

              <Table
                className="ms-1"
                columns={saleTransactionColumn}
                dataSource={transactionSalesInfo}
                scroll={{ y: 190 }}
                size="small"
                rowKey={(record) => record?.id}
                pagination={{
                  current: transactionSalesPageNumber,
                  total: transactionSalesTotalItems,
                  pageSize: 10,
                  onChange: handleTransactionSalesPageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
