import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { DateRangePicker } from "rsuite";
import {
  PlusOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  Modal,
  Table,
  Tooltip,
} from "antd";
import WarehouseStockOutUpdateModal from "./WarehouseStockOutUpdateModal";

export default function WarehouseStockOut() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [stockList, setStockList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [stockLoading, setStockLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [viewStockInModal, setViewStockInModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [grnList, setGrnList] = useState([]);
  const [selectedGRNs, setSelectedGRNs] = useState([]);
  const [allWorkOrder, setAllWorkOrder] = useState([]);
  const [warehouseUpdateModalView, setWarehouseUpdateModalView] =
    useState(false);
  const [transportationUpdateData, setTransportationUpdateData] =
    useState(null);

  const handleSalesDateRage = (value) => {
    setSelectedDateRange(value);
  };

  const handleWarehouse = (value) => {
    setSelectedWarehouse(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleStockInModal = () => {
    setViewStockInModal(true);
  };
  const handleStockInModalClose = () => {
    setViewStockInModal(false);
    form.resetFields();
    setSelectedGRNs([]);
  };

  const fetchGRNList = () => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/grn_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockLoading(false);
        setGrnList(response?.data);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
      });
  };

  const fetchStockOutList = () => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/warehouse_stock_out_list/`, {
        headers: authHeader(),
        params: {
          searched_warehouse_id: selectedWarehouse,
          dateRange: selectedDateRange,
          page: currentPage,
        },
      })
      .then((response) => {
        setStockLoading(false);
        setStockList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setStockLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchStockOutList();
  }, [currentPage, selectedWarehouse, selectedDateRange]);

  useEffect(() => {
    fetchGRNList();
  }, []);

  useEffect(() => {
    setStockLoading(true);
    axios
      .get(`${baseUrl}/sourcing/warehouse_info/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockLoading(false);
        setWarehouseList(response?.data);
      })
      .catch((error) => {
        setStockLoading(true);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/ip/all_work_orders`, {
        headers: authHeader(),
      })
      .then((data) => setAllWorkOrder(data?.data))
      .catch((error) => console.log(error));
  }, []);

  const handleGRNSelection = (value, index) => {
    const updatedSelectedGRNs = selectedGRNs.filter(
      (grn, grnIndex) => grnIndex !== index
    );
    updatedSelectedGRNs.push(value);
    setSelectedGRNs(updatedSelectedGRNs);

    const selectedGRNData = grnList.find((grn) => grn.grn === value);

    const newValues = { ...form.getFieldsValue() };
    if (selectedGRNData) {
      newValues.products[index].remaining_stock_sack =
        selectedGRNData.remaining_stock_sack;
      newValues.products[index].remaining_stock_kg =
        selectedGRNData.remaining_stock_kg;
      newValues.products[index].product_name = selectedGRNData.product_name;
      newValues.products[index].price_per_kg =
        parseFloat(selectedGRNData?.total_purchase_cost) /
        parseFloat(selectedGRNData?.stock_in_kg);
    } else {
      newValues.products[index].remaining_stock_sack = undefined;
      newValues.products[index].remaining_stock_kg = undefined;
      newValues.products[index].product_name = undefined;
      newValues.products[index].price_per_kg = undefined;
    }
    form.setFieldsValue({ ...newValues });
  };

  const handleSackQuantityChange = () => {
    calculateTotalSack();
  };
  const handleKgQuantityChange = (value, index) => {
    const quantity = value.target.value;
    
    const newValues = { ...form.getFieldsValue() };
    newValues.products[index].stock_out_value = (parseFloat(newValues.products[index].price_per_kg)* quantity);
    form.setFieldsValue({ ...newValues });
    calculateTotalKg();
    calculateTotalStockOutValue();
  };

  const calculateTotalSack = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalSackSum = products.reduce((sum, product) => {
      return sum + (parseInt(product.quantity_sack) || 0);
    }, 0);

    form.setFieldValue("total_sack", newTotalSackSum);
  };

  const calculateTotalKg = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalSackSum = products.reduce((sum, product) => {
      return sum + (parseInt(product.quantity_kg) || 0);
    }, 0);

    form.setFieldValue("total_quantity", newTotalSackSum);
  };

  const calculateTotalStockOutValue = () => {
    const formValues = form.getFieldsValue();
    const products = formValues.products || [];
    const newTotalStockOutValueSum = products.reduce((sum, product) => {
      return sum + (parseInt(product.stock_out_value) || 0);
    }, 0);

    form.setFieldValue("total_stock_out_value", newTotalStockOutValueSum);
  };

  const handleRemoveGDN = (index) => {
    const updatedSelectedGDNs = [...selectedGRNs];
    updatedSelectedGDNs.splice(index, 1); // Remove the GDN at the specified index
    setSelectedGRNs(updatedSelectedGDNs); // Update the selected GDNs state
  };

  const handleWarehouseUpdateModal = (record) => {
    setWarehouseUpdateModalView(true);
    setTransportationUpdateData(record);
  };
  const handleWarehouseUpdateModalClose = () => {
    setWarehouseUpdateModalView(false);
    fetchStockOutList();
  };

  const handleSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/sourcing/warehouse_stock_out/`,
        {
          data: data,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setLoading(false);

        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: "Warehouse Stock Out Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            fetchGRNList();
            fetchStockOutList();
            handleStockInModalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const stockColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
      width: "10%",
    },
    {
      title: "Warehouse",
      render: (record) => record?.warehouse?.warehouse_name,
      width: "15%",
    },
    {
      title: "GDN",
      dataIndex: "gdn_number",
      width: "10%",
    },

    {
      title: "Work Order",
      render: (record) => record?.work_order?.work_order_id,
      width: "10%",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: "10%",
    },
    {
      title: "Total Sack",
      dataIndex: "total_sack",
      width: "10%",
    },
    {
      title: "Total Quantity",
      dataIndex: "total_quantity",
      width: "10%",
    },
    {
      title: "Transport Rent Status",
      dataIndex: "transport_rent_status",
      width: "10%",
    },
    {
      title: "Action",
      render: (record) => (
        <Tooltip title="Update">
          <Button
            size="small"
            onClick={() => handleWarehouseUpdateModal(record)}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>
      ),
      width: "15%",
    },
  ];
  return (
    <>
      <WarehouseStockOutUpdateModal
        viewModal={warehouseUpdateModalView}
        handleWarehouseUpdateModalClose={handleWarehouseUpdateModalClose}
        data={transportationUpdateData}
      />
      <Modal
        open={viewStockInModal}
        onCancel={handleStockInModalClose}
        width="85%"
        footer={false}
        title="Warehouse Stock Out"
      >
        <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
          <div
            className="shadow-sm p-3"
            style={{ border: "1px solid #46604A" }}
          >
            <Form
              form={form}
              color="#46604A"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <div className="d-flex justify-content-between">
                <Col style={{ width: "250px" }}>
                  <Form.Item
                    hasFeedback
                    name="warehouse_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select warehouse",
                      },
                    ]}
                  >
                    <Select placeholder="Select Warehouse">
                      {warehouseList?.map((warehouse) => (
                        <Option
                          value={warehouse?.warehouse_id}
                          key={warehouse?.warehouse_id}
                        >
                          {warehouse?.warehouse_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="gdn_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter gdn number",
                      },
                    ]}
                  >
                    <Input placeholder="GDN Number" />
                  </Form.Item>
                </Col>
                <Col style={{ width: "200px" }}>
                  <Form.Item
                    hasFeedback
                    name="work_order_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select work order",
                      },
                    ]}
                  >
                    <Select placeholder="Select Work Order">
                      {allWorkOrder?.map((work_order) => (
                        <Option
                          value={work_order?.work_order_id}
                          key={work_order?.work_order_id}
                        >
                          {work_order?.work_order_id}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </div>

              <Form.List name="products" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <div
                    className="p-3 mb-5"
                    style={{ color: "#46604A", border: "1px solid #46604A" }}
                  >
                    <Col lg={4}>
                      <Form.Item>
                        <Button
                          type="default"
                          onClick={() => add()}
                          block
                          size="small"
                          icon={<PlusOutlined />}
                          style={{ color: "#000000" }}
                        >
                          Add More
                        </Button>
                      </Form.Item>
                    </Col>
                    {fields.length === 0 && (
                      <Space key="initial" align="baseline">
                        <Col style={{ width: "150px" }}>
                          <Form.Item
                            label="GRN"
                            name={[0, `grn`]}
                            rules={[
                              {
                                required: true,
                                message: "GRN is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select GRN"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) => handleGRNSelection(value, 0)}
                            >
                              {grnList
                                .filter(
                                  (grn) => !selectedGRNs.includes(grn.grn)
                                )
                                .map((grn) => (
                                  <Option key={grn?.grn} value={grn?.grn}>
                                    {grn?.grn}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Remaining (Sack)"
                            name={[0, `remaining_stock_sack`]}
                          >
                            <Input
                              placeholder="Remaining Stock (Sack)"
                              readOnly
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Remaining (kg)"
                            name={[0, `remaining_stock_kg`]}
                          >
                            <Input
                              placeholder="Remaining Stock (Kg)"
                              readOnly
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Product Name"
                            name={[0, `product_name`]}
                            rules={[
                              {
                                required: true,
                                message: "Product name required",
                              },
                            ]}
                          >
                            <Input placeholder="Product Name" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Price/Kg"
                            name={[0, `price_per_kg`]}
                            rules={[
                              {
                                required: true,
                                message: "Price per kg required",
                              },
                            ]}
                          >
                            <Input placeholder="Price/Kg" />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Quantity (Sack)"
                            name={[0, `quantity_sack`]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity(sack) required",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Quantity (Sack)"
                              onChange={handleSackQuantityChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Quantity (kg)"
                            name={[0, `quantity_kg`]}
                            rules={[
                              {
                                required: true,
                                message: "Quantity(kg) required",
                              },
                              {
                                pattern: /^[0-9]+(\.[0-9]+)?$/,
                                message: "Only english number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Quantity (Kg)"
                              onChange={(value) => handleKgQuantityChange(value, 0)}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{ width: "160px" }}>
                          <Form.Item
                            label="Stock Out Value"
                            name={[0, `stock_out_value`]}
                            rules={[
                              {
                                required: true,
                                message: "Stock out value is required",
                              },
                            ]}
                          >
                            <Input placeholder="Stock Out Value" disabled />
                          </Form.Item>
                        </Col>
                      </Space>
                    )}
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space key={key} align="baseline">
                        <Row gutter={[8, 16, 24, 32]}>
                          <Col style={{ width: "150px" }}>
                            <Form.Item
                              {...restField}
                              label="GRN"
                              name={[name, `grn`]}
                              rules={[
                                {
                                  required: true,
                                  message: "GRN is required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select GRN"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) =>
                                  handleGRNSelection(value, index)
                                }
                              >
                                {grnList
                                  .filter(
                                    (grn) => !selectedGRNs.includes(grn.grn)
                                  )
                                  .map((grn) => (
                                    <Option key={grn?.grn} value={grn?.grn}>
                                      {grn?.grn}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Remaining (Sack)"
                              name={[name, `remaining_stock_sack`]}
                            >
                              <Input
                                placeholder="Remaining Stock (Sack)"
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Remaining (kg)"
                              name={[name, `remaining_stock_kg`]}
                            >
                              <Input
                                placeholder="Remaining Stock (Kg)"
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Product Name"
                              name={[name, `product_name`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product name required",
                                },
                              ]}
                            >
                              <Input placeholder="Product Name" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Price/Kg"
                              name={[name, `price_per_kg`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Price per kg required",
                                },
                              ]}
                            >
                              <Input placeholder="Price/Kg" />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Quantity (Sack)"
                              name={[name, `quantity_sack`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Quantity required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Quantity (Sack)"
                                onChange={handleSackQuantityChange}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Quantity (kg)"
                              name={[name, `quantity_kg`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Quantity(kg) required",
                                },
                                {
                                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                                  message: "Only english number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Quantity (Kg)"
                                onChange={(value) => handleKgQuantityChange(value, index)}
                              />
                            </Form.Item>
                          </Col>
                          <Col style={{ width: "160px" }}>
                            <Form.Item
                              {...restField}
                              label="Stock Out Value"
                              name={[name, `stock_out_value`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Stock out value is required",
                                },
                              ]}
                            >
                              <Input placeholder="Stock Out Value" disabled />
                            </Form.Item>
                          </Col>

                          {fields.length > 1 ? (
                            <Button
                              className="align-self-center"
                              onClick={() => {
                                handleRemoveGDN(index);
                                form.setFieldsValue({
                                  total_price: undefined,
                                });
                                remove(name);
                                // calculateTotalPrice();
                              }}
                              style={{
                                backgroundColor: "#c21807",
                                color: "#ffffff",
                              }}
                            >
                              <MinusCircleOutlined />
                            </Button>
                          ) : null}
                        </Row>
                      </Space>
                    ))}
                  </div>
                )}
              </Form.List>

              <div className="d-flex mb-4">
                <div
                  className="w-50 pt-4"
                  style={{ color: "#46604A", border: "1px solid #46604A" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Sack"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_sack"
                      rules={[
                        {
                          required: true,
                          message: "Total number of sack missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total number of sack"
                        readOnly
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="w-50 pt-4"
                  style={{ color: "#46604A", border: "1px solid #46604A" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Quantity"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_quantity"
                      rules={[
                        {
                          required: true,
                          message: "Total quantity missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total Quantity"
                        readOnly
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="py-2 text-center custom-placeholder-color"
                        placeholder="Total Stock Out Value"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="total_stock_out_value"
                      rules={[
                        {
                          required: true,
                          message: "Total stock out value missing",
                        },
                      ]}
                    >
                      <Input
                        className="py-2 text-center"
                        placeholder="Total Stock Out Value"
                        readOnly
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <p
                className="text-center"
                style={{ color: "#46604A", fontSize: 16 }}
              >
                <strong>QC and Truck Info </strong>
              </p>
              <div className="d-flex shadow-sm mb-4">
                <div
                  className="w-50 pt-4"
                  style={{ color: "#46604A", border: "1px solid #46604A" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Moisture"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="moisture"
                      rules={[
                        {
                          required: true,
                          message: "Please select moisture level",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Moisture Level"
                        style={{ width: "180px" }}
                      >
                        <Option value="1%-3%">1%-3%</Option>
                        <Option value="3%-5%">3%-5%</Option>
                        <Option value="5%-7%">5%-7%</Option>
                        <Option value="7%-9%">7%-9%</Option>
                        <Option value="9%-11%">9%-11%</Option>
                        <Option value="11%-13%">11%-13%</Option>
                        <Option value="13%-15%">13%-15%</Option>
                        <Option value="15%-17%">15%-17%</Option>
                        <Option value="17%-19%">17%-19%</Option>
                        <Option value="19%-21%">19%-21%</Option>
                        <Option value="21%-23%">21%-23%</Option>
                        <Option value="23%-more">23%-more</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Dust"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="dust"
                      rules={[
                        {
                          required: true,
                          message: "Please select dust level",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Dust Level"
                        style={{ width: "180px" }}
                      >
                        <Option value="N/A">N/A</Option>
                        <Option value="0%">0%</Option>
                        <Option value="0%-1%">0%-1%</Option>
                        <Option value="1%-2%">1%-2%</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Fungus"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="fungus"
                      rules={[
                        {
                          required: true,
                          message: "Please select fungus level",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Fungus Level"
                        style={{ width: "180px" }}
                      >
                        <Option value="N/A">N/A</Option>
                        <Option value="0%">0%</Option>
                        <Option value="0%-1%">0%-1%</Option>
                        <Option value="1%-2%">1%-2%</Option>
                        <Option value="2%-3%">2%-3%</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Comment"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="comment"
                      rules={[
                        {
                          required: true,
                          message: "Please select comment",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Comment"
                        style={{ width: "180px" }}
                      >
                        <Option value="Grade A">Grade A</Option>
                        <Option value="Grade B">Grade B</Option>
                        <Option value="Grade C">Grade C</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="w-50 pt-4"
                  style={{ color: "#46604A", border: "1px solid #46604A" }}
                >
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Truck Driver Name"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="ms-2"
                      name="truck_driver_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter driver name",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message: "Only English characters",
                        },
                      ]}
                    >
                      <Input
                        className="text-center"
                        placeholder="Truck Driver Name"
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Truck Driver Phone"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="truck_driver_phone"
                      rules={[
                        {
                          required: true,
                          message: "Driver's phone number required",
                        },
                        {
                          pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <Input
                        className="text-center"
                        placeholder="Truck Driver Phone"
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Truck No"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item className="ms-2" name="truck_no">
                      <Input
                        className="text-center"
                        placeholder="Truck No"
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Transportation Cost"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="ms-2"
                      name="transportation_cost"
                      rules={[
                        {
                          pattern: /^[0-9]+(\.[0-9]+)?$/, // Regular expression to match English characters and spaces
                          message: "Only English numbers",
                        },
                      ]}
                    >
                      <Input
                        className="text-center"
                        placeholder="Transportation Cost"
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form.Item className="ms-2">
                      <Input
                        className="text-center custom-placeholder-color"
                        placeholder="Transport Rent Status"
                        disabled
                        style={{ color: "#46604A", borderColor: "#46604A" }}
                      />
                    </Form.Item>
                    <Form.Item className="ms-2" name="transport_rent_status">
                      <Select
                        placeholder="Select Rent Status"
                        style={{ width: "200px" }}
                      >
                        <Option value="N/A">N/A</Option>
                        <Option value="Paid">Paid</Option>
                        <Option value="Pending">Pending</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Form.Item className="d-flex justify-content-end ">
                <Button
                  htmlType="submit"
                  disabled={loading}
                  style={{ color: "#ffffff", backgroundColor: "#46604A" }}
                >
                  Submit
                  {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>WAREHOUSE STOCK OUT DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <div>
                  <Select
                    placeholder="Select Warehouse"
                    className="me-3"
                    onChange={handleWarehouse}
                    style={{ width: "300px" }}
                  >
                    <Option value="">All Warehouse</Option>
                    {warehouseList?.map((warehouse) => (
                      <Option
                        value={warehouse?.warehouse_id}
                        key={warehouse?.warehouse_id}
                      >
                        {warehouse?.warehouse_name}
                      </Option>
                    ))}
                  </Select>

                  <DateRangePicker
                    onChange={handleSalesDateRage}
                    value={selectedDateRange}
                    style={{ width: "250px" }}
                    showOneCalendar
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: "#46604A",
                    borderColor: "#46604A",
                    color:"white"
                  }}
                  onClick={handleStockInModal}
                >
                  Stock Out
                </Button>
              </div>
              <Table
                className="border"
                columns={stockColumn}
                dataSource={stockList}
                loading={stockLoading}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
