import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Button, Image, Input, Modal } from "antd";
import Swal from "sweetalert2";
import baseUrl from "../../config";

export default function ASPProfile() {
  const [aspProfileDetails, setAspProfileDetails] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { asp_id } = useParams();
  useEffect(() => {
    axios.get(`${baseUrl}/asp/aspProfile/${asp_id}/`).then((data) => {
      setAspProfileDetails(data?.data);
    });
  }, [asp_id]);

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handlePhoneNumberCheck = () => {
    if (parseInt(phoneNumber) === parseInt(aspProfileDetails?.phone)) {
      setOpenModal(false);
    } else {
      Swal.fire({
        title: "Error",
        text: "Phone number didn't match",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div></div>
      <Modal
        title="Please enter registered ASP phone number (রেজিস্টার্ড এ এস পি এর মোবাইল নম্বর )"
        centered
        closable={false}
        open={openModal}
        footer={[
          <Button key="submit" style={{backgroundColor:"#46604A", color:"white"}} onClick={handlePhoneNumberCheck}>
            OK
          </Button>,
        ]}
      >
        <Input
          placeholder="ASP Phone Number"
          inputMode="numeric"
          onChange={handlePhoneNumber}
        />
      </Modal>
      <div
        className="p-5 container"
        style={{ display: openModal ? "none" : "block" }}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          ASP Information
        </div>
        <div className="d-flex justify-content-end">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <th className="p-2 w-25">ASP ID Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.asp_id}
                  </td>
                </tr>
                <tr>
                  <th className="p-2 w-25">ASP Name</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">ASP's Phone Number</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {"0" + aspProfileDetails?.phone}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">National ID</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.nid}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">District</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.district?.district_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Upazila</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.upazila?.upazila_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Union</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.union?.union_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">Village</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.village}
                  </td>
                </tr>
                <tr>
                  <th className="p-2">GPS</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {aspProfileDetails?.latitude +
                      ", " +
                      aspProfileDetails?.longitude}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="w-50 ms-5">
            <p style={{ color: "#46604A" }}>
              <strong>ASP Image:</strong>
            </p>
            <Image
              className="border"
              width={350}
              height={250}
              src={aspProfileDetails?.profile_image}
              alt="ASP Image"
            />
          </div>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Agri-Machinery Info
        </div>
        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Name Of Agri-Machine</th>
              <th>Brand Name</th>
              <th>Available quantity</th>
              <th>HP (Horse Power)</th>
              <th>Service Price</th>
            </tr>
            {aspProfileDetails?.asp_services?.map((service, index) => (
              <tr key={index}>
                <td className="py-2">{service?.name_of_agri_machine}</td>
                <td>{service?.brand_name}</td>
                <td>{service?.available_quantity}</td>
                <td>{service?.horse_power}</td>
                <td>
                  {service?.price?.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Service with Agrigate
        </div>
        <table
          className="w-100 table-bordered text-center"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th>Invoice</th>
              <th>Name of Agri-Machine</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
            {/* {lspProfileDetails?.product_buying_info?.map((product, index) => (
              <tr key={index}>
                <td className="py-2">{product?.created_at?.slice(0, 10)}</td>
                <td>{product?.sales_id}</td>
                <td>{product?.invoice_products__product__product_name}</td>
                <td>{product?.invoice_products__quantity}</td>
                <td>
                  {product?.invoice_products__product_total_price?.toLocaleString()}
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </>
  );
}
