import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Spin,
  Space,
  Modal,
  Checkbox,
} from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import "./Inventory.css";

export default function TransferStockModal({
  visible,
  modalClose,
  fetchTransferredList,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [mainCenterList, setMainCenterList] = useState([]);
  const [filteredCenterList, setFilteredCenterList] = useState([]);
  const [selectedFromCenter, setSelectedFromCenter] = useState(null);
  const [selectedToCenter, setSelectedToCenter] = useState(null);
  const [wholeTransfer, setWholeTransfer] = useState(false);
  const [wholeStockList, setWholeStockList] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setMainCenterList(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const fetchStockList = (value) => {
    axios
      .get(`${baseUrl}/inventory/center_wise_stock_list/${value}/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setStockList(response?.data);
        setWholeStockList(response?.data);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const handleModalClose = () => {
    setFilteredCenterList(null);
    setSelectedFromCenter(null);
    setSelectedToCenter(null);
    setStockList(null);
    setWholeTransfer(false);
    setFilteredProductsList(null);

    form.resetFields([
      "products",
      "from_center_id",
      "to_center_id",
      "wholeTransfer",
    ]);
    modalClose();
  };
  const handleFromCenter = (value) => {
    form.resetFields(["to_center_id"]);
    form.resetFields(["products"]);
    setSelectedToCenter(null);
    setSelectedProduct([]);
    setFilteredProductsList(null);
    setStockList(null);
    setWholeTransfer(false);
    const remainingCenter = mainCenterList.filter(
      (center) => center?.center?.employee_id !== value
    );
    setFilteredCenterList(remainingCenter);

    setSelectedFromCenter(value);
    fetchStockList(value);
  };
  const handleToCenter = (value) => {
    setSelectedToCenter(value);
  };

  const handleWholeTransfer = (e) => {
    const isChecked = e.target.checked;
    setWholeTransfer(isChecked);

    const newValues = { ...form.getFieldsValue() };

    if (isChecked) {
      setStockList(wholeStockList);
      const allProducts = wholeStockList.map((stock) => ({
        product_id: stock.product.product_id,
        product_name: stock.product.product_name,
        batch_id: stock.batch_id,
        available_qty: stock.stock,
        transfer_qty: stock.stock,
      }));
      newValues.products = [...allProducts];
      setSelectedProduct(wholeStockList)
    } else {
      newValues.products = [];
      setSelectedProduct([]);
    }

    form.setFieldsValue({ ...newValues });
  };

  const distinct_product_name = [
    ...new Set(stockList?.map((item) => item?.product?.product_name)),
  ];

  const filterProductList = (value, index) => {
    const filteredProducts = stockList.filter(
      (product) =>
        product.product.product_name.toLowerCase() === value.toLowerCase()
    );
    setFilteredProductsList(filteredProducts);
    const newValues = form.getFieldsValue();
    newValues.products[index] = {
      ...newValues.products[index],
      batch_id: undefined,
      available_qty: undefined,
      transfer_qty: undefined,
    };

    form.setFieldsValue({ products: [...newValues.products] });
  };

  const handleProductSelect = (value, index) => {
    const newValues = { ...form.getFieldsValue() };

    const selectedProduct = stockList.find(
      (stock) => stock?.batch_id === value
    );

    if (selectedProduct) {
      setSelectedProduct((prevSelectedProducts) => {
        const updatedSelectedProducts = [...prevSelectedProducts];
        updatedSelectedProducts[index] = selectedProduct;
        return updatedSelectedProducts;
      });

      newValues.products[index] = {
        ...newValues.products[index],
        batch_id: selectedProduct.batch_id,
        available_qty: selectedProduct.stock,
        transfer_qty: null,
      };
      form.setFieldsValue(newValues);
    } else {
      console.error("Selected product not found in stock list.");
    }
  };

  const handleSubmit = (data) => {
    const updatedProducts = selectedProduct.map((product) => {
      const matchingProduct = data.products.find(
        (formProduct) =>
          formProduct?.product_name === product?.product?.product_name
      );
      return {
        ...product,
        transfer_qty: matchingProduct?.transfer_qty
          ? parseInt(matchingProduct?.transfer_qty, 10)
          : 0,
      };
    });

    data.products = updatedProducts;
    axios
      .post(`${baseUrl}/inventory/create_transfer/`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.detail,
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            handleModalClose();
            fetchTransferredList();
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.detail,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  return (
    <Modal
      title="Transfer Stock"
      open={visible}
      onCancel={handleModalClose}
      footer={false}
      width="60%"
    >
      <div className="farmerDashboard">
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div
              className="shadow-sm p-3"
              style={{ border: "1px solid #46604A" }}
            >
              <Form
                form={form}
                color="#46604A"
                onFinish={handleSubmit}
                layout="vertical"
              >
                <div className="d-flex  justify-content-between">
                  <div className="d-flex">
                    <Col style={{ width: 170 }}>
                      <Form.Item>
                        <Input
                          className="text-center custom-placeholder-color"
                          placeholder="From"
                          disabled
                          style={{ color: "#46604A", borderColor: "#46604A" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="ps-2" style={{ width: 170 }}>
                      <Form.Item
                        hasFeedback
                        name="from_center_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select center",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Center"
                          onChange={handleFromCenter}
                        >
                          {mainCenterList?.map((center) => (
                            <Option
                              value={center?.center?.employee_id}
                              key={center?.center?.employee_id}
                            >
                              {center?.center_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col style={{ width: 170 }}>
                      <Form.Item>
                        <Input
                          className="text-center custom-placeholder-color"
                          placeholder="TO"
                          disabled
                          style={{ color: "#46604A", borderColor: "#46604A" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="ps-2" style={{ width: 170 }}>
                      <Form.Item
                        hasFeedback
                        name="to_center_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select center",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Center"
                          onChange={handleToCenter}
                        >
                          {filteredCenterList?.map((center) => (
                            <Option
                              value={center?.center?.employee_id}
                              key={center?.center?.employee_id}
                            >
                              {center?.center_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                  {selectedFromCenter &&
                    selectedToCenter &&
                    stockList?.length > 0 && (
                      <div className="d-flex">
                        <Col>
                          <Form.Item>
                            <Checkbox
                              name="wholeTransfer"
                              className="text-center custom-placeholder-color"
                              style={{
                                color: "#46604A",
                                borderColor: "#46604A",
                              }}
                              onChange={handleWholeTransfer}
                            >
                              Whole Transfer
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </div>
                    )}
                </div>

                <Form.List name="products">
                  {(fields, { add, remove }) => (
                    <div
                      className="p-3 mb-5"
                      style={{ color: "#46604A", border: "1px solid #46604A" }}
                    >
                      <Col lg={4}>
                        <Form.Item>
                          <Button
                            type="default"
                            onClick={() => add()}
                            block
                            size="small"
                            icon={<PlusOutlined />}
                            style={{ color: "#000000" }}
                          >
                            Add product
                          </Button>
                        </Form.Item>
                      </Col>
                      {fields.length === 0 && (
                        <Space align="baseline">
                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Product Name"
                              name={[0, "product_name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Product is required",
                                },
                              ]}
                            >
                              {wholeTransfer ? (
                                <Input readOnly={wholeTransfer} />
                              ) : (
                                <Select
                                  placeholder="Select product"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option?.children
                                      ?.toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  onChange={(value) =>
                                    filterProductList(value, 0)
                                  }
                                >
                                  {distinct_product_name?.map(
                                    (product, index) => (
                                      <Option key={index} value={product}>
                                        {product}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Batch ID"
                              name={[0, "batch_id"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Batch ID required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select batch id"
                                showSearch
                                onChange={(value) =>
                                  handleProductSelect(value, 0)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {filteredProductsList?.map((item) => (
                                  <Option
                                    key={item.batch_id}
                                    value={item.batch_id}
                                  >
                                    {item.batch_id}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Available Quantity"
                              name={[0, "available_qty"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Available quantity required",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Available Quantity"
                                readOnly
                              />
                            </Form.Item>
                          </Col>

                          <Col style={{ width: "200px" }}>
                            <Form.Item
                              label="Transfer Quantity"
                              name={[0, "transfer_qty"]}
                              initialValue={0}
                              rules={[
                                {
                                  required: true,
                                  message: "Transfer quantity required",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message:
                                    "Only English numbers (0-9) are allowed",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Transfer Quantity"
                                readOnly={wholeTransfer}
                              />
                            </Form.Item>
                          </Col>
                        </Space>
                      )}

                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space key={key} align="baseline">
                          <Row gutter={[8, 16, 24, 32]}>
                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Product Name"
                                name={[name, "product_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Product is required",
                                  },
                                ]}
                              >
                                {wholeTransfer ? (
                                  <Input readOnly={wholeTransfer} />
                                ) : (
                                  <Select
                                    placeholder="Select product"
                                    showSearch
                                    filterOption={(input, option) =>
                                      option?.children
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    onChange={(value) =>
                                      filterProductList(value, index)
                                    }
                                  >
                                    {distinct_product_name?.map(
                                      (product, index) => (
                                        <Option key={index} value={product}>
                                          {product}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Batch ID"
                                name={[name, "batch_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Batch ID required",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select batch id"
                                  showSearch
                                  onChange={(value) =>
                                    handleProductSelect(value, index)
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {filteredProductsList?.map((item) => (
                                    <Option
                                      key={item.batch_id}
                                      value={item.batch_id}
                                    >
                                      {item.batch_id}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Available Quantity"
                                name={[name, "available_qty"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Available quantity required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Available Quantity"
                                  readOnly
                                />
                              </Form.Item>
                            </Col>

                            <Col style={{ width: "200px" }}>
                              <Form.Item
                                {...restField}
                                label="Transfer Quantity"
                                name={[name, "transfer_qty"]}
                                initialValue={0}
                                rules={[
                                  {
                                    required: true,
                                    message: "Transfer quantity required",
                                  },
                                  {
                                    pattern: /^[0-9]+$/,
                                    message:
                                      "Only English numbers (0-9) are allowed",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Transfer Quantity"
                                  readOnly={wholeTransfer}
                                />
                              </Form.Item>
                            </Col>
                            {fields.length > 1 ? (
                              <Button
                                className="align-self-center"
                                onClick={() => {
                                  remove(name);
                                }}
                                style={{
                                  backgroundColor: "#c21807",
                                  color: "#ffffff",
                                }}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            ) : null}
                          </Row>
                        </Space>
                      ))}
                    </div>
                  )}
                </Form.List>
                <Form.Item className="d-flex justify-content-end ">
                  <Button
                    htmlType="submit"
                    disabled={loading}
                    style={{ color: "#ffffff", backgroundColor: "#46604A" }}
                  >
                    Submit
                    {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
