import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import { Button, Table, Input, Tooltip, Select } from "antd";
import authHeader from "../Authentication/authHeader";

export default function InstitutionalBuyer() {
  const { Option } = Select;
  const [ibList, setIBList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ibCountData, setIBCountData] = useState({});
  const [ibFilterData, setAspFilterData] = useState("all");
  const { Search } = Input;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/ibCount/${ibFilterData}/`, {
        headers: authHeader(),
      })
      .then((results) => {
        setIBCountData(results?.data);
      });
  }, [ibFilterData]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/trader/ibList/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: filter,
        },
      })
      .then((results) => {
        setIBList(results?.data.results || []);
        setTotalItems(results?.data.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, filter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lspDistrictValue = (value) => {
    setAspFilterData(value);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const columns = [
    {
      title: "Institutional Buyer",
      dataIndex: "institutional_buyer_name",
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "institutional_buyer_phone",

      width: "14%",
      render: (text) => {
        return "0" + text; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "Address",
      render: (record) => record?.institutional_buyer_address,
      width: "24%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "12%",
    },
  ];

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h5 style={{ color: "#46604A" }}>INSTITUTIONAL BUYER'S DASHBOARD</h5>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="farmerBoxSection">
          <div className="farmerCardDesign">
            <p className="m-3" style={{ fontSize: "18px" }}>
              Total Registered institutional Buyer
            </p>
            <p className="m-3" style={{ fontSize: "30px" }}>
              {ibCountData?.total_count || 0}
            </p>
          </div>
          <div className="farmerCardDesign">
            <p className="mt-3 ms-3">District Filter:</p>
            <Select
              className="ms-3 w-50"
              name="Select"
              onChange={lspDistrictValue}
              placeholder="Select District"
              showSearch
            >
              <Option value="all">District</Option>
              {ibCountData?.district_values?.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
          <Search
            className="w-25"
            placeholder="search here..."
            allowClear
            onSearch={handleFilterChange}
            enterButton={
              <Button
                style={{ backgroundColor: "#46604A", borderColor: "#46604A", color:'white'}}
              >
                Search
              </Button>
            }
          />
        </div>
        <div className="m-2 border">
          <Table
            columns={columns}
            dataSource={ibList}
            loading={loading}
            rowKey={(record) => record.asp_id}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </div>
  );
}
