import { Button, Form, Input, Modal, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";

export default function OrderFulfillmentUpdateModal({
  visible,
  updateModalData,
  onCancel,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      due: updateModalData?.due,
    });
  }, [updateModalData?.due, form]);

  const modalClose = () => {
    form.resetFields();
    onCancel();
  };


  const handleUpdate = (value) => {
    setLoading(true);
    console.log(value)
    axios
      .patch(
        `${baseUrl}/retailer/orderFulfillmentUpdate/${updateModalData?.fulfillment_id}/`,
        value,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setLoading(false)
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Fulfillment Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            modalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false)
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  return (
    <Modal
      title="Update Order Fulfillment"
      open={visible}
      onCancel={onCancel}
      footer={false}
      centered
    >
      <Form form={form} layout="horizontal" onFinish={handleUpdate}>
        <Form.Item
          label="Sale ID"
          labelCol={{ span: 6 }}
          name="sale_id"
          rules={[
            {
              required: true,
              message: "Sale ID required",
            },
          ]}
        >
          <Input placeholder="Sale ID" />
        </Form.Item>
        <Form.Item className="d-flex justify-content-end ">
          <Button
            htmlType="submit"
            disabled={loading}
            style={{ color: "#ffffff", backgroundColor: "#46604A" }}
          >
            Update
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
