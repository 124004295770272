import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import { Select, Table, Tabs } from "antd";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./OrderTaking.css";

export default function OrderTaking() {
  const [orderTakingTotalData, setOrderTakingTotalData] = useState([]);
  const [retailerOrderList, setRetailerOrderList] = useState([]);
  const [agrigateCenterOrderList, setAgrigateCenterOrderList] = useState([]);
  const [contractFarmingOrderList, setContractFarmingOrderList] = useState([]);
  const [farmerOrderList, setFarmerOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(["all"]);
  const { Option } = Select;

  const [retailerSelectedDateRange, setRetailerSelectedDateRange] =
    useState(null);
  const [retailerSelectedDistrict, setRetailerSelectedDistrict] =
    useState("All Districts");
  const [retailerSelectedBazarName, setRetailerSelectedBazarName] =
    useState("All Bazars");
  const [retailerSelectedRouteNo, setRetailerSelectedRouteNo] =
    useState("All Routes");

  const [
    contractFarmingSelectedDateRange,
    setContractFarmingSelectedDateRange,
  ] = useState(null);
  const [contractFarmingSelectedDistrict, setContractFarmingSelectedDistrict] =
    useState("All Districts");
  const [contractFarmingSelectedGroup, setContractFarmingSelectedGroup] =
    useState("All Groups");

  const [agrigateCenterSelectedDateRange, setAgrigateCenterSelectedDateRange] =
    useState(null);
  const [agrigateCenterSelectedDistrict, setAgrigateCenterSelectedDistrict] =
    useState("All Districts");

  const [farmerSelectedDateRange, setFarmerSelectedDateRange] = useState(null);
  const [farmerSelectedDistrict, setFarmerSelectedDistrict] =
    useState("All Districts");

  const [orderTakingSelectedDateRange, setOrderTakingSelectedDateRange] =
    useState(null);
  const [orderTakingSelectedDistrict, setOrderTakingSelectedDistrict] =
    useState("All Districts");
  const [orderTakingSelectedCategory, setOrderTakingSelectedCategory] =
    useState("All Categories");
  const [orderTakingSelectedProduct, setOrderTakingSelectedProduct] =
    useState("All Products");

  const [allDataSelectedDateRange, setAllDataSelectedDateRange] =
    useState(null);
  const [allDataSelectedDistrict, setAllDataSelectedDistrict] =
    useState("All Districts");
  const [allDataSelectedCategory, setAllDataSelectedCategory] =
    useState("All Categories");
  const [allDataSelectedProduct, setAllDataSelectedProduct] =
    useState("All Products");

  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/orderTakingList/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setRetailerOrderList(response?.data?.Retailer_Order);
        setAgrigateCenterOrderList(response?.data?.Agrigate_Center_Order);
        setContractFarmingOrderList(response?.data?.Contract_Farming_Order);
        setFarmerOrderList(response?.data?.Farmer_Order);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/retailer/orderTakingTotalData/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setOrderTakingTotalData(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleRetailerDateRage = (value) => {
    setRetailerSelectedDateRange(value);
  };
  const handleRetailerDistrictSelect = (value) => {
    setRetailerSelectedDistrict(value);
  };
  const handleRetailerBazar = (value) => {
    setRetailerSelectedBazarName(value);
  };
  const handleRetailerRoute = (value) => {
    setRetailerSelectedRouteNo(value);
  };

  const handleContractFarmingDateRage = (value) => {
    setContractFarmingSelectedDateRange(value);
  };
  const handleContractFarmingDistrict = (value) => {
    setContractFarmingSelectedDistrict(value);
  };
  const handleContractFarmingGroup = (value) => {
    setContractFarmingSelectedGroup(value);
  };

  const handleAgrigateCenterDateRange = (value) => {
    setAgrigateCenterSelectedDateRange(value);
  };
  const handleAgrigateCenterDistrict = (value) => {
    setAgrigateCenterSelectedDistrict(value);
  };

  const handleFarmerDateRange = (value) => {
    setFarmerSelectedDateRange(value);
  };
  const handleFarmerDistrict = (value) => {
    setFarmerSelectedDistrict(value);
  };

  const handleOrderTakingDateRange = (value) => {
    setOrderTakingSelectedDateRange(value);
  };
  const handleOrderTakingDistrict = (value) => {
    setOrderTakingSelectedDistrict(value);
  };
  const handleOrderTakingCategory = (value) => {
    setOrderTakingSelectedCategory(value);
  };
  const handleOrderTakingProduct = (value) => {
    setOrderTakingSelectedProduct(value);
  };

  const handleAllDataDateRange = (value) => {
    setAllDataSelectedDateRange(value);
  };
  const handleAllDataDistrict = (value) => {
    setAllDataSelectedDistrict(value);
  };
  const handleAllDataCategory = (value) => {
    setAllDataSelectedCategory(value);
  };
  const handleAllDataProduct = (value) => {
    setAllDataSelectedProduct(value);
  };

  const distinctDistricts = tabValue.includes("retailer")
    ? [...new Set(retailerOrderList?.map((order) => order.district))]
    : tabValue.includes("contractFarming")
    ? [...new Set(contractFarmingOrderList?.map((order) => order.district))]
    : tabValue.includes("agrigateCenter")
    ? [...new Set(agrigateCenterOrderList?.map((order) => order.district))]
    : tabValue.includes("farmer")
    ? [...new Set(farmerOrderList?.map((order) => order.district))]
    : [];

  const distinctRouteNos = [
    ...new Set(retailerOrderList?.map((order) => order.route_no)),
  ];
  const distinctBazarNames = [
    ...new Set(retailerOrderList?.map((order) => order.bazar_name)),
  ];

  const distinctGroupNames = [
    ...new Set(
      contractFarmingOrderList?.map((order) => order.contract_group_name)
    ),
  ];

  const distinctOrderTakingDistrict = [
    ...new Set(orderTakingTotalData?.map((order) => order?.district)),
  ];

  const distinctOrderTakingProduct = [
    ...new Set(
      orderTakingTotalData?.flatMap(
        (order) =>
          order?.ordered_products?.map(
            (item) => item.product.product_name_english
          ) || []
      )
    ),
  ];

  const distinctOrderTakingCategory = [
    ...new Set(
      orderTakingTotalData?.flatMap(
        (order) =>
          order?.ordered_products?.map((item) => item.product.category) || []
      )
    ),
  ];
  function calculateTotalAmount(data) {
    let totalAmount = 0;

    // Iterate through each order
    for (const order of data) {
      // Iterate through ordered products in the order
      for (const orderedProduct of order.ordered_products) {
        totalAmount += orderedProduct.product_total_price;
      }
    }

    return totalAmount.toLocaleString();
  }

  function calculateTopProductsByQuantity(data, n = 3) {
    const allProducts = data.flatMap((order) => order.ordered_products);

    const productQuantityMap = allProducts.reduce((map, product) => {
      const {
        product: { product_name_english },
        quantity,
      } = product;
      const quantityValue = parseFloat(quantity); // Convert quantity to a number
      if (!isNaN(quantityValue)) {
        map[product_name_english] =
          (map[product_name_english] || 0) + quantityValue;
      }
      return map;
    }, {});

    const productsArray = Object.keys(productQuantityMap).map(
      (product_name_english) => ({
        product_name_english,
        total_quantity: productQuantityMap[product_name_english],
      })
    );

    productsArray.sort((a, b) => b.total_quantity - a.total_quantity);

    return productsArray.slice(0, n);
  }

  const retailerFilteredData = retailerOrderList?.filter((item) => {
    const itemDate = new Date(item?.order_date);
    let retailerStartDate = null;
    let retailerEndDate = null;

    if (retailerSelectedDateRange !== null) {
      retailerStartDate = new Date(retailerSelectedDateRange[0]);
      retailerEndDate = new Date(retailerSelectedDateRange[1]);
      retailerStartDate.setHours(0, 0, 0, 0);
      retailerEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      retailerSelectedDistrict === "All Districts" ||
      retailerSelectedDistrict === item?.district;
    const isProductMatched =
      retailerSelectedBazarName === "All Bazars" ||
      retailerSelectedBazarName === item?.bazar_name;
    const isProductTypeMatched =
      retailerSelectedRouteNo === "All Routes" ||
      retailerSelectedRouteNo === item?.route_no;
    const isDateInRange =
      retailerStartDate === null ||
      (itemDate >= retailerStartDate && itemDate <= retailerEndDate);
    return (
      isDistrictMatched &&
      isProductMatched &&
      isProductTypeMatched &&
      isDateInRange
    );
  });

  const farmerFilteredData = farmerOrderList?.filter((item) => {
    const itemDate = new Date(item?.order_date);
    let farmerStartDate = null;
    let farmerEndDate = null;

    if (farmerSelectedDateRange !== null) {
      farmerStartDate = new Date(farmerSelectedDateRange[0]);
      farmerEndDate = new Date(farmerSelectedDateRange[1]);
      farmerStartDate.setHours(0, 0, 0, 0);
      farmerEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      farmerSelectedDistrict === "All Districts" ||
      farmerSelectedDistrict === item?.district;
    const isDateInRange =
      farmerStartDate === null ||
      (itemDate >= farmerStartDate && itemDate <= farmerEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const agrigateCenterFilteredData = agrigateCenterOrderList?.filter((item) => {
    const itemDate = new Date(item?.order_date);
    let agrigateCenterStartDate = null;
    let agrigateCenterEndDate = null;

    if (agrigateCenterSelectedDateRange !== null) {
      agrigateCenterStartDate = new Date(agrigateCenterSelectedDateRange[0]);
      agrigateCenterEndDate = new Date(agrigateCenterSelectedDateRange[1]);
      agrigateCenterStartDate.setHours(0, 0, 0, 0);
      agrigateCenterEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      agrigateCenterSelectedDistrict === "All Districts" ||
      agrigateCenterSelectedDistrict === item?.district;
    const isDateInRange =
      agrigateCenterStartDate === null ||
      (itemDate >= agrigateCenterStartDate &&
        itemDate <= agrigateCenterEndDate);
    return isDistrictMatched && isDateInRange;
  });

  const contractFarmingFilteredData = contractFarmingOrderList?.filter(
    (item) => {
      const itemDate = new Date(item?.order_date);
      let contractFarmingStartDate = null;
      let contractFarmingEndDate = null;

      if (contractFarmingSelectedDateRange !== null) {
        contractFarmingStartDate = new Date(
          contractFarmingSelectedDateRange[0]
        );
        contractFarmingEndDate = new Date(contractFarmingSelectedDateRange[1]);
        contractFarmingStartDate.setHours(0, 0, 0, 0);
        contractFarmingEndDate.setHours(23, 59, 59, 999);
      }
      const isDistrictMatched =
        contractFarmingSelectedDistrict === "All Districts" ||
        contractFarmingSelectedDistrict === item?.district;
      const isGroupMatched =
        contractFarmingSelectedGroup === "All Groups" ||
        contractFarmingSelectedGroup === item?.contract_group_name;
      const isDateInRange =
        contractFarmingStartDate === null ||
        (itemDate >= contractFarmingStartDate &&
          itemDate <= contractFarmingEndDate);
      return isDistrictMatched && isGroupMatched && isDateInRange;
    }
  );

  const orderTakingTotalFilteredData = orderTakingTotalData?.filter((item) => {
    const itemDate = new Date(item?.order_date);
    let orderTakingStartDate = null;
    let orderTakingEndDate = null;

    if (orderTakingSelectedDateRange !== null) {
      orderTakingStartDate = new Date(orderTakingSelectedDateRange[0]);
      orderTakingEndDate = new Date(orderTakingSelectedDateRange[1]);
      orderTakingStartDate.setHours(0, 0, 0, 0);
      orderTakingEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      orderTakingSelectedDistrict === "All Districts" ||
      orderTakingSelectedDistrict === item?.district;

    const isProductMatched =
      orderTakingSelectedProduct === "All Products" ||
      item?.ordered_products?.some(
        (product) =>
          orderTakingSelectedProduct === product?.product?.product_name_english
      );

    const isProductCategoryMatched =
      orderTakingSelectedCategory === "All Categories" ||
      item?.ordered_products?.some(
        (product) => orderTakingSelectedCategory === product?.product?.category
      );

    const isDateInRange =
      orderTakingStartDate === null ||
      (itemDate >= orderTakingStartDate && itemDate <= orderTakingEndDate);
    return (
      isDistrictMatched &&
      isProductMatched &&
      isProductCategoryMatched &&
      isDateInRange
    );
  });
  console.log('orderTakingTotalFilteredData',orderTakingTotalFilteredData)

  const allDataFilteredData = orderTakingTotalData?.filter((item) => {
    const itemDate = new Date(item?.order_date);
    let allDataStartDate = null;
    let allDataEndDate = null;

    if (allDataSelectedDateRange !== null) {
      allDataStartDate = new Date(allDataSelectedDateRange[0]);
      allDataEndDate = new Date(allDataSelectedDateRange[1]);
      allDataStartDate.setHours(0, 0, 0, 0);
      allDataEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      allDataSelectedDistrict === "All Districts" ||
      allDataSelectedDistrict === item?.district;
    
    const isProductMatched =
      allDataSelectedProduct === "All Products" ||
      item?.ordered_products?.some(
        (product) =>
          allDataSelectedProduct === product?.product?.product_name_english
      );
      
    const isProductCategoryMatched =
      allDataSelectedCategory === "All Categories" ||
      item?.ordered_products?.some(
        (product) => allDataSelectedCategory === product?.product?.category
      );
    const isDateInRange =
      allDataStartDate === null ||
      (itemDate >= allDataStartDate && itemDate <= allDataEndDate);
    return (
      isDistrictMatched &&
      isProductMatched &&
      isProductCategoryMatched &&
      isDateInRange
    );
  });
  console.log('allDataFilteredData',allDataFilteredData)

  const allOrderColumns = [
    {
      title: "Order Date",
      width: "8%",
      dataIndex: "order_date",
    },
    {
      title: "Order ID",
      width: "10%",
      dataIndex: "order_id",
    },
    {
      title: "District",
      dataIndex: "district",
      width: "10%",
    },
    {
      title: "Product & Quantity",
      width: "14%",
      render: (record) =>
        record?.ordered_products
          ?.map(
            (item) =>
              item.product.product_name_english + " -- " + item.quantity + " pc"
          )
          .join(", "),
    },
    {
      title: "Amount (BDT)",
      dataIndex: "total_price",
      width: "8%",
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      width: "8%",
    },
    {
      title: "Field Officer Name",
      width: "12%",
      render: (record) => record?.employee?.employee_name,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
  ];

  const retailerOrderColumns = [
    {
      title: "Order Date",
      width: "8%",
      dataIndex: "order_date",
    },
    {
      title: "Order ID",
      width: "10%",
      dataIndex: "order_id",
    },
    {
      title: "Retailer ID",
      width: "11%",
      render: (record) => record?.retailer?.retailer_id,
    },
    {
      title: "Retailer Name",
      width: "12%",
      render: (record) => record?.retailer?.name,
    },
    {
      title: "Retailer Phone",
      filters: [
        ...new Set(retailerOrderList?.map((obj) => obj.retailer?.phone)),
      ].map((phone) => ({
        text: "0" + phone,
        value: phone,
      })),
      onFilter: (value, record) =>
        record?.retailer?.phone?.toString().startsWith(value),
      filterSearch: true,
      width: "12%",
      render: (text) => {
        return "0" + text?.retailer?.phone; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: "10%",
    },
    {
      title: "Bazar Name",
      dataIndex: "bazar_name",
      width: "10%",
    },
    {
      title: "Route No",
      dataIndex: "route_no",
      width: "6%",
    },
    {
      title: "Product & Quantity",
      width: "14%",
      render: (record) =>
        record?.ordered_products
          ?.map(
            (item) =>
              item.product.product_name_english + " -- " + item.quantity + " pc"
          )
          .join(", "),
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      width: "8%",
    },
    {
      title: "Field Officer Name",
      width: "12%",
      render: (record) => record?.employee?.employee_name,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
  ];

  const farmerOrderColumns = [
    {
      title: "Order Date",
      width: "8%",
      dataIndex: "order_date",
    },
    {
      title: "Order ID",
      width: "10%",
      dataIndex: "order_id",
    },
    {
      title: "Farmer ID",
      width: "11%",
      render: (record) => record?.farmer?.farmer_id,
    },
    {
      title: "Farmer Name",
      width: "12%",
      render: (record) => record?.farmer?.name,
    },
    {
      title: "Farmer Phone",
      filters: [
        ...new Set(farmerOrderList?.map((obj) => obj.farmer?.phone)),
      ].map((phone) => ({
        text: "0" + phone,
        value: phone,
      })),
      onFilter: (value, record) =>
        record?.farmer?.phone?.toString().startsWith(value),
      filterSearch: true,
      width: "12%",
      render: (text) => {
        return "0" + text?.farmer?.phone; // Add '0' to the beginning of the phone number
      },
    },
    {
      title: "District",
      dataIndex: "district",
      width: "10%",
    },
    {
      title: "Product & Quantity",
      width: "14%",
      render: (record) =>
        record?.ordered_products
          ?.map(
            (item) =>
              item.product.product_name_english + " -- " + item.quantity + " pc"
          )
          .join(", "),
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      width: "8%",
    },
    {
      title: "Field Officer Name",
      width: "12%",
      render: (record) => record?.employee?.employee_name,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
  ];

  const contractFarmingOrderColumns = [
    {
      title: "Order Date",
      width: "8%",
      dataIndex: "order_date",
    },
    {
      title: "Order ID",
      width: "10%",
      dataIndex: "order_id",
    },
    {
      title: "Contract Group Name",
      dataIndex: "contract_group_name",
      width: "12%",
    },
    {
      title: "District",
      dataIndex: "district",
      width: "10%",
    },
    {
      title: "Product & Quantity",
      width: "14%",
      render: (record) =>
        record?.ordered_products
          ?.map(
            (item) =>
              item.product.product_name_english + " -- " + item.quantity + " pc"
          )
          .join(", "),
    },
    {
      title: "Field Officer Name",
      width: "12%",
      render: (record) => record?.employee?.employee_name,
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      width: "8%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
  ];

  const agrigateCenterOrderColumns = [
    {
      title: "Order Date",
      width: "8%",
      dataIndex: "order_date",
    },
    {
      title: "Order ID",
      width: "10%",
      dataIndex: "order_id",
    },
    {
      title: "Agrigate Center Name",
      dataIndex: "agrigate_center_name",
      width: "12%",
    },
    {
      title: "District",
      dataIndex: "district",
      width: "10%",
    },
    {
      title: "Product & Quantity",
      width: "14%",
      render: (record) =>
        record?.ordered_products
          ?.map(
            (item) =>
              item.product.product_name_english + " -- " + item.quantity + " pc"
          )
          .join(", "),
    },
    {
      title: "Field Officer Name",
      width: "12%",
      render: (record) => record?.employee?.employee_name,
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "expected_delivery_date",
      width: "8%",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "10%",
    },
  ];

  const items = [
    {
      key: "all",
      label: <span style={{color:"#46604A"}}>All Order Details</span>,
      children: (
        <div className="my-2 me-2 border">
          <div className="d-flex">
            <div className="ms-3 mt-3">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleAllDataDateRange}
                value={allDataSelectedDateRange}
                showOneCalendar
              />
            </div>

            <div className="ms-3 mt-3">
              <p className="m-0">District: </p>
              <Select
                placeholder="Select District"
                onChange={handleAllDataDistrict}
                value={allDataSelectedDistrict}
              >
                <Option value="All Districts">All Districts</Option>
                {distinctOrderTakingDistrict?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">Category: </p>
              <Select
                placeholder="Select Category"
                onChange={handleAllDataCategory}
                value={allDataSelectedCategory}
              >
                <Option value="All Categories">All Categories</Option>
                {distinctOrderTakingCategory?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">Product: </p>
              <Select
                placeholder="Select Product"
                onChange={handleAllDataProduct}
                value={allDataSelectedProduct}
              >
                <Option value="All Products">All Products</Option>
                {distinctOrderTakingProduct?.map((product, index) => (
                  <Option key={index} value={product}>
                    {product}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={allOrderColumns}
            dataSource={allDataFilteredData}
            loading={loading}
            rowKey={(record) => record.order_id}
          />
        </div>
      ),
    },
    {
      key: "retailer",
      label: <span style={{color:"#46604A"}}>Retailer Order Details</span>,
      children: (
        <div className="my-2 me-2 border">
          <div className="d-flex">
            <div className="ms-3 mt-3">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleRetailerDateRage}
                value={retailerSelectedDateRange}
                showOneCalendar
              />
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">District: </p>
              <Select
                placeholder="Select District"
                onChange={handleRetailerDistrictSelect}
                value={retailerSelectedDistrict}
              >
                <Option value="All Districts">All Districts</Option>
                {distinctDistricts?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">Bazar Name: </p>
              <Select
                placeholder="Select Bazar Name"
                value={retailerSelectedBazarName}
                onChange={handleRetailerBazar}
              >
                <Option value="All Bazars">All Bazars</Option>
                {distinctBazarNames?.map((bazar, index) => (
                  <Option key={index} value={bazar}>
                    {bazar}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">Route No: </p>
              <Select
                placeholder="Select Route No"
                value={retailerSelectedRouteNo}
                onChange={handleRetailerRoute}
              >
                <Option value="All Routes">All Routes</Option>
                {distinctRouteNos?.map((route, index) => (
                  <Option key={index} value={route}>
                    {route}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={retailerOrderColumns}
            dataSource={retailerFilteredData}
            loading={loading}
            rowKey={(record) => record.order_id}
          />
        </div>
      ),
    },
    {
      key: "contractFarming",
      label: <span style={{color:"#46604A"}}>Contract Farming Order Details</span>,
      children: (
        <div className="my-2 me-2 border">
          <div className="d-flex">
            <div className="ms-3 mt-3">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleContractFarmingDateRage}
                value={contractFarmingSelectedDateRange}
                showOneCalendar
              />
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">District: </p>
              <Select
                placeholder="Select District"
                onChange={handleContractFarmingDistrict}
                value={contractFarmingSelectedDistrict}
              >
                <Option value="All Districts">All Districts</Option>
                {distinctDistricts?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">Group Name: </p>
              <Select
                placeholder="Select group name"
                onChange={handleContractFarmingGroup}
                value={contractFarmingSelectedGroup}
              >
                <Option value="All Groups">All Groups</Option>
                {distinctGroupNames?.map((group, index) => (
                  <Option key={index} value={group}>
                    {group}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={contractFarmingOrderColumns}
            dataSource={contractFarmingFilteredData}
            loading={loading}
            rowKey={(record) => record.order_id}
          />
        </div>
      ),
    },
    {
      key: "agrigateCenter",
      label: <span style={{color:"#46604A"}}>Agrigate Center Order Details</span>,
      children: (
        <div className="my-2 me-2 border">
          <div className="d-flex">
            <div className="ms-3 mt-3">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleAgrigateCenterDateRange}
                value={agrigateCenterSelectedDateRange}
                showOneCalendar
              />
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">District: </p>
              <Select
                placeholder="Select District"
                onChange={handleAgrigateCenterDistrict}
                value={agrigateCenterSelectedDistrict}
              >
                <Option value="All Districts"> All Districts</Option>
                {distinctDistricts?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={agrigateCenterOrderColumns}
            dataSource={agrigateCenterFilteredData}
            loading={loading}
            rowKey={(record) => record.order_id}
          />
        </div>
      ),
    },
    {
      key: "farmer",
      label: <span style={{color:"#46604A"}}>Farmer Order Details</span>,
      children: (
        <div className="my-2 me-2 border">
          <div className="d-flex">
            <div className="ms-3 mt-3">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleFarmerDateRange}
                value={farmerSelectedDateRange}
                showOneCalendar
              />
            </div>
            <div className="ms-3 mt-3">
              <p className="m-0">District: </p>
              <Select
                placeholder="Select District"
                onChange={handleFarmerDistrict}
                value={farmerSelectedDistrict}
              >
                <Option value="All Districts">All Districts</Option>
                {distinctDistricts?.map((district, index) => (
                  <Option key={index} value={district}>
                    {district}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={farmerOrderColumns}
            dataSource={farmerFilteredData}
            loading={loading}
            rowKey={(record) => record.order_id}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h5 style={{ color: "#46604A" }}>ORDER TAKING DASHBOARD</h5>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="orderTakingTotalFilterCardDesign">
          <div className="ms-3 mt-3">
            <p className="m-0">Date: </p>
            <DateRangePicker
              onChange={handleOrderTakingDateRange}
              value={orderTakingSelectedDateRange}
              showOneCalendar
            />
          </div>

          <div className="ms-3 mt-3">
            <p className="m-0">District: </p>
            <Select
              placeholder="Select District"
              onChange={handleOrderTakingDistrict}
              value={orderTakingSelectedDistrict}
            >
              <Option value="All Districts">All Districts</Option>
              {distinctOrderTakingDistrict?.map((district, index) => (
                <Option key={index} value={district}>
                  {district}
                </Option>
              ))}
            </Select>
          </div>
          <div className="ms-3 mt-3">
            <p className="m-0">Category: </p>
            <Select
              placeholder="Select Category"
              onChange={handleOrderTakingCategory}
              value={orderTakingSelectedCategory}
            >
              <Option value="All Categories">All Categories</Option>
              {distinctOrderTakingCategory?.map((district, index) => (
                <Option key={index} value={district}>
                  {district}
                </Option>
              ))}
            </Select>
          </div>
          <div className="ms-3 mt-3">
            <p className="m-0">Product: </p>
            <Select
              placeholder="Select Product"
              onChange={handleOrderTakingProduct}
              value={orderTakingSelectedProduct}
            >
              <Option value="All Products">All Products</Option>
              {distinctOrderTakingProduct?.map((product, index) => (
                <Option key={index} value={product}>
                  {product}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="orderTakingBoxSection">
          <div className="farmerCardDesign">
            <p className="m-3" style={{ fontSize: "18px" }}>
              Order Collected Amount (BDT)
            </p>
            <p className="m-3" style={{ fontSize: "30px" }}>
              {calculateTotalAmount(orderTakingTotalFilteredData)}
            </p>
          </div>
          <div className="farmerCardDesign">
            <p className="m-3" style={{ fontSize: "18px" }}>
              Top 3 Products
            </p>
            <p className="mx-3" style={{ fontSize: "16px" }}>
              {calculateTopProductsByQuantity(
                orderTakingTotalFilteredData,
                3
              )?.map((product, index) => (
                <p className="m-0" key={index}>
                  {" "}
                  <strong>
                    {index + 1}. {product.product_name_english}
                  </strong>
                </p>
              ))}
            </p>
          </div>
        </div>

        <Tabs
          className="mx-3 mt-5 custom-tabs"
          defaultActiveKey="all"
          items={items}
          onChange={(key) => setTabValue(key)}
        />
      </div>
    </div>
  );
}
