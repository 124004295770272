import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./employee.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link } from "react-router-dom/";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";

export default function Employee() {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const Filter = (data) => {
    const newArray = employeeList?.map((obj) => ({
      text: `${obj[data]?.first_name} ${obj[data]?.last_name}`,
      value: obj[data]?.first_name,
    }));
  
    const distinctValues = newArray.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (item) => item.text === obj.text && item.value === obj.value
        )
    );
  
    return distinctValues;
  };
  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      width: "20%",
      key: "employee_id",
      filters: employeeList?.map((obj) => ({
        text: obj?.employee_id,
        value: obj?.employee_id,
      })),
      onFilter: (value, record) => record.employee_id?.startsWith(value),
      filterSearch: true,
    },
    {
      title: 'Employee Name',
      dataIndex: 'user_info',
      render: (user_info) => (
        <span>{`${user_info.first_name} ${user_info.last_name}`}</span>
      ),
      filters: Filter('user_info'),
      onFilter: (value, record) =>
        record.user_info?.first_name?.startsWith(value) ||
        record.user_info?.last_name?.startsWith(value),
      filterSearch: true,
      width: '15%',
    },
    {
      title: "Phone",
      dataIndex: "phone",
      filters: employeeList?.map((obj) => ({
        text: ['0'+obj.phone],
        value: obj.phone,
      })),
      onFilter: (value, record) => record.phone.toString().startsWith(value),
      filterSearch: true,
      width: "14%",
      render: (text) => {
        return "0" + text;
      }
    },
    {
      title: 'Email',
      dataIndex: 'user_info',
      render: (user_info) => (
        <span>{`${user_info.email}`}</span>
      ),
      width: '15%',
    },
    

    // {
    //   title: "Action",
    //   key: "actions",
    //   width: "15%",
    //   render: (record) => (
    //     <>
    //       <Link
    //         className="actionBtnDesign me-4 shadow"
    //         to={`/farmerProfile/${record?.farmer_id}`}
    //       >
    //         <Tooltip title="Profile">
    //           <PortraitIcon />
    //         </Tooltip>
    //       </Link>
    //       <Link
    //         className="actionBtnDesign shadow"
    //         to={`/farmerProfileUpdate/${record?.farmer_id}`}
    //       >
    //         <Tooltip title="Update">
    //           <UpdateIcon />
    //         </Tooltip>
    //       </Link>
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/employee/employeeList`, {headers:authHeader()}).then((results) => {
      setEmployeeList(results?.data);
      console.log(results?.data)
      setLoading(false);
    });
  }, []);
  return (
    <div className="employeeList">
      <div className="employeeHeaderSection">
        <h5 style={{ color: "#46604A" }}>EMPLOYEE LIST</h5>
      </div>
      <div className="employeeContainer shadow-sm rounded">
        <div className="d-flex justify-content-end">
          <Link to="/create_profile">
            <button
              className="px-3 py-2 rounded me-2 mt-3"
              style={{textDecoration:"none", backgroundColor: "#46604A", color: "#ffffff" }}
            >
              <AddBoxIcon /> Employee
            </button>
          </Link>
        </div>
        <div className="m-2 border">
          <Table
            columns={columns}
            dataSource={employeeList}
            loading={loading}
            rowKey={(record) => record.employee_id}
          />
        </div>
      </div>
    </div>
  );
}
