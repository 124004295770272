import { useEffect } from "react";
import AuthService from "./authService";

function isUserLoggedIn() {
  const refreshToken = localStorage.getItem("refresh_token");
  const accessToken = localStorage.getItem("access_token");
  return accessToken && refreshToken && localStorage.getItem("isLoggedIn") === "true";
}

const AuthTokenRefresh = {
  useAuthTokenRefresh(loggedIn) {
    useEffect(() => {
      if (isUserLoggedIn()) {
        console.log("Token refresh interval started");

        const interval = setInterval(async () => {
          if (!isUserLoggedIn()) {
            clearInterval(interval);
            console.log("User logged out, interval cleared");
            return;
          }

          try {
            await AuthService.refreshToken();
            console.log("Token refreshed successfully");
          } catch (error) {
            console.error("Token refresh failed:", error);
            AuthService.logout();
            clearInterval(interval);
          }
        }, 900000);

        return () => {
          clearInterval(interval); 
          console.log("Token refresh interval cleared");
        };
      } else {
        console.log("User is not logged in. Token refresh interval not started.");
      }
    }, [loggedIn]);
  },
};


export function checkTokenValidity() {
  const refreshToken = localStorage.getItem("refresh_token");
  if (refreshToken) {
    const now = Math.floor(Date.now() / 1000);
    const tokenExpirationTime = JSON.parse(
      localStorage.getItem("token_expiration_time")
    ); 

    if (tokenExpirationTime && tokenExpirationTime < now) {
      console.log("Refresh token expired. Logging out.");
      AuthService.logout(); 
      return; 
    }
  }
}

export default AuthTokenRefresh;
