import React, { useEffect, useState } from "react";
import "./ContractFarmingDashboard.css";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
} from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import BarChart from "./BarChart";
import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import DueDetailsModal from "./DueDetailsModal";
export default function ContractFarmingDashboard() {
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const { Option } = Select;
  const { Search } = Input;
  const [tabValue, setTabValue] = useState([""]);
  const [district, setDistrict] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectAllDetails, setProjectAllDetails] = useState([]);
  const [detailsViewType, setDetailsViewType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");
  const [projectWiseData, setProjectWiseData] = useState([]);
  const [farmerAcquisitionData, setFarmerAcquisitionData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [CFAnalyticsLoading, setCFAnalyticsLoading] = useState(false);
  const [CFAnalyticsData, setCFAnalyticsData] = useState([]);
  const [projectAnalyticsPageNumber, setProjectAnalyticsPageNumber] =
    useState(1);
  const [projectAnalyticsTotalItems, setProjectAnalyticsTotalItems] =
    useState(0);
  const [selectedProjectAnalyticsName, setSelectedProjectAnalyticsName] =
    useState("");
  const [selectedProjectAnalyticsType, setSelectedProjectAnalyticsType] =
    useState("");
  const [selectedProjectAnalyticsYear, setSelectedProjectAnalyticsYear] =
    useState("");
  const [dueDetailsProjectId, setDueDetailsProjectId] = useState("");
  const [dueDetailsModal, setDueDetailsModal] = useState(false);

  useEffect(() => {
    setCFAnalyticsLoading(true);
    axios
      .get(`${baseUrl}/contractFarming/CFStatsList`, {
        headers: authHeader(),
        params: {
          project_id: selectedProjectAnalyticsName,
          type: selectedProjectAnalyticsType,
          year: selectedProjectAnalyticsYear,
        },
      })
      .then((response) => {
        setCFAnalyticsLoading(false);
        setCFAnalyticsData(response?.data?.results);
        setProjectAnalyticsTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setCFAnalyticsLoading(false);
        console.log(error);
      });
  }, [
    selectedProjectAnalyticsName,
    selectedProjectAnalyticsType,
    selectedProjectAnalyticsYear,
  ]);

  const distinctYear = [...new Set(projects?.map((project) => project?.year))];

  const fetchProjectList = async () => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/`, {
        headers: authHeader(),
      })
      .then((response) => setDistrict(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/farmerAcquisitionData/`, {
        headers: authHeader(),
      })
      .then((response) => setFarmerAcquisitionData(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    fetchProjectList();
  }, [tabValue]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projectAllDetails/`, {
        headers: authHeader(),
      })
      .then((response) => setProjectAllDetails(response?.data))
      .catch((error) => console.log(error));
  }, []);

  const onChange = (key) => {
    setTabValue(key);
  };
  const onFinish = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/contractFarming/createProject/`,
        {
          data: data,
        },
        {
          headers: { ...authHeader() },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  const handleProjectDetails = (data) => {
    setDetailsViewType(data?.project_type);
    setSelectedProjectID(data?.project_id);
  };

  const handleDueDetails = (id) => {
    setDueDetailsProjectId(id);
    setDueDetailsModal(true);
  };
  const handleDueDetailsModalClose = () => {
    setDueDetailsModal(false);
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleProjectAnalyticsPageChange = (page) => {
    setProjectAnalyticsPageNumber(page);
  };

  const scrollToBottom = () => {
    const section = document.getElementById("detailsSection");

    if (section) {
      section.style.height = "780px";
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleProjectAnalyticsName = (value) => {
    setSelectedProjectAnalyticsName(value);
  };
  const handleProjectAnalyticsYear = (value) => {
    setSelectedProjectAnalyticsYear(value);
  };
  const handleProjectAnalyticsType = (value) => {
    setSelectedProjectAnalyticsType(value);
  };
  const handleProjectModal = (value) => {
    setViewModal(true);
    modalForm.setFieldsValue(value);
    console.log(value);
  };

  const handleProjectUpdate = (data) => {
    console.log(data);
    axios
      .patch(
        `${baseUrl}/contractFarming/projectUpdate/${data?.project_id}/`,
        data,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Project Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            setViewModal(false);
            fetchProjectList();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    setProjectDataLoading(true);
    if (selectedProjectID) {
      axios
        .get(
          `${baseUrl}/contractFarming/projectWiseData/${selectedProjectID}/`,
          {
            headers: authHeader(),
            params: {
              page: currentPage,
              filter: filter,
            },
          }
        )
        .then((results) => {
          console.log(results?.data);
          setProjectWiseData(results?.data || []);
          setProjectDataLoading(false);
        })
        .catch((error) => {
          setProjectDataLoading(false);
          console.log(error);
        });
    }
  }, [currentPage, filter, selectedProjectID]);

  const projectsColumns = [
    {
      title: "Project ID",
      dataIndex: "project_id",
      width: "16%",
    },
    {
      title: "Name",
      dataIndex: "project_name",
      width: "15%",
    },
    {
      title: "Year",
      dataIndex: "year",
      width: "12%",
    },
    {
      title: "Type",
      dataIndex: "project_type",
      width: "15%",
    },
    {
      title: "Location",
      dataIndex: "district",
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "project_status",
      width: "16%",
    },
    {
      title: "Edit",
      width: "10%",
      render: (record) => (
        <Button
          size="small"
          onClick={() => {
            handleProjectModal(record);
          }}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];
  const projectMonitoringColumns = [
    {
      title: "Project ID",
      dataIndex: "project_id",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
    },
    {
      title: "No. of Groups",
      dataIndex: "group_count",
    },
    {
      title: "No. of Farmers",
      dataIndex: "farmer_count",
    },
    {
      title: "Details",
      render: (record) => (
        <div>
          <Button
            onClick={() => {
              handleProjectDetails(record);
              scrollToBottom();
            }}
          >
            Details
          </Button>
        </div>
      ),
    },
  ];

  const projectAnalyticsColumn = [
    {
      title: "Project",
      dataIndex: "project_id",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Total Farmer",
      dataIndex: "farmer_count",
    },
    {
      title: "Credit",
      render: (record) => parseFloat(record?.total_price)?.toLocaleString(),
    },
    {
      title: "Input",
      render: (record) =>
        parseFloat(record?.total_purchase_price)?.toLocaleString(),
    },
    {
      title: "Output",
      render: (record) => parseFloat(record?.total_paid)?.toLocaleString(),
    },
    {
      title: "Due",
      render: (record) => parseFloat(record?.total_due)?.toLocaleString(),
    },
    {
      title: "Details",
      render: (record) => (
        <div>
          <Button
            onClick={() => {
              handleDueDetails(record?.project_id);
            }}
          >
            Details
          </Button>
        </div>
      ),
    },
  ];

  const cropWiseDataColumns = [
    {
      title: "Project ID",
      dataIndex: "project",
      width: "15%",
    },
    {
      title: "Location",
      dataIndex: "district",
      width: "15%",
    },
    {
      title: "Type",
      dataIndex: "contract_farming_type",
      width: "10%",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      width: "15%",
    },
    {
      title: "BP Name",
      dataIndex: "bp_name",
      width: "15%",
    },
    {
      title: "No. of Farmers",
      dataIndex: "no_of_farmers",
      width: "10%",
    },

    {
      title: "Seed Amount",
      width: "15%",
      dataIndex: "seed_amount",
    },
  ];

  const livestockWiseDataColumns = [
    {
      title: "Project ID",
      dataIndex: "project",
      width: "15%",
    },
    {
      title: "Location",
      dataIndex: "district",
      width: "12%",
    },
    {
      title: "Type",
      dataIndex: "contract_farming_type",
      width: "10%",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      width: "15%",
    },
    {
      title: "BP Name",
      dataIndex: "bp_name",
      width: "15%",
    },
    {
      title: "No. of Farmers",
      dataIndex: "no_of_farmers",
      width: "10%",
    },

    {
      title: "No. of Livestock",
      width: "15%",
      dataIndex: "no_of_livestock",
    },
  ];

  const items = [
    {
      key: "projects",
      label: <span style={{color:"#46604A"}}>Projects</span>,
      children: (
        <div className="border pe-2 pb-3" style={{ height: "350px" }}>
          <Table
            columns={projectsColumns}
            dataSource={projects}
            scroll={{ y: 238 }}
            rowKey={(record) => record.project_id}
            size="small"
          />
        </div>
      ),
    },
    {
      key: "create projects",
      label: <span style={{color:"#46604A"}}>Create Projects</span>,
      children: (
        <div
          className="border pe-2 pb-3"
          style={{ overflow: "auto", height: "350px" }}
        >
          <Form
            className="mt-2"
            onFinish={onFinish}
            form={form}
            layout="horizontal"
          >
            <Form.Item
              hasFeedback
              label="Project Name"
              name="project_name"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Please enter project name",
                },
                {
                  pattern: /^[a-zA-Z\s\-.]+(\s-\s\d+(\.\d+)?)?$/,
                  message: "Only english characters",
                },
              ]}
            >
              <Input placeholder="Project Name" />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Project Type"
              name="project_type"
              style={{ marginBottom: 8 }}
            >
              <Select placeholder="Select Project Type" showSearch>
                <Option value="Livestock">Livestock</Option>
                <Option value="Crop">Crop</Option>
              </Select>
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Year"
              name="year"
              labelCol={{ span: 6 }}
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Please enter year",
                },
                {
                  pattern: /^\d+(\.\d+)?$/,
                  message: "Only numbers are allowed.",
                },
              ]}
            >
              <Input placeholder="Year" />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 6 }}
              hasFeedback
              label="Location"
              name="district"
              style={{ marginBottom: 8 }}
            >
              <Select placeholder="Select Districts" showSearch>
                {district?.map((district, index) => (
                  <Option value={district?.district_name} key={index}>
                    {district?.district_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              hasFeedback
              label="Crop/Livestock Name"
              name="crop_or_livestock_names"
              labelCol={{ span: 7 }}
              style={{ marginBottom: 8 }}
              rules={[
                {
                  required: true,
                  message: "Please enter crop/livestock name",
                },
                {
                  pattern:
                    /^[a-zA-Z]+(?:\s[a-zA-Z]+)*(?:,[a-zA-Z]+(?:\s[a-zA-Z]+)*)*$/,
                  message:
                    "Only English characters and spaces are allowed. No space after comma.",
                },
              ]}
              getValueFromEvent={(event) => event.target.value.split(",")}
            >
              <Input placeholder="Crop/Livestock" />
            </Form.Item>

            <Form.List name="names">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        margin: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...field}
                        label="Group Name"
                        name={[name, "group"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input group name",
                          },
                        ]}
                      >
                        <Input placeholder="Group Name" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="BP Name"
                        name={[name, "bp"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please input bp name.",
                          },
                        ]}
                      >
                        <Input placeholder="BP Name" />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      ) : null}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      className="w-100 ms-1"
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Group & BP
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item style={{ textAlign: "right", margin: 0 }}>
              <Button
                style={{ backgroundColor: "#46604A", color: "#ffffff" }}
                htmlType="submit"
                disabled={loading}
              >
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Update Project"
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
        }}
        width="35%"
        footer={null}
      >
        <Form
          className="mt-2"
          onFinish={handleProjectUpdate}
          form={modalForm}
          layout="horizontal"
        >
          <Form.Item
            hasFeedback
            label="Project ID"
            name="project_id"
            labelCol={{ span: 6 }}
            style={{ marginBottom: 8 }}
          >
            <Input placeholder="Project ID" readOnly />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="Project Name"
            name="project_name"
            labelCol={{ span: 6 }}
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: "Please enter project name",
              },
              {
                pattern: /^[a-zA-Z\s\-.]+(\s-\s\d+(\.\d+)?)?$/,
                message: "Only english characters",
              },
            ]}
          >
            <Input placeholder="Project Name" />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            hasFeedback
            label="Project Type"
            name="project_type"
            style={{ marginBottom: 8 }}
          >
            <Select placeholder="Select Project Type" showSearch>
              <Option value="Livestock">Livestock</Option>
              <Option value="Crop">Crop</Option>
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Year"
            name="year"
            labelCol={{ span: 6 }}
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: "Please enter year",
              },
              {
                pattern: /^\d+(\.\d+)?$/,
                message: "Only numbers are allowed.",
              },
            ]}
          >
            <Input placeholder="Year" />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 6 }}
            hasFeedback
            label="Location"
            name="district"
            style={{ marginBottom: 8 }}
          >
            <Select placeholder="Select Districts" showSearch>
              {district?.map((district, index) => (
                <Option value={district?.district_name} key={index}>
                  {district?.district_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            hasFeedback
            label="Crop/Livestock Name"
            name="crop_or_livestock_names"
            labelCol={{ span: 7 }}
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: "Please enter crop/livestock name",
              },
              {
                pattern:
                  /^[a-zA-Z]+(?:\s[a-zA-Z]+)*(?:,[a-zA-Z]+(?:\s[a-zA-Z]+)*)*$/,
                message:
                  "Only English characters and spaces are allowed. No space after comma.",
              },
            ]}
            getValueFromEvent={(event) => event.target.value.split(",")}
          >
            <Input placeholder="Crop/Livestock" />
          </Form.Item>
          <Form.Item
            hasFeedback
            label="Project Status"
            name="project_status"
            labelCol={{ span: 7 }}
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: "Please select project status",
              },
            ]}
          >
            <Select placeholder="Select Status">
              <Option value="On going">On going</Option>
              <Option value="Completed">Completed</Option>
            </Select>
          </Form.Item>

          <Form.List name="names" align="baseline">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...field }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      margin: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...field}
                      label="Group Name"
                      name={[name, "group"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input group name",
                        },
                      ]}
                    >
                      <Input placeholder="Group Name" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="BP Name"
                      name={[name, "bp"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input bp name.",
                        },
                      ]}
                    >
                      <Input placeholder="BP Name" />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                      />
                    ) : null}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className="w-100 ms-1"
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Group & BP
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item style={{ textAlign: "right", margin: 0 }}>
            <Button
              style={{ backgroundColor: "#46604A", color: "#ffffff" }}
              htmlType="update"
              disabled={loading}
            >
              Submit
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {dueDetailsModal && (
        <DueDetailsModal
          closeModal={handleDueDetailsModalClose}
          openModal={dueDetailsModal}
          projectId={dueDetailsProjectId}
        />
      )}
      <div className="consultationDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>CONTRACT FARMING DASHBOARD</h5>
        </div>
        <div className="consultationDashboardContainer shadow-sm rounded">
          <div className="projectInfo px-3">
            <div className="projects mt-4">
              <p
                className="text-center py-2 border"
                style={{ backgroundColor: "#46604A", color:"white"}}
              >
                Projects
              </p>
              <Tabs
                className="custom-tabs"
                defaultActiveKey="projects"
                items={items}
                onChange={onChange}
              />
            </div>
            <div className="projectMonitoring mt-4 ms-2">
              <p
                className="text-center py-2 border"
                style={{ backgroundColor: "#46604A", color:"white" }}
              >
                Project Monitoring
              </p>
              <Table
                className="border"
                dataSource={projectAllDetails}
                columns={projectMonitoringColumns}
                scroll={{ y: 270 }}
                rowKey={(record) => record.project_id}
              />
            </div>
            {/* <div className="inputOutputInfo ps-2 pe-2">
              <div>
                <Select
                  className="text-center mt-4"
                  placeholder="Project Name - Year"
                  size="large"
                >
                  <Option>Project Name</Option>
                </Select>
              </div>
              <div className="border w-75 h-25 mt-2 text-center">Input</div>
              <div className="border w-75 h-25 mt-2 text-center">Output</div>
            </div> */}
          </div>
          <div className="analyticsSection">
            <div className="farmerAcquisition ps-3 pe-2">
              <p
                className="text-center py-2 border"
                style={{ backgroundColor: "#46604A", color:"white" }}
              >
                Farmer Acquisition
              </p>
              <BarChart data={farmerAcquisitionData} />
            </div>
            <div className="projectAnalytics pe-3">
              <p
                className="text-center py-2 border"
                style={{ backgroundColor: "#46604A", color:"white" }}
              >
                Project Analytics
              </p>
              <div className="my-2 border">
                <div className="d-flex">
                  <div className="ms-3 mt-3">
                    <p className="m-0">Project: </p>
                    <Select
                      placeholder="Select District"
                      onChange={handleProjectAnalyticsName}
                      value={selectedProjectAnalyticsName}
                      style={{ width: "200px" }}
                    >
                      <Option value="">All Projects</Option>
                      {projects?.map((project) => (
                        <Option
                          key={project?.project_id}
                          value={project?.project_id}
                        >
                          {project?.project_name +
                            " " +
                            project?.project_type +
                            " " +
                            project?.year}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="ms-3 mt-3">
                    <p className="m-0">Type: </p>
                    <Select
                      placeholder="Select Type"
                      onChange={handleProjectAnalyticsType}
                      style={{ width: "200px" }}
                      value={selectedProjectAnalyticsType}
                    >
                      <Option value="">All Types</Option>
                      <Option value="Crop">Crop</Option>
                      <Option value="Livestock">Livestock</Option>
                    </Select>
                  </div>
                  <div className="ms-3 mt-3">
                    <p className="m-0">Year: </p>
                    <Select
                      placeholder="Select Year"
                      onChange={handleProjectAnalyticsYear}
                      style={{ width: "200px" }}
                      value={selectedProjectAnalyticsYear}
                    >
                      <Option value="">All Years</Option>
                      {distinctYear?.map((year, index) => (
                        <Option key={index} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <Table
                  className="mt-3"
                  columns={projectAnalyticsColumn}
                  dataSource={CFAnalyticsData}
                  loading={CFAnalyticsLoading}
                  size="small"
                  scroll={{ y: 180 }}
                  rowKey={(record) => record?.project_id}
                  pagination={{
                    current: projectAnalyticsPageNumber,
                    total: projectAnalyticsTotalItems,
                    pageSize: 10,
                    onChange: handleProjectAnalyticsPageChange,
                  }}
                />
              </div>
            </div>
          </div>
          <div id="detailsSection">
            {detailsViewType.includes("Livestock") && (
              <>
                <div className="m-2 mt-4 me-3 d-flex justify-content-end align-items-center">
                  <Search
                    className="w-25"
                    placeholder="search here..."
                    allowClear
                    onSearch={handleFilterChange}
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#46604A",
                          borderColor: "#46604A",
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
                <Table
                  className="border mx-3 mb-4"
                  dataSource={projectWiseData}
                  columns={livestockWiseDataColumns}
                  loading={projectDataLoading}
                  rowKey={(record, index) => index}
                  pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize: 10, // Set the number of items per page
                    onChange: handlePageChange,
                  }}
                />
              </>
            )}
            {detailsViewType.includes("Crop") && (
              <>
                <div className="m-2 me-3 mt-4 d-flex justify-content-end align-items-center">
                  <Search
                    className="w-25"
                    placeholder="search here..."
                    allowClear
                    onSearch={handleFilterChange}
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#46604A",
                          borderColor: "#46604A",
                          color:"white"
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
                <Table
                  className="border mx-3 mb-4"
                  dataSource={projectWiseData}
                  columns={cropWiseDataColumns}
                  rowKey={(record, index) => index}
                  loading={projectDataLoading}
                  pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize: 10,
                    onChange: handlePageChange,
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
