import React, { useEffect, useState } from "react";
import logo from "../../assets/Agrigate.png";
import { Button, Form, Input, Select, Row, Col, Spin, Table } from "antd";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

export default function ContractFarmingReg() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [contractFarmerList, setContractFarmerList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  }, []);

  const handleProjectInfo = (value) => {
    const selectedProjectDetails = projects?.find(
      (project) => project.project_id === value
    );
    console.log(selectedProjectDetails);
    setSelectedProject(selectedProjectDetails);
    form.setFieldsValue({
      contract_farming_type: selectedProjectDetails?.project_type,
      year: selectedProjectDetails?.year,
      season: null,
      crop_or_livestock_name: null,
      seed_amount: null,
      bp_name: null,
      group_name: null,
      land_or_farm_size: null,
      no_of_livestock: null,
      district: selectedProjectDetails?.district,
    });
  };
  const handleGroupSelect = (value) => {
    const selectGroup = selectedProject?.names?.find((item) => item.group === value);
    form.setFieldsValue({
      bp_name: selectGroup.bp,
    })
  }

  useEffect(() => {
    setTableLoading(true);
    axios
      .get(`${baseUrl}/api/contract_farmer_list/`, {
        headers: authHeader(),
        params: {
          page: currentPage,
          filter: filter,
        },
      })
      .then((results) => {
        setContractFarmerList(results?.data.results || []);
        setTotalItems(results?.data.count || 0);
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
        console.log(error);
      });
  }, [currentPage, filter, loading]);

  const handleFarmerInfo = (e) => {
    const phone = e.target.value;
    if (phone.length === 11) {
      axios
        .get(`${baseUrl}/api/farmer_info/${phone}/`, {
          headers: authHeader(),
        })
        .then((data) => {
          form.setFieldsValue({
            farmer_name: data?.data?.name,
            farmer_id: data?.data?.farmer_id,
          });
        })
        .catch((error) => {
          form.setFieldsValue({
            farmer_name: undefined,
            farmer_id: undefined,
          });
          Swal.fire({
            title: "Error",
            text: "Farmer Not Found",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const onFinish = (data) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/api/contract_farming_reg/`,
        {
          data: data,
        },
        {
          headers: { ...authHeader() },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Farmer ID",
      width: "13%",
      render: (record) => record?.farmer?.farmer_id,
    },
    {
      title: "Farmer Name",
      render: (record) => record?.farmer?.name,
      width: "11%",
    },
    {
      title: "Phone",
      width: "11%",
      render: (record) => "0" + record?.farmer?.phone,
    },
    {
      title: "Contract Farming Type",
      render: (record) => record?.contract_farming_type,
      width: "11%",
    },
    {
      title: "Contract Crop/Livestock Name",
      render: (record) => record?.crop_or_livestock_name,
      width: "11%",
    },
    {
      title: "Project Name",
      render: (record) => record?.project?.project_name,
      width: "11%",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      width: "11%",
    },
    {
      title: "BP Name",
      dataIndex: "bp_name",
      width: "11%",
    },
    {
      title: "Year",
      render: (record) => record?.project?.year,
      width: "11%",
    },
  ];

  return (
    <div className="p-5" style={{color:"#46604A"}}>
      <section className="headSection d-flex align-items-center">
        <div>
          <img
            src={logo}
            style={{ width: "100px", height: "100px" }}
            alt="agrigate"
          />
        </div>
        <div>
          <h5> Contract Farmer Registration </h5>
        </div>
      </section>
      <section className="container m-auto">
        <Form
          className="mt-2"
          onFinish={onFinish}
          form={form}
          layout="horizontal"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Farmer Phone Number"
                name="phoneNumber"
                onChange={handleFarmerInfo}
                rules={[
                  {
                    required: true,
                    message: "Please enter farmer's phone number",
                  },
                  {
                    pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Phone Number" type="tel" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item hasFeedback label="Project Name" name="project_id">
                <Select
                  placeholder="Select Project"
                  onChange={handleProjectInfo}
                >
                  {projects?.map((project) => (
                    <Option
                      value={project?.project_id}
                      key={project?.project_id}
                    >
                      {project?.project_name +
                        " - " +
                        project?.project_type +
                        " - " +
                        project?.year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Farmer Name"
                name="farmer_name"
                rules={[
                  {
                    required: true,
                    message: "Farmer name required",
                  },
                ]}
              >
                <Input placeholder="Farmer Name" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Farmer ID"
                name="farmer_id"
                rules={[
                  {
                    required: true,
                    message: "Farmer ID required",
                  },
                ]}
              >
                <Input placeholder="Farmer ID" readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: "District required",
                  },
                ]}
              >
                <Input placeholder="District Name" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Contract Type"
                name="contract_farming_type"
                rules={[
                  {
                    required: true,
                    message: "Contract farming type required",
                  },
                ]}
              >
                <Input placeholder="Contract Farming Type" readOnly />
              </Form.Item>
            </Col>
            {selectedProject?.project_type === "Crop" && (
              <>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Crop Name"
                    name="crop_or_livestock_name"
                    rules={[
                      {
                        required: true,
                        message: "Please select crop name",
                      },
                    ]}
                  >
                    <Select placeholder="Select crop name">
                      {selectedProject?.crop_or_livestock_names?.map(
                        (item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5}>
                  <Form.Item
                    hasFeedback
                    label="Seed Amount"
                    name="seed_amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter seed amount",
                      },
                    ]}
                  >
                    <Input placeholder="Seed Amount" />
                  </Form.Item>
                </Col>
              </>
            )}
            {selectedProject?.project_type === "Livestock" && (
              <>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Form.Item
                    hasFeedback
                    label="Livestock Name"
                    name="crop_or_livestock_name"
                    rules={[
                      {
                        required: true,
                        message: "Please select livestock name",
                      },
                    ]}
                  >
                    <Select placeholder="Select livestock name">
                      {selectedProject?.crop_or_livestock_names?.map(
                        (item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5}>
                  <Form.Item
                    hasFeedback
                    label="No of Livestock"
                    name="no_of_livestock"
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of livestock",
                      },
                    ]}
                  >
                    <Input placeholder="No of Livestock" />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24} sm={12} md={8} lg={7}>
              <Form.Item
                hasFeedback
                label="Land(Decimal)/ Farm Size(sqft)"
                name="land_or_farm_size"
                rules={[
                  {
                    required: true,
                    message: "Please enter Land / Farm size",
                  },
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Only english numbers",
                  },
                ]}
              >
                <Input placeholder="Land/ Farm Size" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Group Name"
                name="group_name"
                rules={[
                  {
                    required: true,
                    message: "Please select group name",
                  },
                ]}
              >
                <Select placeholder="Select group name" onChange={handleGroupSelect}>
                  {selectedProject?.names?.map((item, index) => (
                    <Option key={index} value={item.group}>
                      {item.group}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="BP Name"
                name="bp_name"
                rules={[
                  {
                    required: true,
                    message: "BP name required",
                  },
                ]}
              >
                <Input placeholder="BP Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                hasFeedback
                label="Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: "year required",
                  },
                ]}
              >
                <Input placeholder="Year-Season" readOnly />
              </Form.Item>
            </Col>
            {selectedProject?.project_type === "Crop" && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item
                  hasFeedback
                  label="Season"
                  name="season"
                  rules={[
                    {
                      required: true,
                      message: "Please select season",
                    },
                  ]}
                >
                  <Select placeholder="Select season">
                    <Option value="Robi">Robi</Option>
                    <Option value="Kharif1">Kharif1</Option>
                    <Option value="Kharif2">Kharif2</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Col xs={24} sm={12} md={10} lg={10}>
            <Form.Item>
              <Button
                style={{ backgroundColor: "#46604A", color: "#ffffff" }}
                htmlType="submit"
                disabled={loading}
              >
                Submit
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </section>
      <h5 className="ms-2 mt-5">Contract Farmer Details</h5>
      <div className="m-2 mt-4 d-flex justify-content-end align-items-center">
        <Search
          className="w-25"
          placeholder="search here..."
          allowClear
          onSearch={handleFilterChange}
          enterButton={
            <Button
              style={{ backgroundColor: "#46604A", borderColor: "#46604A", color:"white" }}
            >
              Search
            </Button>
          }
        />
      </div>
      <div className="m-2 mt-2 border">
        <Table
          columns={columns}
          dataSource={contractFarmerList}
          loading={tableLoading}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            total: totalItems,
            pageSize: 10, // Set the number of items per page
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
}
