import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Input, Select, Table } from "antd";
import { DateRangePicker } from "rsuite";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function CustomerLedger() {
  const { Option } = Select;
  const { Search } = Input;
  const [invoiceLists, setInvoiceLists] = useState([]);
  const [salesLoading, setSalesLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [distinctValues, setDistinctSalesValue] = useState({});

  const [search, setSearch] = useState("");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );

  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedType, setSalesSelectedType] = useState("");

  const handleSalesDateRage = (value) => {
    setSelectedDateRange(value);
  };

  const handleSalesType = (value) => {
    setSalesSelectedType(value);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleSearch = (value) => {
    setSearch(value);
  };

  const fetchData = async () => {
    try {
      setSalesLoading(true);
      const response = await axios.get(
        `${baseUrl}/inventory/sales_customer_ledger/${userProfile?.employee_id}/`,
        {
          headers: authHeader(),
          params: {
            type: selectedType,
            dateRange: selectedDateRange,
            filter: search,
            page:currentPage,
          },
        }
      );
      setTotalItems(response?.data?.count);
      setInvoiceLists(response?.data?.results);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setSalesLoading(false);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/inventory/sales_distinct_data/${userProfile.employee_id}/`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setDistinctSalesValue(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userProfile.employee_id]);

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    selectedType,
    selectedDateRange,
    search,
    userProfile.employee_id,
  ]);

  const salesColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
    },
    {
      title: "Location",
      render: (record) => record?.center?.center_name,
    },
    {
      title: "Sale ID",
      dataIndex: "sales_id",
    },
    {
      title: "Customer",
      render: (record) => record?.farmer?.name || record?.retailer?.name || record?.institutional?.institutional_buyer_name,
    },
    {
      title: "Phone",
      render: (record) =>
        "0" + (record?.retailer?.phone || record?.farmer?.phone || record?.institutional?.institutional_buyer_phone),
    },
    {
      title: "Customer Type",
      dataIndex: "customer_type",
    },
    {
      title: "Total Paid",
      dataIndex: "total_paid",
    },
    {
      title: "Due",
      dataIndex: "due",
    },
  ];

  return (
    <>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>CUSTOMER LEDGER</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="d-flex justify-content-between border-bottom ">
                <div className="d-flex mb-4">
                  <div className="ms-3">
                    <p className="m-0">Date: </p>
                    <DateRangePicker
                      onChange={handleSalesDateRage}
                      value={selectedDateRange}
                      style={{ width: "190px" }}
                      showOneCalendar
                    />
                  </div>

                  <div className="ms-1 mb-1">
                    <p className="m-0">Type: </p>
                    <Select
                      style={{ width: "200px" }}
                      showSearch
                      placeholder="Select Type"
                      onChange={handleSalesType}
                      value={selectedType}
                    >
                      <Option value="">All Types</Option>
                      {distinctValues?.customer_types?.map((type, index) => (
                        <Option value={type} key={index}>
                          {type}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="pt-4">
                  <Search
                    className="w-100"
                    placeholder="search here..."
                    allowClear
                    onSearch={handleSearch}
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#46604A",
                          borderColor: "#46604A",
                          color:"white"
                        }}
                      >
                        Search
                      </Button>
                    }
                  />
                </div>
              </div>
              <Table
                columns={salesColumn}
                dataSource={invoiceLists}
                loading={salesLoading}
                rowKey={(record) => record?.sales_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
