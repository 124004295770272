import React, { useEffect, useState } from "react";
import "./FarmerUpdate.css";
import { Button, Form, Input, Select, Space } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import logo from "../../../assets/Agrigate.png";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";

export default function FarmerUpdate() {
  const [farmerProfileDetails, setFarmerProfileDetails] = useState([]);
  const { farmer_id } = useParams();
  const [form] = Form.useForm();
  const { Option } = Select;
  const address_options = [
    { value: "DIN-BIR-NIJ-0001", label: "Dinajpur,Birgonj,Nijpara" },
    { value: "DIN-BIR-SUJ-0001", label: "Dinajpur,Birgonj,Sujalpur" },
    { value: "DIN-BIR-VOG-0001", label: "Dinajpur,Birgonj,Vognagar" },
    { value: "DIN-KAH-MUK-0001", label: "Dinajpur,Kaharole,Mukundopur" },
    { value: "LAL-HAT-PAT-0001", label: "Lalmonirhat,Hatibandha,Patikapara" },
    { value: "LAL-HAT-SHI-0001", label: "Lalmonirhat,Hatibandha,Shingimari" },
    { value: "LAL-HAT-SIN-0001", label: "Lalmonirhat,Hatibandha,Sindurna" },
    { value: "LAL-HAT-TAN-0001", label: "Lalmonirhat,Hatibandha,Tangvangha" },
    { value: "LAK-KAM-CFA-0001", label: "Lakshmipur,Kamalnagar,Char Falcon" },
    { value: "LAK-KAM-CKA-0001", label: "Lakshmipur,Kamalnagar,Char Kadira" },
    { value: "LAK-KAM-HAZ-0001", label: "Lakshmipur,Kamalnagar,Hazirhat" },
  ];
  const activities_options = [
    { value: "Crop", lable: "Crop" },
    { value: "Livestock", lable: "Livestock" },
    { value: "Crop, Livestock", lable: "Crop, Livestock" },
  ];
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/farmerProfile/${farmer_id}/`, {
        headers: authHeader(),
      })
      .then((data) => {
        setFarmerProfileDetails(data?.data);
        form.setFieldsValue(data?.data);

        const initialAddressOption = address_options.find(
          (option) => option.value === data?.data?.address?.address_id
        );
        const initialActivityValue = activities_options.find(
          (option) => option.value === data?.data?.agricultural_activities
        );

        if (initialAddressOption) {
          form.setFieldsValue({ address_id: initialAddressOption.value });
        }
        if (initialActivityValue) {
          form.setFieldsValue({ activities: initialActivityValue.value });
        }
      });
    // eslint-disable-next-line no-use-before-define
  }, [farmer_id, form]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <div className="p-5 border">
      <section className="headSection d-flex align-items-center">
        <div>
          <img
            src={logo}
            style={{ width: "100px", height: "100px" }}
            alt="agrigate"
          />
        </div>
        <div>
          <h5>Update Farmers ID Card</h5>
        </div>
      </section>

      <Form
        className="mt-5"
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        form={form}
      >
        <h6 className="mb-4 ms-5">
          <strong>Personal Information:</strong>
        </h6>
        <Form.Item
          name="farmer_id"
          label="Farmer ID"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            width: "40%",
          }}
        >
          <Input disabled />
        </Form.Item>
        <div className="d-flex justify-content-start">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name required",
              },
            ]}
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: "Please enter a phone number",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const phoneNumber = value ? value.toString() : "";
                  if (phoneNumber.length < 10) {
                    return Promise.reject(
                      new Error("Phone number must be at least 10 digits long")
                    );
                  }
                  if (phoneNumber.length > 11) {
                    return Promise.reject(
                      new Error("Phone number cannot exceed 11 digits")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="d-flex justify-content-start">
          <Form.Item
            name="address_id"
            label="Address"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please select your address!",
              },
            ]}
            style={{
              width: "40%",
            }}
          >
            <Select placeholder="Please select an address">
              {address_options.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="activities"
            label="Agricultural Activities"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please select your Agricultural Activities!",
              },
            ]}
            style={{
              width: "40%",
            }}
          >
            <Select placeholder="Please select an activity">
              {activities_options.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="d-flex justify-content-start">
          <Form.Item
            name="latitude"
            label="Latitude"
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="longitude"
            label="Longitude"
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
        </div>

        <h6 className="mt-5 mb-4 ms-5">
          <strong>Banking Information:</strong>
        </h6>
        <div className="d-flex justify-content-start">
          <Form.Item
            name={["banking", "bank_name"]}
            label="Bank Name"
            rules={[
              {
                required: true,
                message: "Please enter your bank name",
              },
            ]}
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["banking", "branch_name"]}
            label="Branch Name"
            style={{
              width: "40%",
            }}
            rules={[
              {
                required: true,
                message: "Please enter your branch name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          name={["banking", "routing_number"]}
          label="Routing Number"
          style={{
            width: "40%",
          }}
          rules={[
            {
              required: true,
              message: "Please enter your bank account routing number",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="d-flex justify-content-start">
          <Form.Item
            name={["banking", "account_name"]}
            label="Account Name"
            rules={[
              {
                required: true,
                message: "Please enter your account name",
              },
            ]}
            style={{
              width: "40%",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["banking", "account_number"]}
            label="Account Number"
            style={{
              width: "40%",
            }}
            rules={[
              {
                required: true,
                message: "Please enter your account number",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <h6 className="mt-5 mb-4 ms-5">
          <strong>Land/Farm Information:</strong>
        </h6>
        <Form.List name="property_info">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    justifyContent:'flex-start',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "own_land_size"]}
                    label="Own Land:"
                    style={{width:'100%'}}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "lease_land_size"]}
                    label="Lease Land:"
                    style={{width:'100%'}}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "agrigate_contract_land_size"]}
                    label="Agrigate Contract:"
                    style={{width:'100%'}}

                  >
                    <Input placeholder="Agrigate Contract Land Size" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item
          wrapperCol={{
            ...layout.wrapperCol,
            offset: 8,
          }}
        >
          <Button style={{backgroundColor:"#46604A", color:"white"}} htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
