import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
} from "antd";
import "./employee.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Link } from "react-router-dom/";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";
import { EditOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

export default function User() {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [updateUserID, setUpdatedUserID] = useState(null);
  const [form] = Form.useForm();

  const handleUpdateButtonClick = (data) => {
    setUpdatedUserID(data.id)
    form.setFieldsValue(data);
    setUpdateModalVisible(true);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      width: "20%",
      key: "username",
      filters: userList?.map((obj) => ({
        text: obj?.username,
        value: obj?.username,
      })),
      onFilter: (value, record) => record.username?.startsWith(value),
      filterSearch: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",

      filters: Array.from(new Set(userList?.map((obj) => obj?.first_name))).map(
        (value) => ({
          text: value,
          value: value,
        })
      ),
      onFilter: (value, record) => record.first_name?.startsWith(value),
      filterSearch: true,
      width: "15%",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",

      filters: Array.from(new Set(userList?.map((obj) => obj?.last_name))).map(
        (value) => ({
          text: value,
          value: value,
        })
      ),
      onFilter: (value, record) => record.last_name?.startsWith(value),
      filterSearch: true,
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Is Active?",
      render: (text, record) => (record.is_active ? "Active" : "Inactive"),
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record.is_active === value,
      filterSearch: true,
      width: "14%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <Tooltip title="Update">
          <Button
            className="d-flex align-items-center"
            onClick={() => handleUpdateButtonClick(record)}
          >
            Update <EditOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];
  useEffect(() => {
    axios
      .get(`${baseUrl}/employee/usersList`, { headers: authHeader() })
      .then((results) => {
        setUserList(results?.data);
        setLoading(false);
      });
  }, []);

  const handleUpdate = (data) => {
    console.log(data);
    axios
      .put(`${baseUrl}/employee/updateUser/${updateUserID}/`, data, { headers: authHeader() })
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "User updated successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            setUpdateModalVisible(false);
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <>
      <Modal
        title="Update User"
        centered
        open={updateModalVisible}
        onCancel={() => setUpdateModalVisible(false)}
        width={"60%"}
        footer={null}
      >
        <Form
          className="mt-5"
          form={form}
          onFinish={handleUpdate}
          layout="vertical"
        >
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* ID */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please enter name" }]}
              >
                <Input placeholder="User Name" />
              </Form.Item>
            </Col>
            {/* Email */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter email" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            {/* Phone Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                  {
                    pattern: /^[A-Za-z\s]+$/,
                    message: "Only english characters",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                hasFeedback
                label="Password"
                name="password"
                rules={[
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  {
                    pattern:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]+$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@#$%^&*!)",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={2}>
              <Form.Item
                label="Is active?"
                name="is_active"
                initialValue={true}
                valuePropName="checked"
              >
                <Checkbox>active</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} lg={2}>
              <Form.Item
                label="Is staff?"
                name="is_staff"
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox>staff</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} lg={3}>
              <Form.Item
                label="Is super user?"
                name="is_superuser"
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox>super user</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Button */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Form.Item>
              <Button style={{backgroundColor:"#46604A", color:"white"}} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <div className="employeeList">
        <div className="employeeHeaderSection">
          <h5 style={{ color: "#46604A" }}>USER LIST</h5>
        </div>
        <div className="employeeContainer shadow-sm rounded">
          <div className="d-flex justify-content-end">
            <Link to="/createUser">
              <button
                className="px-3 py-2 rounded me-2 mt-3"
                style={{
                  textDecoration: "none",
                  backgroundColor: "#46604A",
                  color: "#ffffff",
                }}
              >
                <AddBoxIcon /> User
              </button>
            </Link>
          </div>
          <div className="m-2 border">
            <Table
              columns={columns}
              dataSource={userList}
              loading={loading}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
    </>
  );
}
