import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  Image,
} from "antd";
import axios from "axios";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import Swal from "sweetalert2";
import dayjs from "dayjs";

export default function LivestockFollowupModal({
  visible,
  onCancel,
  data,
  fetchFollowupLivestockList,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [livestockCompletedTickets, setLivestockCompletedTickets] = useState(
    []
  );
  const [selectedFollowUp, setSelectedFollowup] = useState(null);
  const [ticketStatus, setTicketStatus] = useState("Open");

  const dateFormat = "YYYY-MM-DD";

  useEffect(() => {
    form.setFieldsValue({
      opening_date: data?.ticket?.opening_date,
      ticket_id: data?.ticket?.ticket_id,
      district: data?.ticket?.district,
      name: data?.farmer?.name,
      phone: "0" + data?.farmer?.phone,
      farmer_id: data?.farmer?.farmer_id,
      issue_type: data?.ticket?.issue_type?.issue_type,
      crop_or_livestock_name: data?.ticket?.crop_or_livestock_name,
      ticket_status: data?.ticket?.status,
      advice: data?.followup_status === "Completed" ? null : data?.advice,
      followup_date:
        data?.followup_status === "Completed"
          ? null
          : dayjs(data?.followup_date, dateFormat),
    });
  }, [data]);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/consultation/livestockCompletedTickets/${data?.ticket?.ticket_id}`,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        if (data?.status === 200) {
          setLivestockCompletedTickets(data?.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data?.ticket?.ticket_id]);

  const handleFollowupData = (id) => {
    const selectedFollowup = livestockCompletedTickets?.find(
      (data) => data.livestock_consultation_id === id
    );
    console.log(selectedFollowup);
    setSelectedFollowup(selectedFollowup);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      followups: [],
    });
    setSelectedFollowup(null);
    onCancel();
  };

  const handleUpdate = (value) => {
    value.livestock_consultation_id = data.livestock_consultation_id;
    const convertDate = new Date(value.followup_date);
    const year = convertDate.getFullYear();
    const month = String(convertDate.getMonth() + 1).padStart(2, "0");
    const day = String(convertDate.getDate()).padStart(2, "0");
    const followup_date = `${year}-${month}-${day}`;
    value.followup_date = followup_date;

    axios
      .patch(
        `${baseUrl}/consultation/livestockFollowupUpdate/${value?.livestock_consultation_id}/`,
        value,
        {
          headers: authHeader(),
        }
      )
      .then((data) => {
        console.log(data)
        if (data?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Followup Update Successful",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            onCancel();
            fetchFollowupLivestockList();
          });
        }
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleFollowup = (value) => {
    const convertDate = new Date(value.followup_date);
    const year = convertDate.getFullYear();
    const month = String(convertDate.getMonth() + 1).padStart(2, "0"); // Adding 1 as months are zero-indexed
    const day = String(convertDate.getDate()).padStart(2, "0");
    const followup_date = `${year}-${month}-${day}`;
    value.followup_date = followup_date;
    axios
      .post(
        `${baseUrl}/consultation/livestockFollowupCreate/`,
        {
          data: value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((result) => {
        if (result?.status === 201) {
          Swal.fire({
            title: "Success",
            text: result?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            onCancel();
            fetchFollowupLivestockList();
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const handleTicketStatus = (value) => {
    setTicketStatus(value);
  };

  return (
    <Modal
      title="Livestock Followup"
      centered
      open={visible}
      onCancel={handleCancel}
      footer={null}
      width="40%"
    >
      <Form
        className="mt-4"
        onFinish={
          (data?.followup_status === "Pending" || ticketStatus === "Close") ? handleUpdate : handleFollowup
        }
        form={form}
        layout="horizontal"
      >
        <Form.Item
          hasFeedback
          label="Date"
          name="opening_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Please select ticket date",
            },
          ]}
        >
          <Input placeholder="Date" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Ticket"
          name="ticket_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Ticket required",
            },
          ]}
        >
          <Input placeholder="Ticket ID" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Location"
          name="district"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Location required",
            },
          ]}
        >
          <Input placeholder="Location" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Name"
          name="name"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer name required",
            },
          ]}
        >
          <Input placeholder="Farmer Name" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer Contact"
          name="phone"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer Contact" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Farmer ID"
          name="farmer_id"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Farmer Contact",
            },
          ]}
        >
          <Input placeholder="Farmer ID" readOnly />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 6 }}
          hasFeedback
          label="Crop/Livestock Name"
          name="crop_or_livestock_name"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Crop/Livestock name required",
            },
          ]}
        >
          <Input placeholder="Crop/Livestock Name" readOnly />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Issue Type"
          name="issue_type"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: "Issue type required",
            },
          ]}
        >
          <Input placeholder="Issue Type" readOnly />
        </Form.Item>
        {livestockCompletedTickets?.length > 0 && (
          <Form.Item
            hasFeedback
            label="Followups"
            name="followups"
            labelCol={{ span: 6 }}
            style={{ marginBottom: 8 }}
          >
            <Select placeholder="Select Followup" onChange={handleFollowupData}>
              {livestockCompletedTickets?.map((item) => (
                <Option
                  value={item?.livestock_consultation_id}
                  key={item?.livestock_consultation_id}
                >
                  {item?.followup_date}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedFollowUp !== null ? (
          <div
            className="border d-flex justify-content-around align-items-center p-2"
            style={{ marginLeft: "80px" }}
          >
            <div>
              <p>
                <strong>Issue Details:</strong>{" "}
                {selectedFollowUp?.issue_details}
              </p>
              <p>
                <strong>Advice:</strong> {selectedFollowUp?.advice}
              </p>
            </div>
            <div>
              <Image src={selectedFollowUp?.issue_image} width={400} />
            </div>
          </div>
        ) : (
          ""
        )}

        <Form.Item
          hasFeedback
          label="Advice"
          name="advice"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: ticketStatus === "Open" ? true : false,
              message: "Please input advice",
            },
            {
              pattern: /^[A-Za-z0-9\s,._'"\/;-]+$/,
              message: "Only English characters",
            },
          ]}
        >
          <TextArea rows={3} placeholder="Advice" />
        </Form.Item>
        <Form.Item
          label="Followup Date"
          name="followup_date"
          labelCol={{ span: 6 }}
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: ticketStatus === "Open" ? true : false,
              message: "Followup date required",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        {data?.followup_status === "Completed" && (
          <Form.Item
            hasFeedback
            label="Ticket Status"
            name="ticket_status"
            labelCol={{ span: 6 }}
            style={{ marginBottom: 8 }}
            rules={[
              {
                required: true,
                message: "Ticket status required",
              },
            ]}
          >
            <Select
              placeholder="Select ticket status"
              onChange={handleTicketStatus}
            >
              <Option value="Open">Open</Option>
              <Option value="Close">Close</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item style={{ textAlign: "right", margin: 0 }}>
          {(data?.followup_status === "Pending" ||
            ticketStatus === "Close") && (
            <Button
              htmlType="submit"
              style={{
                backgroundColor: "#ffffff",
                color: "#46604A",
                marginRight: 10,
              }}
              disabled={loading}
            >
              Update
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          )}
          {data?.followup_status === "Completed" &&
            (ticketStatus === "Close" ? null : (
              <Button
                style={{ backgroundColor: "#46604A", color: "#ffffff" }}
                htmlType="submit"
                disabled={loading}
              >
                Followup
                {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            ))}
        </Form.Item>
      </Form>
    </Modal>
  );
}
