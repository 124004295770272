import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Image, Modal, Select, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";

export default function BeefSurvey() {
  const { Option } = Select;
  const [beefSurvey, setBeefSurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedUpazila, setSelectedUpazila] = useState("");
  const [selectedUnion, setSelectedUnion] = useState("");
  const [surveyDistinctValues, setSurveyDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxD3nLM3Z1T_c9--w5uS4R8bPWABXXLXuw1dij67fo1oQCKhQFFOpFaNHwXdrcM0KPR/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setSurveyDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handleUpazila = (value) => {
    setSelectedUpazila(value);
  };
  const handleUnion = (value) => {
    setSelectedUnion(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbyC6UgRzqVnzs-DO0UTngDq9x20vduTOp6oBQD7mOw8rZ_pmnPa-r7bJa06vipFYW6rGQ/exec?page=${currentPage}&district=${selectedDistrict}&upazila=${selectedUpazila}&union=${selectedUnion}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setBeefSurvey(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    selectedDateRange,
    selectedDistrict,
    selectedUnion,
    selectedUpazila,
  ]);

  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };
  const handleImageClick = (url) => {
    setViewImageModal(true);
    setImageUrl(url);
  };

  const beefColumns = [
    {
      title: "Survey Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Farmer ID",
      width: "11%",
      dataIndex: "Farmer_ID",
    },
    {
      title: "Farmer Name",
      width: "12%",
      dataIndex: "Farmer_Name",
    },
    {
      title: "Farmer Phone",
      width: "12%",
      render: (record) => {
        return "0" + record?.Phone_Number; // Add '0' to the beginning of the phone number
      },
    },

    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },
    {
      title: "Farm Size",
      dataIndex: "Farm_Size",
      width: "10%",
    },
    {
      title: "Fattening Cow",
      dataIndex: "Number_of_fattening_cows",
      width: "10%",
    },
    {
      title: "Bull",
      dataIndex: "Number_of_bulls",
      width: "10%",
    },
    {
      title: "Bullocks",
      dataIndex: "Number_of_bullocks",
      width: "12%",
    },
    {
      title: "Heifer/Cow",
      dataIndex: "number_of_heifers/cows",
      width: "12%",
    },
    {
      title: "FO Name",
      dataIndex: "Field_Officer_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Farm Photo"
        centered
        visible={viewImageModal} // Use 'visible' to control the visibility of the Modal
        footer={null}
        onCancel={() => {
          setViewImageModal(false);
        }}
      >
        <Image width={200} src={imageUrl} alt="Farm Photo" />
      </Modal>
      <Modal
        title="Beef Survey"
        centered
        open={viewModal}
        onCancel={() => setViewModal(false)}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Farmer Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Farmer ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_ID}
                </td>
              </tr>
              <tr>
                <th className="p-2">Farmer Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farmer_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Farmer Phone</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Phone_Number}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Beef Cattle Farm Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Cattle Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-50">Number of fattening cows</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_fattening_cows}
                </td>
              </tr>
              <tr>
                <td className="p-2">Bulls</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_bulls}
                </td>
              </tr>
              <tr>
                <td className="p-2">Bullocks</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Number_of_bullocks}
                </td>
              </tr>
              <tr>
                <td className="p-2">Heifers/Cows</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.number_of_heifers_cows}
                </td>
              </tr>
              <tr>
                <td className="p-2">Cattle Breeds</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Breed_of_cattle}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center" colSpan={2}>
                  Farm Info
                </th>
              </tr>
              <tr>
                <td className="p-2 w-25">Farm Size (sqft)</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Farm_Size}
                </td>
              </tr>
              <tr>
                <td className="p-2">Farm Photo</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  <Button
                    onClick={() => handleImageClick(modalData?.Farm_Photo)}
                  >
                    click
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Feed Prices
        </div>
        <table
          className="w-50 table-bordered"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th
                className="p-2 text-center w-75"
                style={{ backgroundColor: "#E6E6E6" }}
              >
                Total Feed Cost per cow
              </th>
              <td className="p-2 text-center w-25">
                {modalData?.Total_cattle_feed_cost}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="d-flex justify-content-between mt-2"
          style={{ backgroundColor: "#607D43", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">Concentrate Info</div>
          <div className="w-50 border text-center py-1">Grass Info</div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <thead className="m-5">
                <tr>
                  <th className="p-2 text-center">Product</th>
                  <th className="p-2 text-center">Amount per cow</th>
                  <th className="p-2 text-center">Cost per cow</th>
                </tr>
              </thead>
              <tbody className="m-5">
                <tr>
                  <td className="p-2 w-25">Wheat Bran</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_wheat_bran_amount_per_cow_Kg}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_cost_of_Wheat_bran_per_cow_Tk}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Broken pulse</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_broken_pulse_amount_per_cow_Kg}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_cost_of_Broken_pulse_per_cow_Tk}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Oil Cake</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_oil_cake_amount_per_cow_Kg}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_cost_of_oil_cake_per_cow_Tk}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Broken Rice</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_Broken_Rice_amount_per_cow_Kg}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_Broken_Rice_amount_per_cow_Kg}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Broken_Maize</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_Broken_Maize_amount_per_cow_kg}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_cost_of_Broken_maize_per_cow_Tk}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25" colSpan={2}>
                    Cost per kg to mix ingredients at home (Tk)
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {
                      modalData?.How_much_does_it_cost_per_kg_to_mix_ingredients_at_home_Tk
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr
                  className="text-center"
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  <td className=" py-1" colSpan={2}>
                    Ready Feed Info
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Company Name</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Ready_Feed_Compnay_Name}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Daily amount per cow (kg)</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Amount_ready_feed_given_per_day}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Ready feed price per kg</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Ready_feed_price_per_kg}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <td className="px-2 py-1 w-50">Daily amount per cow (kg)</td>
                  <td style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Daily_grass_amount_per_cow_kg}
                  </td>
                </tr>
                <tr>
                  <td className="px-2 py-1">Selling surplus grass</td>
                  <td style={{ backgroundColor: "#E6E6E6" }}>
                    {
                      modalData?.Selling_surplus_grass_from_owned_field_after_cow_feeding
                    }
                  </td>
                </tr>
                <tr>
                  <td className="px-2 py-1">Average daily grass sales</td>
                  <td style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Average_daily_grass_sales}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr
                  className="text-center"
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  <td className=" py-1" colSpan={2}>
                    Hay Info
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Daily amount per cow (kg)</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Daily_amount_of_hay_per_cow_Kg}</td>
                </tr>
                <tr>
                  <td className="p-2">Local Hay price per kg</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Local_hay_price_per_kg}</td>
                </tr>
                <tr>
                  <td className="p-2">Daily Cost per cow</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Daily_hay_cost_per_cow_Tk}</td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr
                  className="text-center"
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  <td className=" py-1" colSpan={2}>
                    UMS / Block Info
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Daily amount per cow (kg)?</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Amount_of_UMS_given_Kg}</td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr
                  className="text-center"
                  style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                >
                  <td className=" py-1" colSpan={2}>
                    Grain Info
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Daily Amount per Cow (kg)</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Daily_grain_amount_per_cow_Kg}</td>
                </tr>
                <tr>
                  <td className="p-2 w-50">Buying Place</td>
                  <td
                    className="p-2"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >{modalData?.Where_do_you_buy_grain}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Fattening Info
        </div>
        <table
          className="w-100 table-bordered"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <td className="p-2 w-50">Buying cattle age for fattening</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Typical_age_for_fattening_cows}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">Buying Cattle weight</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}></td>
            </tr>
            <tr>
              <td className="p-2 w-50">Measure System</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Measure_System}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">
                Time interval of weight measurement after 1st
              </td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Time_interval_of_taking_weight_Month}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">Times rear cattle in a year</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Times_rear_cattle_in_a_year}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">
                Month duration of fattening before selling
              </td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}></td>
            </tr>
            <tr>
              <td className="p-2 w-50">Present weight of cattles</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Present_avg_weight_of_cattle_kg}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">Average weight of fattened bulls</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Average_weight_of_fattened_bulls}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">Average weight of fattened bullock</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Average_weight_of_fattened_bullock}</td>
            </tr>
            <tr>
              <td className="p-2 w-50">Cow selling place</td>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>{modalData?.Where_to_sell_cows}</td>
            </tr>
          </tbody>
        </table>
        <div
          className="d-flex justify-content-between mt-2"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">Waste Management</div>
          <div className="w-50 border text-center py-1">Deworming Info</div>
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-50">Waste Management Methods</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Waste_Management_Method}
                </td>
              </tr>
              <tr>
                <td className="p-2">Sales Cow Dung</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Do_you_sell_cow_dung}
                </td>
              </tr>
              <tr>
                <td className="p-2">Revenue (year)</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Yearly_Earnings_from_selling_dung}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-50">Does Deworming</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Deworming_Status}
                </td>
              </tr>
              <tr>
                <td className="p-2">Name of dewarmer</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Name_of_dewarmer}
                </td>
              </tr>
              <tr>
                <td className="p-2">Cost of deworming</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Cost_of_deworming_Per_Cow}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="d-flex justify-content-between mt-2"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          <div className="w-50 border text-center py-1">Vaccination</div>
          <div className="w-50 border text-center py-1">Vitamin or Tonic</div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <td className="p-2 w-50">Disease names for vaccination</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vaccination_against_what_disease}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Vaccine Type</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Which_vaccine_do_you_use}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Private vaccine company name</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.If_Private_vaccine_company_name}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Vaccination Cost per cow</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.vaccination_cost_per_cow}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Who gives the vaccine</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Who_give_the_vaccine}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <td className="p-2 w-50">Use any vitamin or tonic</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vitamins_tonics_etc_are_used_to_fatten_cows}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="mt-3 mb-1 text-center py-1"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Organizational Information
        </div>
        <div className="d-flex justify-content-between">
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <td
                    className="p-2 text-center w-75"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Involved with any organization
                  </td>
                  <td className="p-2 text-center w-25">{modalData?.Involve_With_another_Org}</td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <thead className="m-5">
                <tr>
                  <th className="p-2 text-center">Stakeholder</th>
                  <th className="p-2 text-center">Name</th>
                  <th className="p-2 text-center">Number</th>
                </tr>
              </thead>
              <tbody className="m-5">
                <tr>
                  <td className="p-2 w-25">Organization</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Company_Distributor_NGO_name}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Company_Distributor_NGO_phone}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Vet</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vet_Name}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vet_Phone}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 w-25">Vaccine provider</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vaccine_provider_name}
                  </td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Vaccine_provider_Phone}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <th
                    className="p-2 text-center"
                    colSpan={2}
                    style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                  >
                    Agrigate Interest
                  </th>
                </tr>
                <tr>
                  <td className="p-2 w-50">Interested to work with Agrigate</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Deworming_Status}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Attend regular meetings</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Age_month_of_first_deworming}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Pay method</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Time_interval_of_deworming}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Pay dues on time</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Time_interval_of_deworming}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-50">
            <table
              className="w-100 table-bordered"
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <th
                    className="p-2 text-center"
                    colSpan={2}
                    style={{ backgroundColor: "#607D43", color: "#ffffff" }}
                  >
                    Loan Info
                  </th>
                </tr>
                <tr>
                  <th className="p-2 w-50">Take loan from NGO</th>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Deworming_Status}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Amount of loan</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Age_month_of_first_deworming}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Purpose</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Time_interval_of_deworming}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Weekly installment</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Time_interval_of_deworming}
                  </td>
                </tr>
                <tr>
                  <td className="p-2">Weekly installemnts left</td>
                  <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                    {modalData?.Time_interval_of_deworming}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>BEEF SURVEY DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {surveyDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Upazila: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select upazila"
                showSearch
                onChange={handleUpazila}
                value={selectedUpazila}
              >
                <Option value="">All Upazilas</Option>
                {surveyDistinctValues?.distinctUpazila?.map(
                  (upazila, index) => (
                    <Option value={upazila} key={index}>
                      {upazila}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Union: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select union"
                showSearch
                onChange={handleUnion}
                value={selectedUnion}
              >
                <Option value="">All Unions</Option>
                {surveyDistinctValues?.distinctUnion?.map((union, index) => (
                  <Option value={union} key={index}>
                    {union}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            columns={beefColumns}
            dataSource={beefSurvey}
            loading={tableLoading}
            rowKey={(record) => record.Farmer_ID}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
