import { Modal } from "antd";
import React from "react";

export default function PrescriptionModal({ viewModal, data, closeModal }) {
  return (
    <Modal open={viewModal} onCancel={closeModal} footer={false} width="50%">
      <div
        className="d-flex mt-4 align-items-center justify-content-between"
        style={{
          height: "100px",
          border: "1px solid #46604A",
          color: "#46604A",
          fontSize: 16,
        }}
      >
        <div>
          <h5 className="ms-2">Agrigate Network Limited</h5>
        </div>
        <div>
          <p className="me-2 m-0">Dr. Nasrin Akter</p>
          <p className="me-2 m-0">Veterinary Officer</p>
          <p className="me-2 m-0">Regi. No. 7682</p>
          <p className="me-2 m-0">Cell: 01324741625</p>
        </div>
      </div>

      <div
        className="d-flex mt-4 align-items-center justify-content-between"
        style={{
          height: "100px",
          border: "1px solid #46604A",
          color: "#46604A",
          fontSize: 16,
        }}
      >
        <div>
          <p className="ms-2 m-0">Date:</p>
          <p className="ms-2 m-0">Farmer ID:</p>
          <p className="ms-2 m-0">District:</p>
        </div>
        <div>
          <p className="m-0">Name:</p>
          <p className="m-0">Ticket:</p>
        </div>
        <div>
          <p className="me-5 m-0">Phone:</p>
        </div>
      </div>

      <div className="d-flex mt-4 align-items-center justify-content-between">
        <div
          className="w-25 me-3"
          style={{
            height: "600px",
            border: "1px solid #46604A",
            color: "#46604A",
            fontSize: 16,
          }}
        >
          <div className="m-2">
            <h5 className="m-0">
              <strong>Issue Type:</strong>
            </h5>
            <p
              className="border m-0"
              style={{
                height: "180px",
              }}
            ></p>
          </div>
          <div className="m-2">
            <h5 className="m-0">
              <strong>Advice:</strong>
            </h5>
            <p
              className="border m-0"
              style={{
                height: "180px",
              }}
            ></p>
          </div>
          <div className="m-2">
            <h5 className="m-0">
              <strong>Follow-up Date:</strong>
            </h5>
            <p
              className="border m-0"
              style={{
                height: "80px",
              }}
            ></p>
          </div>
        </div>

        <div
          className="w-75 ms-3"
          style={{
            height: "600px",
            border: "1px solid #46604A",
            color: "#46604A",
            fontSize: 16,
          }}
        >
          <p className="ms-2 mt-2 m-0">R/x</p>
        </div>
      </div>
      <div
        className="d-flex mt-4 align-items-end justify-content-between"
        style={{
          height: "120px",
          border: "1px solid #46604A",
          color: "#46604A",
          fontSize: 16,
        }}
      >
        <div className="ms-2 my-2">
          <p className="me-2 m-0">Agrigate Network Limited</p>
          <p className="me-2 m-0">Agrigate Address</p>
          <p className="me-2 m-0">Email</p>
          <p className="me-2 m-0">Phone</p>
        </div>
        <div>
          <p className="me-5 m-0 mb-2">Signature</p>
        </div>
      </div>
    </Modal>
  );
}
