import React, { useEffect, useRef, useState } from "react";
import "./HomePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { Select } from "antd";
import authHeader from "../Authentication/authHeader";
import baseUrl from "../../config";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon } from "leaflet";
import retailerList from "../Sales/RouteMap/retailerData";

export default function HomePage() {
  const { Option } = Select;
  const mapRef = useRef(null);
  const [farmerDistrict, setFarmerDistrict] = useState("");
  const [retailerDistrict, setRetailerDistrict] = useState("");
  const [calculateSales, setCalculateSales] = useState({});
  const [contractFarmingDetails, setContractFarmingDetails] = useState([]);
  const [district, setDistrict] = useState([]);
  const [sourcingInfo, setSourcingInfo] = useState(null);
  const [sourcingProductsList, setSourcingProductsList] = useState([]);
  const [selectedSourcingProduct, setSelectedSourcingProduct] = useState("");
  const [selectedSourcingDistrict, setSelectedSourcingDistrict] = useState("");

  const today = new Date();
  const [retailerKYC, setRetailerKYC] = useState([]);
  const [farmerKYC, setFarmerKYC] = useState([]);
  const [lspKYC, setLSPKYC] = useState([]);
  const [dairySurvey, setDairySurvey] = useState([]);
  const [beefSurvey, setBeefSurvey] = useState([]);
  const [agroProcessorSurvey, setAgroProcessorSurvey] = useState([]);
  const [warehouseSurvey, setWarehouseSurvey] = useState([]);
  const [poultrySurvey,setPoultrySurvey] = useState([])

  // Create custom icon
  const customIcon = new Icon({
    iconUrl: require("../Sales/RouteMap/icons/placeholder.png"),
    iconSize: [30, 40],
  });


  useEffect(() => {
    axios
      .get(`${baseUrl}/api/districts/`, {
        headers: authHeader(),
      })
      .then((response) => setDistrict(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/trader/sourcingProductList`, {
        headers: authHeader(),
      })
      .then((data) => setSourcingProductsList(data?.data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycby5cUvzcQ-ekH2O-xv0usK-sKB0_5DI0zrl2n7DkkxuBbORbpkdw7VV9qOSYh_wrHE17Q/exec"
    )
      .then((response) => response.json())
      .then((data) => setFarmerKYC(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbw2j3ejdKTPtyfHUjtGXuCGtJdNq9GanOd1uukZmtnL1MKHggmODX005KARZMTl7RSL/exec"
    )
      .then((response) => response.json())
      .then((data) => setRetailerKYC(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbw66KO3qu8xdsACgmtuHFqcVaJqJydk6plWSxdXRn5Tg8TAaQQb0ldzsk7FusmhYLI/exec"
    )
      .then((response) => response.json())
      .then((data) => setLSPKYC(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxzkFgbn4Jm_dAMHc-YdQa0mFt_Vfh_DhV5ZliPx7BHYIXL03qYgACU-F_lYRCUit4HCw/exec"
    )
      .then((response) => response.json())
      .then((data) => setAgroProcessorSurvey(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbydjny9cA7lGvy_Kh-KqgKcBhTL5Qa_j_DAizehHfGdWVRzYHZ6igxLJC0zQDiMlt6arA/exec"
    )
      .then((response) => response.json())
      .then((data) => setDairySurvey(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbyFm3LSqdDO_s2WNMxvWgoZdpF2chHmN1t8_htMtAxRrGY_BJ9ZqMtlNsb3lYQw-YqdNQ/exec"
    )
      .then((response) => response.json())
      .then((data) => setBeefSurvey(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbzOuqEqgXmw65Uv6zAdc2sgUtL_TSiY3hkjDX6G95Y-RW8HNUXs0lUfD209cKTzmNw/exec"
    )
      .then((response) => response.json())
      .then((data) => setWarehouseSurvey(data));
  }, []);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxqpGVUp8N-zPqvCiSpxE56Usuk58vnGaj-ruRGFBCplQRWgYC0MIF7waqXZoKFZ-7ILw/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setPoultrySurvey(data?.data);
      });
  }, []);

  function filterData(filteredData) {
    return filteredData?.filter((item) => {
      const itemDate = new Date(item?.Date);
      const todayStart = new Date(today);
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date(today);
      todayEnd.setHours(23, 59, 59, 999);
      return itemDate >= todayStart && itemDate <= todayEnd;
    });
  }

  const retailerTodaysData = filterData(retailerKYC);
  const todaysDistinctRetailer = [
    ...new Set(retailerTodaysData?.map((item) => item?.Retailer_ID)),
  ];

  const farmerTodaysData = filterData(farmerKYC);
  const todaysDistinctFarmer = [
    ...new Set(farmerTodaysData?.map((item) => item?.Farmer_ID)),
  ];
  const lspTodaysData = filterData(lspKYC);
  const todaysDistinctLSP = [
    ...new Set(lspTodaysData.map((item) => item?.LSP_ID)),
  ];
  const agroProcessorTodaysData = filterData(agroProcessorSurvey);
  const todaysDistinctAgroProcessor = [
    ...new Set(
      agroProcessorTodaysData?.map((item) => item?.Manager_Phone_Number)
    ),
  ];
  const dairyTodaysData = filterData(dairySurvey);
  const todaysDistinctDairy = [
    ...new Set(dairyTodaysData?.map((item) => item?.Farmer_ID)),
  ];
  const beefTodaysData = filterData(beefSurvey);
  const todaysDistinctBeef = [
    ...new Set(beefTodaysData?.map((item) => item?.Farmer_ID)),
  ];
  const warehouseTodaysData = filterData(warehouseSurvey);
  const todaysDistinctWarehouse = [
    ...new Set(warehouseTodaysData?.map((item) => item?.Farmer_ID)),
  ];
  const poultryTodaysData = filterData(poultrySurvey);
  const todaysDistinctPoultry = [
    ...new Set(poultryTodaysData?.map((item) => item?.Farmer_ID)),
  ];


  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/calculate_sales/`, {
        headers: authHeader(),
        params: {
          farmer_district_name: farmerDistrict,
          retailer_district_name: retailerDistrict,
        },
      })
      .then((data) => {
        setCalculateSales(data?.data);
      });
  }, [farmerDistrict, retailerDistrict]);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbyzWN4H-HSXHf93rHZegPZr9rzFtA7DQwCmcKBar58T9ze92u-ApEvYe0ow5XE64HvDAA/exec`
      )
      .then((data) => {
        console.log(data?.data);
        setContractFarmingDetails(data?.data?.data)
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/sourcingInfo`, {
        headers: authHeader(),
        params: {
          district: selectedSourcingDistrict,
          product: selectedSourcingProduct,
        },
      })
      .then((response) => {
        setSourcingInfo(response?.data);
      });
    // eslint-disable-next-line no-sparse-arrays
  }, [, selectedSourcingDistrict, selectedSourcingProduct]);

  const farmerDistrictValue = (value) => {
    setFarmerDistrict(value);
  };

  const retailerDistrictValue = (value) => {
    setRetailerDistrict(value);
  };

  const sourcingDistrictHandle = (value) => {
    setSelectedSourcingDistrict(value);
  };
  const sourcingProductHandle = (value) => {
    setSelectedSourcingProduct(value);
  };

  return (
    <div className="homeDashboard">
      <div className="homeNameSection">
        <h5 style={{ color: "#46604A" }}>Home Page</h5>
      </div>
      <div className="homeDashboardContainer shadow-sm rounded">
        <div className="miniDashboardSection">
          <div className="surveySection rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              SURVEY & KYC
            </h5>
            <p style={{color:"#46604A"}}>
              <strong>Todays Survey & kyc update</strong>
            </p>
            <div className="row mx-2" style={{color:"#46604A"}}>
              <div className="col-md-6 border">
                <p className="mb-0">Farmer KYC: </p>
                <p className="mb-0">
                  {todaysDistinctFarmer ? todaysDistinctFarmer.length : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">Retailer KYC: </p>
                <p className="mb-0">
                  {todaysDistinctRetailer ? todaysDistinctRetailer.length : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">LSP KYC: </p>
                <p className="mb-0">
                  {todaysDistinctLSP ? todaysDistinctLSP.length : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">Dairy Survey: </p>
                <p className="mb-0">
                  {todaysDistinctDairy ? todaysDistinctDairy.length : 0}
                </p>
              </div>
            </div>
            <div className="row mx-2" style={{color:"#46604A"}}>
              <div className="col-md-6 border">
                <p className="mb-0">Beef Survey: </p>
                <p className="mb-0">
                  {todaysDistinctBeef ? todaysDistinctBeef.length : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">Agro-Processor Survey: </p>
                <p className="mb-0">
                  {todaysDistinctAgroProcessor
                    ? todaysDistinctAgroProcessor.length
                    : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">Warehouse Survey: </p>
                <p className="mb-0">
                  {todaysDistinctWarehouse ? todaysDistinctWarehouse.length : 0}
                </p>
              </div>
              <div className="col-md-6 border">
                <p className="mb-0">Poultry Survey: </p>
                <p className="mb-0">
                  {todaysDistinctPoultry ? todaysDistinctPoultry.length : 0}
                </p>
              </div>
            </div>
            <p className="mt-4">
              <Link className="farmerCashSalesDashboardBtn" to="/survey">
                See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
              </Link>
            </p>
          </div>
          <div className="farmerSalesSection rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              FARMER SALES
            </h5>
            <div className="farmerSalesDetails mt-3">
              <div className="border farmerCropSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Crop Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_crop_sales_farmer?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerLivestockSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Livestock Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_livestock_sales_farmer?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerTotalSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_sales_farmer?.toLocaleString() || 0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerSalesFilter">
                <Select
                  className="mt-4 w-75"
                  name="Select"
                  onChange={farmerDistrictValue}
                  placeholder="Select District"
                  showSearch
                  size="large"
                >
                  <Option value="">All Districts</Option>
                  {district?.map((district, index) => (
                    <Option key={index} value={district.district_name}>
                      {district.district_name}
                    </Option>
                  ))}
                </Select>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/cashSales">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
            </div>
          </div>
          {/* contract farmer mini dashboard start*/}

          <div className="contractFarmingSection  rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              CONTRACT FARMING
            </h5>
            <div className="contractFarmingDetails mt-3">
              <div className="border totalCreditAmountDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Credit Amount</strong>
                </h6>
                <h6>
                  {contractFarmingDetails?.Total_Credit?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h6>
              </div>
              <div className="border totalRecoveredDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Recovered</strong>
                </h6>
                <h6>
                  {contractFarmingDetails?.Total_Recovered?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h6>
              </div>
              <div className="border totalPendingDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Pending</strong>
                </h6>
                <h6>
                  {contractFarmingDetails?.Total_Due?.toLocaleString() || 0}{" "}
                  BDT
                </h6>
              </div>
              <p className="mt-4">
                <Link className="farmerCashSalesDashboardBtn" to="/contractFarming">
                  See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                </Link>
              </p>
            </div>
          </div>

          {/* contract farmer mini dashboard end*/}
          <div className="mapSection rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              MAP
            </h5>
            <div style={{ height: "280px", overflow: "hidden" }}>
              <MapContainer center={[17.7902, 90.4096]} zoom={5.5} ref={mapRef}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* Markers */}
                <MarkerClusterGroup>
                  {retailerList.map((retailer, index) => (
                    <Marker
                      key={index}
                      position={[retailer.Latitude, retailer.Longitude]}
                      icon={customIcon}
                      // ref={(marker) => {
                      //   if (marker) {
                      //     marker.leafletId = retailer.Retailer_ID;
                      //     markers.current.push(marker);
                      //   }
                      // }}
                    >
                      <Popup>
                        <div>
                          <h5>Retailer Info</h5>
                          {/* Render Popup Content */}
                          <p>Retailer Name: {retailer.Retailer_Name}</p>
                          <p>Retailer ID: {retailer.Retailer_ID}</p>
                          <p>Phone: {retailer.Retailer_Phone}</p>
                          <p>Traders Name: {retailer.Traders_Name}</p>
                          <p>Bazar Name: {retailer.Bazar_Name}</p>
                          <p>District: {retailer.District_Name}</p>
                          <p>Upazila: {retailer.Upazila_Name}</p>
                          <p>Union: {retailer.Union_Name}</p>
                          <p>Last Visited: {retailer.Last_visited}</p>
                          <p>Route Name: {retailer.Route_name}</p>
                          <p>Assigned To: {retailer.Assigned_to}</p>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                </MarkerClusterGroup>
              </MapContainer>
            </div>
            <p className="mt-4">
              <small>
                <Link className="farmerCashSalesDashboardBtn" to="/routeMap">
                  See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                </Link>
              </small>
            </p>
          </div>
          <div className="retailerSalesSection rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              RETAILER SALES
            </h5>
            <div className="farmerSalesDetails mt-3">
              <div className="border farmerCropSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Crop Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_crop_sales_retailer?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerLivestockSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Livestock Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_livestock_sales_retailer?.toLocaleString() ||
                    0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerTotalSalesDetails">
                <h5 className="font-weight-bold">
                  <strong>Total Sales</strong>
                </h5>
                <h5>
                  {calculateSales?.total_sales_retailer?.toLocaleString() || 0}{" "}
                  BDT
                </h5>
              </div>
              <div className="border farmerSalesFilter">
                <Select
                  className="mt-4 w-75"
                  name="Select"
                  onChange={retailerDistrictValue}
                  placeholder="Select District"
                  showSearch
                  size="large"
                >
                  <Option value="">All Districts</Option>
                  {district?.map((district, index) => (
                    <Option key={index} value={district.district_name}>
                      {district.district_name}
                    </Option>
                  ))}
                </Select>
                <p className="mt-4">
                  <Link className="farmerCashSalesDashboardBtn" to="/cashSales">
                    See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                  </Link>
                </p>
              </div>
            </div>
          </div>

          {/* Sourcing mini dashboard starts*/}
          <div className="sourcingSection rounded text-center">
            <h5
              className="text-center"
              style={{
                borderBottom: "3px solid #46604A",
                color: "#46604A",
                lineHeight: "50px",
              }}
            >
              SOURCING
            </h5>
            <div className="sourcingDetails mt-3">
              <div className="border totalCreditAmountDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Purchase Qty (Kg)</strong>
                </h6>
                <h6>
                  {sourcingInfo?.total_purchase_quantity?.toLocaleString() || 0}
                  Kg
                </h6>
              </div>
              <div className="border totalRecoveredDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Purchase Amount</strong>
                </h6>
                <h6>
                  {sourcingInfo?.total_purchase_amount?.toLocaleString() || 0}{" "}
                  BDT
                </h6>
              </div>
              <div className="border totalPendingDetails">
                <h6 className="font-weight-bold">
                  <strong>Total Sales Amount </strong>
                </h6>
                <h6>
                  {sourcingInfo?.total_sale_amount?.toLocaleString() || 0} BDT
                </h6>
              </div>
              <div className="border contractFarmingFilter">
                <Select
                  className="mt-3 w-75"
                  placeholder="Select District"
                  showSearch
                  onChange={sourcingDistrictHandle}
                >
                  <Option value="">All Districts</Option>
                  {district?.map((district, index) => (
                    <Option key={index} value={district.district_name}>
                      {district.district_name}
                    </Option>
                  ))}
                </Select>

                <Select
                  placeholder="Select product"
                  showSearch
                  className="w-75 mt-1"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={sourcingProductHandle}
                >
                  <Option value="">All Products</Option>
                  {sourcingProductsList?.map((ip) => (
                    <Option
                      key={ip.sourcing_product_id}
                      value={ip.product_name}
                    >
                      {ip.product_name}
                    </Option>
                  ))}
                </Select>

                <p className="mt-4">
                  <small>
                    <Link className="farmerCashSalesDashboardBtn" to="/productSourcing">
                      See Dashboard <FontAwesomeIcon icon={faHandPointRight} />
                    </Link>
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
