import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Form, Input, Modal, Select, Spin, Table, Tooltip } from "antd";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

export default function SourcingProducts() {
  const [form] = Form.useForm();
  const [createForm] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [sourcingProductLists, setSourcingProductLists] = useState([]);
  const [sourcingProductLoading, setSourcingProductLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleUpdateModal = (record) => {
    setUpdateModal(true);
    form.setFieldsValue({
      sourcing_product_id: record?.sourcing_product_id,
      product_name: record?.product_name,
      category: record?.category,
      sub_category: record?.sub_category,
    });
  };
  const handleUpdateModalClose = () => {
    setUpdateModal(false);
  };
  const handleCreateModal = () => {
    setCreateModal(true);
  };
  const handleCreateModalClose = () => {
    setCreateModal(false);
  };
  const fetchData = async () => {
    try {
      setSourcingProductLoading(true);
      const response = await axios.get(
        `${baseUrl}/sourcing/sourcingProductList/`,
        {
          headers: authHeader(),
          params: {
            page:currentPage
          }
        }
      );
      setTotalItems(response?.data?.count);
      setSourcingProductLists(response?.data?.results);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setSourcingProductLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleCreate = (value) => {
    setCreateLoading(true);
    axios
      .post(
        `${baseUrl}/sourcing/sourcingProductCreate/`,
        {
          data: value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setCreateLoading(false);
        console.log(response)
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            form.resetFields();
            fetchData();
            handleCreateModalClose();
          });
        }
      })
      .catch((error) => {
        console.log(error)
        setCreateLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const handleUpdate = (value) => {
    setLoading(true);
    axios
      .patch(
        `${baseUrl}/sourcing/sourcingProductUpdate/${value?.sourcing_product_id}/`,
        value,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Product Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            fetchData();
            handleUpdateModalClose();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  const productColumn = [
    {
      title: "Date",
      render: (record) =>
        record?.created_at
          ? new Date(record?.created_at).toISOString().slice(0, 10)
          : "",
    },
    {
      title: "Product ID",
      dataIndex: "sourcing_product_id",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
    },

    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Sub Category",
      dataIndex: "sub_category",
    },
    {
      title: "Action",
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="Update">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleUpdateModal(record)}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Add Sourcing Product"
        open={updateModal}
        onCancel={handleUpdateModalClose}
        footer={false}
      >
        <Form form={form} layout="horizontal" onFinish={handleUpdate}>
          <Form.Item
            label="Sourcing Product ID"
            name="sourcing_product_id"
            rules={[
              {
                required: true,
                message: "Sourcing product ID required",
              },
            ]}
          >
            <Input placeholder="Sourcing Product ID" readOnly />
          </Form.Item>
          <Form.Item
            label="Product Name"
            name="product_name"
            rules={[
              {
                required: true,
                message: "Please enter sourcing product name",
              },
            ]}
          >
            <Input placeholder="Product Name" />
          </Form.Item>
          <Form.Item
            label="Category"
            name="category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select placeholder="Select Product Category">
              <Option value="Livestock">Livestock</Option>
              <Option value="Crop">Crop</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Sub Category"
            name="sub_category"
            rules={[
              {
                required: true,
                message: "Please select sub-category",
              },
            ]}
          >
            <Select placeholder="Select Product Sub-Category">
              <Option value="Perishable">Perishable</Option>
              <Option value="Non Perishable">Non Perishable</Option>
            </Select>
          </Form.Item>
          <Form.Item className="d-flex justify-content-end ">
            <Button
              htmlType="submit"
              disabled={loading}
              style={{ color: "#ffffff", backgroundColor: "#46604A" }}
            >
              Update
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Sourcing Product"
        className="mb-5"
        open={createModal}
        onCancel={handleCreateModalClose}
        footer={false}
      >
        <Form form={createForm} layout="horizontal" onFinish={handleCreate}>
          <Form.Item
            label="Sourcing Product ID"
            name="product_id"
            rules={[
              {
                required: true,
                message: "Sourcing product ID required",
              },
            ]}
          >
            <Input placeholder="Sourcing Product ID" />
          </Form.Item>
          <Form.Item
            label="Product Name"
            name="sourcing_product_name"
            rules={[
              {
                required: true,
                message: "Please enter sourcing product name",
              },
            ]}
          >
            <Input placeholder="Product Name" />
          </Form.Item>
          <Form.Item
            label="Category"
            name="product_category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
          >
            <Select placeholder="Select Product Category">
              <Option value="Livestock">Livestock</Option>
              <Option value="Crop">Crop</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Sub Category"
            name="product_sub_category"
            rules={[
              {
                required: true,
                message: "Please select sub-category",
              },
            ]}
          >
            <Select placeholder="Select Product Sub-Category">
              <Option value="Perishable">Perishable</Option>
              <Option value="Non Perishable">Non Perishable</Option>
            </Select>
          </Form.Item>
          <Form.Item className="d-flex justify-content-end ">
            <Button
              htmlType="submit"
              disabled={loading}
              style={{ color: "#ffffff", backgroundColor: "#46604A" }}
            >
              Create
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>SOURCING PRODUCTS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="d-flex justify-content-end border-bottom ">
                <div className="mb-4">
                  <Button
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      backgroundColor: "#46604A",
                      borderColor: "#46604A",
                      color:"white"
                    }}
                    onClick={handleCreateModal}
                  >
                    <PlusOutlined />
                    Add Product
                  </Button>
                </div>
              </div>
              <Table
                columns={productColumn}
                dataSource={sourcingProductLists}
                loading={sourcingProductLoading}
                rowKey={(record) => record?.sourcing_product_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
