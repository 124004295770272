import { Button, Image, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import baseUrl from "../../config";
import axios from "axios";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

export default function WorkOrderDetailsModal({
  visible,
  data,
  handleWorkOrderDetailsModalClose,
}) {
  const [workOrderDetails, setWorkOrderDetails] = useState(null);
  const modalRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    pageStyle: `
    @media print {
      @page {
        size: A4 landscape;
        margin: 5;
      }
    
      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
      }
    
    
      th,
      td {
        padding: 5px;
        font-size:12px;
      }
    
    
      /* Ensure table rows are not split across pages */
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
    
      /* Adjust font size for better readability */
      p,
      strong {
        font-size: 12px;
      }
    }
    `,
    onAfterPrint: () => {
      handleWorkOrderDetailsModalClose();
    },
  });

  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/workOrderDetails/${data?.work_order_id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(response?.data);
        setWorkOrderDetails(response?.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }, [data?.work_order_id]);
  const calculateProfit = () => {
    const directSourcingPurchaseCost =
      workOrderDetails?.agrigate_sourcing_info?.reduce(
        (total, item) =>
          total +
          Number(
            parseFloat(item?.purchase_quantity_kg) *
              parseFloat(item?.purchase_rate_tk_per_kg)
          ),
        0
      );
    const warehouseSourcingPurchaseCost =
      workOrderDetails?.warehouse_sourcing_info?.reduce(
        (total, item) => total + Number(item?.stock_out_value),
        0
      );
    
    const ipReceivable = workOrderDetails?.ip_selling_info
      ?.reduce(
        (total, item) =>
          total + Number(item?.total_receivable),
        0
      )
      
      return parseFloat((ipReceivable - (directSourcingPurchaseCost+warehouseSourcingPurchaseCost))) || null
  };
  return (
    <Modal
      title="Work Order Details"
      open={visible}
      onCancel={handleWorkOrderDetailsModalClose}
      footer={false}
      width="95%"
    >
      <div ref={modalRef}>
        <div className="d-flex justify-content-between">
          <table
            className="my-4 table-bordered w-75"
            style={{
              border: "0.3px solid lightgray ",
              color: "#46604A",
              height: "200px",
            }}
          >
            <tbody>
              <tr>
                <td className="p-1 w-50">
                  <strong>Company Name:</strong>
                </td>
                <td className="p-1">{data?.company?.company_name}</td>
              </tr>
              <tr>
                <td className="p-1">
                  <strong>Work Order Number:</strong>
                </td>
                <td className="p-1">{data?.work_order_id}</td>
              </tr>
              <tr>
                <td className="p-1">
                  <strong>Purchase Order Number:</strong>
                </td>
                <td className="p-1">{data?.purchase_order}</td>
              </tr>
              <tr>
                <td className="p-1">
                  <strong>Product Name:</strong>
                </td>
                <td className="p-1">{data?.sourcing_product?.product_name}</td>
              </tr>
              <tr>
                <td className="p-1">
                  <strong>Purchase Order Quantity:</strong>
                </td>
                <td className="p-1">
                  {parseFloat(data?.product_quantity_kg)?.toLocaleString()} Kg
                </td>
              </tr>
              <tr>
                <td className="p-1">
                  <strong>Purchase Rate (Tk/Kg):</strong>
                </td>
                <td className="p-1">{data?.rate_tk_per_kg} BDT</td>
              </tr>
              {data?.wo_status === "Complete" && (
                <tr>
                  <td className="p-1">
                    <strong>Profit:</strong>
                  </td>
                  <td className="p-1">{calculateProfit()?.toLocaleString()} BDT</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="w-25 align-self-center ms-2 border">
            <Image
              src={data?.wo_image}
              alt="Work Order Image"
              width={300}
              height={200}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="pe-2 w-50" style={{ overflowX: "auto" }}>
            <p style={{ fontSize: 16 }} className="mb-1">
              <strong>Purchase Details</strong>
            </p>
            <div className="border">
              <p style={{ fontSize: 14 }} className="mb-1 ms-1">
                <strong>Direct Sourcing</strong>
              </p>
              <div className="table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th className="p-1">Purchase</th>
                      <th className="p-1">GDN</th>
                      <th className="p-1">Moisture</th>
                      <th className="p-1">Purchase Qty(Kg)</th>
                      <th className="p-1">Payment Amount(tk)</th>
                      <th className="p-1">Paid Amount (tk)</th>
                      <th className="p-1">Due(tk)</th>
                      <th className="p-1">Payment Status</th>
                      <th className="p-1">Transport Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrderDetails?.agrigate_sourcing_info?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td className="p-1">{item?.purchase_date}</td>
                          <td className="p-1">{item?.gdn_number}</td>
                          <td className="p-1">{item?.moisture}</td>
                          <td className="p-1">
                            {item?.purchase_quantity_kg?.toLocaleString()}
                          </td>
                          <td className="p-1">
                            {(
                              parseFloat(item?.purchase_quantity_kg) *
                              parseFloat(item?.purchase_rate_tk_per_kg)
                            )?.toLocaleString()}
                          </td>
                          <td className="p-1">
                            {item?.total_paid_amount?.toLocaleString()}
                          </td>
                          <td className="p-1">{item?.total_due}</td>
                          <td className="p-1">{item?.payment_status}</td>
                          <td className="p-1">{item?.transport_cost_tk}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td className="p-1" colSpan={3}>
                        <strong>Total</strong>
                      </td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.agrigate_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.purchase_quantity_kg),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>

                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.agrigate_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total +
                                Number(
                                  parseFloat(item?.purchase_quantity_kg) *
                                    parseFloat(item?.purchase_rate_tk_per_kg)
                                ),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.agrigate_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.total_paid_amount),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1">
                        {" "}
                        <strong>
                          {workOrderDetails?.agrigate_sourcing_info
                            ?.reduce(
                              (total, item) => total + Number(item?.total_due),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1"></td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.agrigate_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.transport_cost_tk),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p style={{ fontSize: 14 }} className="mb-1 ms-1 mt-3">
                <strong>Warehouse Sourcing</strong>
              </p>
              <div className="table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th className="p-1">Purchase</th>
                      <th className="p-1">Warehouse</th>
                      <th className="p-1">GDN</th>
                      <th className="p-1">Moisture</th>
                      <th className="p-1">Total Qty(Sack)</th>
                      <th className="p-1">Total Qty(Kg)</th>
                      <th className="p-1">Stock Out value(tk)</th>
                      <th className="p-1">Transport Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workOrderDetails?.warehouse_sourcing_info?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td className="p-1">
                            {item?.created_at
                              ? new Date(item?.created_at)
                                  .toISOString()
                                  .split("T")[0]
                              : null}
                          </td>
                          <td className="p-1">
                            {item?.warehouse?.warehouse_name}
                          </td>
                          <td className="p-1">{item?.gdn_number}</td>
                          <td className="p-1">{item?.moisture}</td>
                          <td className="p-1">{item?.total_sack}</td>
                          <td className="p-1">
                            {item?.total_quantity?.toLocaleString()}
                          </td>
                          <td className="p-1">
                            {item?.stock_out_value?.toLocaleString()}
                          </td>
                          <td className="p-1">
                            {item?.transport_cost_tk?.toLocaleString()}
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td className="p-1" colSpan={4}>
                        <strong>Total</strong>
                      </td>

                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.warehouse_sourcing_info
                            ?.reduce(
                              (total, item) => total + Number(item?.total_sack),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.warehouse_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.total_quantity),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.warehouse_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.stock_out_value),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                      <td className="p-1">
                        <strong>
                          {workOrderDetails?.warehouse_sourcing_info
                            ?.reduce(
                              (total, item) =>
                                total + Number(item?.transport_cost_tk),
                              0
                            )
                            ?.toLocaleString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="ps-2 w-50" style={{ overflowX: "auto" }}>
            <p style={{ fontSize: 16 }} className="mb-1">
              <strong>Sale Details</strong>
            </p>
            <div className="table-responsive">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th className="p-1">Product Receive</th>
                    <th className="p-1">GDN</th>
                    <th className="p-1">GRN</th>
                    <th className="p-1">Provided Qty</th>
                    <th className="p-1">Received Qty</th>
                    <th className="p-1">Total Receivable</th>
                    <th className="p-1">Total Received Amount</th>
                    <th className="p-1">Due Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {workOrderDetails?.ip_selling_info?.map((item, index) => (
                    <tr key={index}>
                      <td className="p-1">{item.product_receive_date}</td>
                      <td className="p-1">
                        {item?.direct_gdn_number?.gdn_number ||
                          item?.warehouse_gdn_number?.gdn_number}
                      </td>
                      <td className="p-1">{item.grn}</td>
                      <td className="p-1">
                        {parseFloat(item?.provided_quantity)?.toLocaleString()}
                      </td>
                      <td className="p-1">
                        {parseFloat(item?.received_quantity)?.toLocaleString()}
                      </td>
                      <td className="p-1">
                        {parseFloat(item?.total_receivable)?.toLocaleString()}
                      </td>
                      <td className="p-1">
                        {parseFloat(item?.total_received)?.toLocaleString()}
                      </td>
                      <td className="p-1">
                        {parseFloat(item?.due_amount)?.toLocaleString()}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td className="p-1" colSpan={3}>
                      <strong>Total</strong>
                    </td>

                    <td className="p-1">
                      <strong>
                        {workOrderDetails?.ip_selling_info
                          ?.reduce(
                            (total, item) =>
                              total + Number(item?.provided_quantity),
                            0
                          )
                          ?.toLocaleString()}
                      </strong>
                    </td>
                    <td className="p-1">
                      <strong>
                        {workOrderDetails?.ip_selling_info
                          ?.reduce(
                            (total, item) =>
                              total + Number(item?.received_quantity),
                            0
                          )
                          ?.toLocaleString()}
                      </strong>
                    </td>
                    <td className="p-1">
                      <strong>
                        {workOrderDetails?.ip_selling_info
                          ?.reduce(
                            (total, item) =>
                              total + Number(item?.total_receivable),
                            0
                          )
                          ?.toLocaleString()}
                      </strong>
                    </td>
                    <td className="p-1">
                      <strong>
                        {workOrderDetails?.ip_selling_info
                          ?.reduce(
                            (total, item) =>
                              total + Number(item?.total_received),
                            0
                          )
                          ?.toLocaleString()}
                      </strong>
                    </td>
                    <td className="p-1">
                      <strong>
                        {workOrderDetails?.ip_selling_info
                          ?.reduce(
                            (total, item) => total + Number(item?.due_amount),
                            0
                          )
                          ?.toLocaleString()}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="d-flex align-items-center fw-bold"
        style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        onClick={handlePrint}
      >
        <PrinterOutlined />
        Print
      </Button>
    </Modal>
  );
}
