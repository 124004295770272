import React, { useContext, useState } from "react";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import InventoryIcon from "@mui/icons-material/Inventory";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import StorageIcon from "@mui/icons-material/Storage";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import BadgeIcon from "@mui/icons-material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListIcon from "@mui/icons-material/List";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { AppRegistrationOutlined } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./SideBar.css";
import { loginContext } from "../../App";
import Groups3Icon from "@mui/icons-material/Groups3";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PollIcon from "@mui/icons-material/Poll";
import AddchartIcon from "@mui/icons-material/Addchart";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FactoryIcon from "@mui/icons-material/Factory";
import LaptopIcon from "@mui/icons-material/Laptop";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CoffeeMakerIcon from "@mui/icons-material/CoffeeMaker";
import StorefrontIcon from "@mui/icons-material/Storefront";
import GradingIcon from "@mui/icons-material/Grading";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GridViewIcon from "@mui/icons-material/GridView";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import { RetweetOutlined } from "@ant-design/icons";

export default function SideBar() {
  const { collapseSidebar } = useProSidebar();
  const [IS_SIDEBAR_OPEN, SET_IS_SIDEBAR_OPEN] = useState(true);
  const { userPermissions, setuserPermissions } = useContext(loginContext);

  const [activePage, setActivePage] = useState(null);

  function handleActive(event) {
    if (activePage) activePage.classList.remove("active");
    event.target.classList.add("active");
    setActivePage(event.target);
  }
  const webmailUrl = "http://webmail.agrigate.asia/";
  const oneThreadUrl = "https://onethread.agrigate.network/";

  return (
    <>
      <div style={{ display: "flex", height: "100%", zIndex:900 }}>
        <Sidebar>
          <h5
            className="text-center mt-4"
            style={{
              color: "#46604A",
            }}
          >
            {IS_SIDEBAR_OPEN ? "MIS Dashboard" : null}
            <MenuIcon
              onClick={() => {
                collapseSidebar();
                SET_IS_SIDEBAR_OPEN(!IS_SIDEBAR_OPEN);
              }}
              style={{ fontSize: "28", cursor: "pointer" }}
            />
          </h5>
          <Menu>
            {userPermissions?.includes("view_home_menu") && (
              <MenuItem
                className="NavigationLink"
                icon={<DashboardIcon style={{ color: "inherit" }} />}
                style={{ fontSize: "15px" }}
                onClick={handleActive}
                component={<Link className="NavigationLink" to="/home" />}
              >
                {IS_SIDEBAR_OPEN ? "Home" : null}
              </MenuItem>
            )}
            {userPermissions?.includes("view_webmail_menu") && (
              <MenuItem
                className="NavigationLink"
                style={{ fontSize: "15px" }}
                icon={<LocalPostOfficeIcon style={{ color: "inherit" }} />}
                component={
                  <a
                    className="NavigationLink"
                    href={webmailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleActive}
                  >
                    {IS_SIDEBAR_OPEN ? "WebMail" : null}
                  </a>
                }
              >
                {IS_SIDEBAR_OPEN ? "WebMail" : null}
              </MenuItem>
            )}
            {userPermissions?.includes("view_general_meeting") && (
              <SubMenu
                className="NavigationLink"
                style={{ fontSize: "15px" }}
                icon={<VideoCameraBackIcon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Meeting" : null}
              >
                {userPermissions?.includes("view_general_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<Groups3Icon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/generalMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    General
                  </MenuItem>
                )}
                {userPermissions?.includes("view_hr_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<HandshakeIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/hrMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    HR
                  </MenuItem>
                )}
                {userPermissions?.includes("view_operations_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<Diversity3Icon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/opsMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    Operations
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sourcing_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<LocalShippingIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    Sourcing
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sales_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<ShoppingCartIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/salesMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    Sales
                  </MenuItem>
                )}
                {userPermissions?.includes("view_tech_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<LaptopIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/techMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    Tech
                  </MenuItem>
                )}
                {userPermissions?.includes("view_accounts_meeting") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<AccountBalanceIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/accountsMeeting"
                        onClick={handleActive}
                        target="_blank"
                      />
                    }
                  >
                    Accounts
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_operations_menu") && (
              <SubMenu
                className="NavigationLink "
                style={{ fontSize: "15px" }}
                icon={<Groups3Icon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Operations" : null}
              >
                {userPermissions?.includes("view_operations_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<GridViewIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/operationsDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                )}
                {userPermissions?.includes("view_consultation_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={
                      <AnalyticsOutlinedIcon style={{ color: "inherit" }} />
                    }
                    label="Consultation"
                  >
                    {userPermissions?.includes("view_consultation_menu") && (
                      <MenuItem
                        className="NavigationLink"
                        style={{ fontSize: "15px" }}
                        icon={
                          <AnalyticsOutlinedIcon style={{ color: "inherit" }} />
                        }
                        component={
                          <Link
                            className="NavigationLink"
                            to="/consultationDashboard"
                            onClick={handleActive}
                          />
                        }
                      >
                        Admin
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_consultant_menu") && (
                      <MenuItem
                        className="NavigationLink"
                        style={{ fontSize: "15px" }}
                        icon={
                          <AppRegistrationOutlined
                            style={{ color: "inherit" }}
                          />
                        }
                        component={
                          <Link
                            className="NavigationLink"
                            to="/consultant"
                            onClick={handleActive}
                          />
                        }
                      >
                        Consultant
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
                <SubMenu
                  className="NavigationLink"
                  style={{ fontSize: "15px" }}
                  icon={<HistoryEduIcon style={{ color: "inherit" }} />}
                  label={"Contract Farming"}
                >
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      icon={<HistoryEduIcon style={{ color: "inherit" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarming"
                          onClick={handleActive}
                        />
                      }
                    >
                      Dashboard
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_reg") && (
                    <MenuItem
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      icon={
                        <AppRegistrationOutlined style={{ color: "inherit" }} />
                      }
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarmingReg"
                          onClick={handleActive}
                        />
                      }
                    >
                      Registration
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      icon={<CheckBoxIcon style={{ color: "inherit" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/productDemand"
                          onClick={handleActive}
                        />
                      }
                    >
                      Product Demand
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_contract_farming_menu") && (
                    <MenuItem
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      icon={<AccessTimeIcon style={{ color: "inherit" }} />}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/contractFarmingHistory"
                          onClick={handleActive}
                        />
                      }
                    >
                      History
                    </MenuItem>
                  )}
                </SubMenu>
                {userPermissions?.includes("view_survey_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<PollIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/survey"
                        onClick={handleActive}
                      />
                    }
                  >
                    Survey
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_inventory_menu") && (
              <SubMenu
                className="NavigationLink "
                style={{ fontSize: "15px" }}
                icon={<InventoryIcon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Inventory" : null}
              >
                <SubMenu
                  className="NavigationLink"
                  style={{ fontSize: "15px" }}
                  icon={<PointOfSaleIcon style={{ color: "inherit" }} />}
                  label="Point of Sale"
                >
                  {userPermissions?.includes("view_gui_menu") && (
                    <MenuItem
                      icon={<ReceiptLongIcon style={{ color: "inherit" }} />}
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/gui"
                          onClick={handleActive}
                        />
                      }
                    >
                      Quick Invoice
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_manage_sales_menu") && (
                    <MenuItem
                      icon={<ReceiptIcon style={{ color: "inherit" }} />}
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/manageSales"
                          onClick={handleActive}
                        />
                      }
                    >
                      Manage Sales
                    </MenuItem>
                  )}
                  {userPermissions?.includes("view_pos_menu") && (
                    <MenuItem
                      icon={
                        <BackupTableOutlinedIcon style={{ color: "inherit" }} />
                      }
                      className="NavigationLink"
                      style={{ fontSize: "15px" }}
                      component={
                        <Link
                          className="NavigationLink"
                          to="/customerLedger"
                          onClick={handleActive}
                        />
                      }
                    >
                      Customer Dues
                    </MenuItem>
                  )}
                </SubMenu>
                {userPermissions?.includes("view_purchase_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<LocalMallIcon style={{ color: "inherit" }} />}
                    label="Purchase"
                  >
                    {userPermissions?.includes(
                      "view_purchase_product_menu"
                    ) && (
                      <MenuItem
                        icon={
                          <ShoppingCartCheckoutIcon
                            style={{ color: "inherit" }}
                          />
                        }
                        className="NavigationLink"
                        style={{ fontSize: "15px" }}
                        component={
                          <Link
                            className="NavigationLink"
                            to="/purchaseProduct"
                            onClick={handleActive}
                          />
                        }
                      >
                        Product
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_purchase_menu") && (
                      <MenuItem
                        icon={<ReceiptIcon style={{ color: "inherit" }} />}
                        className="NavigationLink"
                        style={{ fontSize: "15px" }}
                        component={
                          <Link
                            className="NavigationLink"
                            to="/managePurchase"
                            onClick={handleActive}
                          />
                        }
                      >
                        Manage
                      </MenuItem>
                    )}
                  </SubMenu>
                )}
                {userPermissions?.includes("view_stock_menu") && (
                  <MenuItem
                    icon={<ShowChartIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/stock"
                        onClick={handleActive}
                      />
                    }
                  >
                    Stock
                  </MenuItem>
                )}
                {userPermissions?.includes("view_transfer_menu") && (
                  <MenuItem
                    icon={
                      <RetweetOutlined
                        style={{ color: "inherit", fontSize: "25px" }}
                      />
                    }
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/transferStock"
                        onClick={handleActive}
                      />
                    }
                  >
                    Transfer
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_sales_menu") && (
              <SubMenu
                className="NavigationLink "
                icon={<ShoppingCartIcon style={{ color: "inherit" }} />}
                style={{ fontSize: "15px" }}
                label={IS_SIDEBAR_OPEN ? "Sales" : null}
              >
                {userPermissions?.includes("view_sales_dashboard") && (
                  <MenuItem
                    icon={<GridViewIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/salesDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_menu") && (
                  <MenuItem
                    icon={<ListAltIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productList"
                        onClick={handleActive}
                      />
                    }
                  >
                    Product
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sales_dashboard") && (
                  <MenuItem
                    icon={<ShoppingBasketIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/cashSales"
                        onClick={handleActive}
                      />
                    }
                  >
                    Sales Details
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_price") && (
                  <MenuItem
                    icon={<ListIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productPrice"
                        onClick={handleActive}
                      />
                    }
                  >
                    Order Price List
                  </MenuItem>
                )}
                {/* {userPermissions?.includes("view_credit_status_menu") && (
                  <MenuItem
                    icon={<CreditCardIcon style={{ color: "#8f92b1" }} />}
                    className="NavigationLink"
                    component={
                      <Link
                        className="NavigationLink"
                        to="/creditStatus"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Credit Status" : null}
                  </MenuItem>
                )} */}
                {userPermissions?.includes("view_order_taking_menu") && (
                  <MenuItem
                    icon={<AddchartIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/orderTaking"
                        onClick={handleActive}
                      />
                    }
                  >
                    Order Taking
                  </MenuItem>
                )}
                {userPermissions?.includes("view_order_taking_menu") && (
                  <MenuItem
                    icon={<GradingIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/orderFulfillment"
                        onClick={handleActive}
                      />
                    }
                  >
                    Order Fulfillment
                  </MenuItem>
                )}
                {userPermissions?.includes("view_route_map_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<ForkRightIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/routeMap"
                        onClick={handleActive}
                      />
                    }
                  >
                    Route Map
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_sourcing_menu") && (
              <SubMenu
                className="NavigationLink "
                style={{ fontSize: "15px" }}
                icon={<LocalShippingIcon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Sourcing" : null}
              >
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<GridViewIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                )}
                {userPermissions?.includes("view_product_sourcing_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<LocalShippingIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/productSourcing"
                        onClick={handleActive}
                      />
                    }
                  >
                    Product Sourcing
                  </MenuItem>
                )}
                {userPermissions?.includes("view_price_app_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<PriceCheckIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/priceApp"
                        onClick={handleActive}
                      />
                    }
                  >
                    Price Analytics
                  </MenuItem>
                )}
                {userPermissions?.includes("view_sourcing_product_list") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<ListIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/sourcingList"
                        onClick={handleActive}
                      />
                    }
                  >
                    Sourcing Products
                  </MenuItem>
                )}

                {userPermissions?.includes("view_sourcing_menu") && (
                  <SubMenu
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<WarehouseIcon style={{ color: "inherit" }} />}
                    label="Warehouse"
                  >
                    {userPermissions?.includes("view_sourcing_menu") && (
                      <MenuItem
                        icon={<InputIcon style={{ color: "inherit" }} />}
                        style={{ fontSize: "15px" }}
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/warehouseStockIn"
                            onClick={handleActive}
                          />
                        }
                      >
                        Stock In
                      </MenuItem>
                    )}
                    {userPermissions?.includes("view_sourcing_menu") && (
                      <MenuItem
                        icon={<OutputIcon style={{ color: "inherit" }} />}
                        style={{ fontSize: "15px" }}
                        className="NavigationLink"
                        component={
                          <Link
                            className="NavigationLink"
                            to="/warehouseStockOut"
                            onClick={handleActive}
                          />
                        }
                      >
                        Stock Out
                      </MenuItem>
                    )}
                  </SubMenu>
                )}

                {userPermissions?.includes("view_industrial_partner_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<FactoryIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/ipInfo"
                        onClick={handleActive}
                      />
                    }
                  >
                    Industrial Partner Info
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {userPermissions?.includes("view_employee_menu") && (
              <SubMenu
                className="NavigationLink "
                style={{ fontSize: "15px" }}
                icon={<EngineeringIcon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Employee" : null}
              >
                {userPermissions?.includes("view_create_user") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<PersonIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/user"
                        onClick={handleActive}
                      />
                    }
                  >
                    User
                  </MenuItem>
                )}
                {userPermissions?.includes("view_create_employee_profile") && (
                  <MenuItem
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    icon={<BadgeIcon style={{ color: "inherit" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/employee"
                        onClick={handleActive}
                      />
                    }
                  >
                    Employee List
                  </MenuItem>
                )}

                {/* {userPermissions?.includes("view_harmony_access") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<PinDropIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/harmony"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Harmony" : null}
                  </MenuItem>
                )} */}
              </SubMenu>
            )}

            {userPermissions?.includes("view_database_menu") && (
              <SubMenu
                className="NavigationLink "
                style={{ fontSize: "15px" }}
                icon={<StorageIcon style={{ color: "inherit" }} />}
                label={IS_SIDEBAR_OPEN ? "Databases" : null}
              >
                {userPermissions?.includes("view_database_menu") && (
                  <MenuItem
                    icon={<GridViewIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databaseDashboard"
                        onClick={handleActive}
                      />
                    }
                  >
                    Dashboard
                  </MenuItem>
                )}
                {userPermissions?.includes("view_farmer_database") && (
                  <MenuItem
                    icon={<AgricultureIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/farmer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Farmer
                  </MenuItem>
                )}
                {userPermissions?.includes("view_retailer_database") && (
                  <MenuItem
                    icon={
                      <StoreMallDirectoryIcon style={{ color: "inherit" }} />
                    }
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/retailer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Retailer
                  </MenuItem>
                )}
                {userPermissions?.includes("view_asp_database") && (
                  <MenuItem
                    icon={
                      <PrecisionManufacturingIcon
                        style={{ color: "inherit" }}
                      />
                    }
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/asp"
                        onClick={handleActive}
                      />
                    }
                  >
                    ASP
                  </MenuItem>
                )}
                {userPermissions?.includes("view_lsp_database") && (
                  <MenuItem
                    icon={
                      <VolunteerActivismIcon style={{ color: "inherit" }} />
                    }
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/lsp"
                        onClick={handleActive}
                      />
                    }
                  >
                    LSP
                  </MenuItem>
                )}
                {userPermissions?.includes("view_trader_database") && (
                  <MenuItem
                    icon={<StorefrontIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/trader"
                        onClick={handleActive}
                      />
                    }
                  >
                    Trader
                  </MenuItem>
                )}
                {userPermissions?.includes("view_agro_processor_database") && (
                  <MenuItem
                    icon={<CoffeeMakerIcon style={{ color: "inherit" }} />}
                    className="NavigationLink"
                    style={{ fontSize: "15px" }}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/databases/institutionalBuyer"
                        onClick={handleActive}
                      />
                    }
                  >
                    Institutional Buyer
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {/* {userPermissions?.includes("view_map_menu") && (
              <SubMenu
                className="NavigationLink "
                icon={<MapIcon style={{ color: "#8f92b1" }} />}
                label={IS_SIDEBAR_OPEN ? "Map" : null}
              >
                {userPermissions?.includes("view_location_map_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<LocationOnIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/locationMap"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Location Map" : null}
                  </MenuItem>
                )}

                {userPermissions?.includes("view_map_tacking_menu") && (
                  <MenuItem
                    className="NavigationLink"
                    icon={<RouteIcon style={{ color: "#8f92b1" }} />}
                    component={
                      <Link
                        className="NavigationLink"
                        to="/tracking"
                        onClick={handleActive}
                      />
                    }
                  >
                    {IS_SIDEBAR_OPEN ? "Map Tracking" : null}
                  </MenuItem>
                )}
              </SubMenu>
            )} */}

            {userPermissions?.includes("view_daily_report") && (
              <MenuItem
                className=" NavigationLink"
                style={{ fontSize: "15px" }}
                icon={<AssignmentTurnedInIcon style={{ color: "inherit" }} />}
                component={
                  <a
                    className="NavigationLink"
                    href={oneThreadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleActive}
                  >
                    {IS_SIDEBAR_OPEN ? "Agenda" : null}
                  </a>
                }
              >
               Agenda
              </MenuItem>
            )}
            {/* {userPermissions?.includes("view_daily_report") && (
              <MenuItem
                className=" NavigationLink"
                icon={<SummarizeIcon style={{ color: "#8f92b1" }} />}
                component={
                  <Link
                    className="NavigationLink"
                    to="/dailyReport"
                    onClick={handleActive}
                  />
                }
              >
                {IS_SIDEBAR_OPEN ? "Daily Report" : null}
              </MenuItem>
            )} */}
          </Menu>
         
        </Sidebar>
      </div>
    </>
  );
}
