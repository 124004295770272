import React, { useEffect, useState } from "react";
import "./CreateProfilePage.css";
import { Form, Input, Button, Col, Select, Row } from "antd";
import authHeader from "../Authentication/authHeader";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import baseUrl from "../../config";
import { Checkbox } from "antd";

export default function CreateUser() {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const [userList, setUserList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${baseUrl}/employee/users/`, { headers: authHeader() })
      .then((results) => {
        setUserList(results?.data);
      });
  }, []);

  const handleSubmit = (data) => {
    const oneThreadSignupData = {
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      roleName: data.roleName,
      password: data.password,
    };
    axios
      .post(`${baseUrl}/employee/createUser/`, data, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        if (response.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            axios
              .post(
                `https://onethread-backend.agrigate.network/Api/v1/accounts/signup/`,
                oneThreadSignupData
              )
              .then((response) => {
                console.log(response);
              });
            history.push("/user");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="createProfilePage">
      <div className="createProfileHeaderSection">
        <h5 style={{ color: "#46604A" }}>CREATE USER</h5>
      </div>

      <div className="createProfileContainer">
        <div className="createProfilePageSection ">
          <header className="text-center mt-4">
            {" "}
            <strong>User Create Form </strong>
          </header>
          <Form
            className="mt-5"
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              {/* username*/}
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input placeholder="User Name" />
                </Form.Item>
              </Col>
              {/* Email */}
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              {/*Role */}
              <Col xs={24} sm={12} md={8} lg={12}>
                <Form.Item
                  hasFeedback
                  label="Role"
                  name="roleName"
                  rules={[{ required: true, message: "Please select role" }]}
                >
                  <Select placeholder="Role">
                    <Option value="managingDirector"> Managing Director</Option>
                    <Option value="headOfDepartment">Head of Department</Option>
                    <Option value="executive">Executive</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              {/* Phone Number */}
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Only english characters",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Only english characters",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  hasFeedback
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password",
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]+$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@#$%^&*!)",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6} lg={2}>
                <Form.Item
                  label="Is active?"
                  name="is_active"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>active</Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6} lg={2}>
                <Form.Item
                  label="Is staff?"
                  name="is_staff"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Checkbox>staff</Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6} lg={3}>
                <Form.Item
                  label="Is super user?"
                  name="is_superuser"
                  initialValue={false}
                  valuePropName="checked"
                >
                  <Checkbox>super user</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {/* Submit Button */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item>
                <Button style={{backgroundColor:"#46604A", color:"white"}} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </div>
      </div>
    </div>
  );
}
