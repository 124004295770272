import React, { useEffect, useState } from "react";
import StockTableComponent from "./StockTableComponent";
import "./DailyUpdate.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import SalesTableComponent from "./SalesTableComponent";
import axios from "axios";

export default function DailyUpdate() {
  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(startDate.getDate()-1);
  endDate.setDate(endDate.getDate()-1);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);;
  const [stockDateValue, setStockDateValue] = useState([startDate,endDate]);
  const [salesDateValue, setSalesDateValue] = useState([startDate,endDate]);
  const [stockData, setStockData] = useState([]);
  const [salesData, setSalesData] = useState([]);

  console.log(stockDateValue)

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbyB6tyQPa837LihrU_9uLVaEwJe7WvtEygsbDQ_jYIGjpQFcU8cFFv5iGh2fKi98cCevQ/exec?date=${JSON.stringify(
          stockDateValue
        )}`
      )
      .then((data) => {
        const separateArray = {};
        for (let i = 0; i < data?.data.length; i++) {
          const key0Value = data?.data[i].key0;
          if (!separateArray[key0Value]) {
            separateArray[key0Value] = {
              key: key0Value,
              values: [],
            };
          }
          separateArray[key0Value].values.push(data?.data[i]);
        }

        // Filter out objects with key7 null or empty string
        const sortedArray = Object.values(separateArray).map((obj) => ({
          key: obj.key,
          values: obj.values
            .filter((val) => val.key7 !== null && val.key7 !== "")
            .sort((a, b) => b.key5 - a.key5),
        }));

        setStockData(sortedArray);
      });
  }, [stockDateValue]);

  useEffect(() => {
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbyIf9JVTgpXkV_tlRkLYkjJRDZQjHCIVWrxX7H6RRoJ5CDMGLoAKaUaaRM1CNOa-fjW/exec?date=${JSON.stringify(
          salesDateValue
        )}`
      )
      .then((data) => {
        const sums = {};
        for (let i = 0; i < data?.data.length; i++) {
          const key = `${data?.data[i].key0}_${data?.data[i].key3}_${data?.data[i].key9}`;
          if (!sums[key]) {
            sums[key] = {
              key0: data?.data[i].key0,
              key3: data?.data[i].key3,
              key9: data?.data[i].key9,
              key6Sum: 0,
              key7Sum: 0,
              key8Sum: 0,
            };
          }
          sums[key].key6Sum += data?.data[i].key6;
          sums[key].key7Sum += data?.data[i].key7;
          sums[key].key8Sum += data?.data[i].key8;
        }

        const result = Object.values(sums);
        const separateArray = {};
        for (let i = 0; i < result.length; i++) {
          const key3Value = result[i].key3;
          if (!separateArray[key3Value]) {
            separateArray[key3Value] = {
              key: key3Value,
              values: [],
            };
          }
          separateArray[key3Value].values.push(result[i]);
        }

        const sortedArray = Object.values(separateArray).map((obj) => ({
          key: obj.key,
          values1: obj.values
            .filter((val) => val.key7 !== null && val.key7 !== "")
            .sort((a, b) => b.key5 - a.key5),
        }));

        setSalesData(sortedArray);
      });
  }, [salesDateValue]);

  const mergedArray = stockData
    ?.map((obj1) => {
      const obj2 = salesData?.find((obj2) => obj2.key === obj1.key);
      if (obj2) {
        return { ...obj1, ...obj2 };
      } else {
        return obj1;
      }
    })
    .concat(
      salesData?.filter(
        (obj2) => !stockData?.some((obj1) => obj1.key === obj2.key)
      )
    );

  return (
    <div className="shadow rounded m-1">
      <div className="ms-3 pt-3">
        <span className="me-5" style={{ color: "#46604A", fontWeight: "bold" }}>
          Select Sales Date Range:
          <DateRangePicker
            onChange={setSalesDateValue}
            value={salesDateValue}
          />
        </span>
        <span className="me-5" style={{ color: "#46604A", fontWeight: "bold" }}>
          Select Stock Date Range:
          <DateRangePicker
            onChange={setStockDateValue}
            value={stockDateValue}
          />
        </span>
      </div>
      {mergedArray?.map((item, index) => (
        <>
          <h6
            key={index}
            className="text-center rounded shadow-sm m-1 p-1"
            style={{ backgroundColor: "#5e8a3d", color: "#ffffff" }}
          >
            {item.key}
          </h6>
          <div className="DailyUpdate">
            <div className="DailyUpdateLeftTable">
              <SalesTableComponent data={item.values1} />
            </div>
            <div className="DailyUpdateRightTable">
              <StockTableComponent data={item.values} />
            </div>
          </div>
        </>
      ))}
    </div>
  );
}
