import React, { useEffect, useRef, useState } from "react";
import "./PriceAppDashboard.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Button, Select, Table } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";

export default function PriceAppDashboard() {
  exporting(Highcharts);
  const { Option } = Select;
  const [priceAppDetails, setPriceAppDetails] = useState([]);
  const [todaysData, setTodaysData] = useState([]);
  const [loading, setLoading] = useState(false);

  let startDate = new Date();
  let endDate = new Date();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  const [priceShortTableDate, setPriceShortTableDate] = useState([
    startDate,
    endDate,
  ]);
  const [priceAppDataWithEmployee, setPriceAppDataWithEmployee] = useState([]);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [viewFullData, setViewFullData] = useState(false);
  const [lineChartDateRange, setLineChartDateRange] = useState(null);
  const [lineChartSelectedDistrict, setLineChartSelectedDistrict] =
    useState("All Districts");
  const [lineChartSelectedProduct, setLineChartSelectedProduct] =
    useState("All Products");
  const [lineChartSelectedProductType, setLineChartSelectedProductType] =
    useState("All Types");
  const [totalTableDateRange, setTotalTableDateRange] = useState(null);
  const [totalDataSelectedDistrict, setTotalDataSelectedDistrict] =
    useState("All Districts");
  const [totalDataSelectedProduct, setTotalDataSelectedProduct] =
    useState("All Products");
  const [totalDataSelectedProductType, setTotalDataSelectedProductType] =
    useState("All Types");
  const [totalDataSelectedFO, setTotalDataSelectedFO] = useState("All FOs");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/api/price_app/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setLoading(false);
        setPriceAppDetails(response?.data);
        dailyProductPrice(
          response?.data?.filter((item) => item?.created_at !== null),
          priceShortTableDate
        );
      });
  }, [priceShortTableDate]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/employee/price_app_history/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setLoading(false);
        setPriceAppDataWithEmployee(response?.data);
      });
  }, [priceShortTableDate]);

  const handlePriceShortTableDate = (newValue) => {
    setPriceShortTableDate(newValue);
  };

  const handleLineChartDateChange = (newValue) => {
    setLineChartDateRange(newValue);
    console.log(newValue);
  };

  const handleTotalDataDateChange = (newValue) => {
    setTotalTableDateRange(newValue);
  };

  const dailyProductPrice = (data, dateRange) => {
    let startDate = dateRange[0];
    let endDate = dateRange[1];

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });

    const distinctProductData = {};

    filteredData.forEach((item) => {
      const productName = item.product_name;

      if (!distinctProductData[productName]) {
        distinctProductData[productName] = {
          product_name: productName,
          total_spot_price: item.spot_price,
          count: 1,
          date: item.date,
          id: item.id,
          district: item.district.district_name,
        };
      } else {
        distinctProductData[productName].total_spot_price += item.spot_price;
        distinctProductData[productName].count++;
      }
    });

    // Calculate the average spot_price for each product
    const averageProductData = Object.values(distinctProductData).map(
      (productData) => ({
        ...productData,
        avg_spot_price: (
          productData.total_spot_price / productData.count
        ).toFixed(2),
      })
    );

    setTodaysData(averageProductData);
  };

  const distinctDistricts = [
    ...new Set(priceAppDetails?.map((item) => item?.district?.district_name)),
  ];

  const distinctProducts = [
    ...new Set(priceAppDetails?.map((item) => item?.product_name)),
  ];
  // const distinctFO = [
  //   ...new Set(
  //     priceAppDataWithEmployee?.map((item) => item?.employee?.user_info?.first_name + " " + item?.employee?.user_info?.last_name)
  //   ),
  // ];

  const handleLineChartLocationSelect = (value) => {
    setLineChartSelectedDistrict(value);
  };
  const handleLineChartProductSelect = (value) => {
    setLineChartSelectedProduct(value);
  };
  const handleLineChartProductTypeSelect = (value) => {
    setLineChartSelectedProductType(value);
  };

  const handleTotalDataLocationSelect = (value) => {
    setTotalDataSelectedDistrict(value);
  };
  const handleTotalDataProductSelect = (value) => {
    setTotalDataSelectedProduct(value);
  };
  const handleTotalDataProductTypeSelect = (value) => {
    setTotalDataSelectedProductType(value);
  };
  const handleTotalDataFOSelect = (value) => {
    setTotalDataSelectedFO(value);
  };

  const globalLineChartFilteredData = priceAppDetails?.filter((item) => {
    const itemDate = new Date(item.date);
    let lineStartDate = null;
    let lineEndDate = null;

    if (lineChartDateRange !== null) {
      lineStartDate = new Date(lineChartDateRange[0]);
      lineEndDate = new Date(lineChartDateRange[1]);
      lineStartDate.setHours(0, 0, 0, 0);
      lineEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      lineChartSelectedDistrict === "All Districts" ||
      lineChartSelectedDistrict === item?.district?.district_name;
    const isProductMatched =
      lineChartSelectedProduct === "All Products" ||
      lineChartSelectedProduct === item.product_name;
    const isProductTypeMatched =
      lineChartSelectedProductType === "All Types" ||
      lineChartSelectedProductType === item.product_category;
    const isDateInRange =
      lineStartDate === null ||
      (itemDate >= lineStartDate && itemDate <= lineEndDate);
    return (
      isDistrictMatched &&
      isProductMatched &&
      isProductTypeMatched &&
      isDateInRange
    );
  });

  const totalDataFilteredData = priceAppDetails?.filter((item) => {
    const itemDate = new Date(item?.date);
    let totalStartDate = null;
    let totalEndDate = null;

    if (totalTableDateRange !== null) {
      totalStartDate = new Date(totalTableDateRange[0]);
      totalEndDate = new Date(totalTableDateRange[1]);
      totalStartDate.setHours(0, 0, 0, 0);
      totalEndDate.setHours(23, 59, 59, 999);
    }
    const isDistrictMatched =
      totalDataSelectedDistrict === "All Districts" ||
      totalDataSelectedDistrict === item?.district?.district_name;
    const isProductMatched =
      totalDataSelectedProduct === "All Products" ||
      totalDataSelectedProduct === item?.product_name;
    const isProductTypeMatched =
      totalDataSelectedProductType === "All Types" ||
      totalDataSelectedProductType === item?.product_category;
    // const isFOMatched =
    //   totalDataSelectedFO === "All FOs" ||
    //   totalDataSelectedFO === (item?.employee?.user_info?.first_name + " " + item?.employee?.user_info?.last_name);
    const isDateInRange =
      totalStartDate === null ||
      (itemDate >= totalStartDate && itemDate <= totalEndDate);
    return (
      isDistrictMatched &&
      isProductMatched &&
      isProductTypeMatched &&
      isDateInRange
      // &&
      // isFOMatched
    );
  });

  console.log(totalDataFilteredData);

  const dailyPriceDataColumns = [
    {
      title: "Date",
      dataIndex: "date",
      width: "20%",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      width: "35%",
    },
    {
      title: "District",
      dataIndex: "district",
      width: "20%",
    },
    {
      title: "Price (avg)",
      dataIndex: "avg_spot_price",
      width: "20%",
    },
  ];
  const pagination = {
    pageSize: 5, // Set the number of rows per page
  };

  const totalTableDataColumns = [
    {
      title: "Date",
      render: (record) => record?.date,
    },
    {
      title: "Product Name",
      render: (record) => record?.product_name,
    },
    {
      title: "Moisture",
      render: (record) => record?.moisture,
    },
    {
      title: "District",
      render: (record) => record?.district?.district_name,
    },
    {
      title: "Upazila",
      render: (record) => record?.upazila?.upazila_name,
    },
    {
      title: "Union",
      render: (record) => record?.upazila?.upazila_name,
    },
    {
      title: "Today's Price",
      render: (record) => record?.spot_price,
    },
    {
      title: "Info Source Name",
      render: (record) => record?.info_source_name,
    },
    {
      title: "Info Source Type",
      render: (record) => record?.info_source_type,
    },
    {
      title: "Info Source Bazar",
      render: (record) => record?.info_source_bazar,
    },
    {
      title: "Info Source Phone",
      render: (record) =>
        record?.info_source_number ? "0" + record?.info_source_number : null,
    },
    {
      title: "Field Officer",
      render: (record) =>
        record?.employee?.user_info?.first_name +
        " " +
        record?.employee?.user_info?.last_name,
    },
  ];

  useEffect(() => {
    const processedData = {};

    if (globalLineChartFilteredData) {
      globalLineChartFilteredData.forEach((item) => {
        const { product_name, date, spot_price } = item;
        const formattedDate = new Date(date).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        if (!processedData[product_name]) {
          processedData[product_name] = { name: product_name, data: [] };
        }

        const dateIndex = processedData[product_name].data.findIndex(
          (entry) => entry[0] === formattedDate
        );

        if (dateIndex === -1) {
          processedData[product_name].data.push([formattedDate, spot_price]);
        } else {
          processedData[product_name].data[dateIndex][1] =
            (processedData[product_name].data[dateIndex][1] + spot_price) / 2;
        }
      });

      // Sort the data by date using a custom sorting function
      Object.values(processedData).forEach((item) => {
        item.data.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      });

      setChartData(Object.values(processedData));
    }
  }, [
    lineChartSelectedDistrict,
    lineChartSelectedProduct,
    lineChartSelectedProductType,
    priceAppDetails,
    lineChartDateRange,
  ]);

  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "Product Price Timeline",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Price",
      },
    },
    series: chartData,
    exporting: {
      enabled: true, // Enable exporting
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF"],
          symbol: "download", // Icon for the context menu button
          text: "Export", // Text for the context menu button
        },
      },
    },
  };

  return (
    <div className="farmerDashboard">
      <div className="dashboardNameSection">
        <h5 style={{ color: "#46604A" }}>PRICE APP DASHBOARD</h5>
      </div>
      <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="priceAppFilterBoxSection">
          <div className="ms-3 mt-3 me">
            <p className="m-0">Date: </p>
            <DateRangePicker
              onChange={handleLineChartDateChange}
              showOneCalendar
            />
          </div>
          <div className="mx-2 mt-3">
            <p className="m-0">Location: </p>
            <Select
              value={lineChartSelectedDistrict}
              placeholder="Select District"
              onChange={handleLineChartLocationSelect}
              showSearch
            >
              <Option value="All Districts">All Districts</Option>
              {distinctDistricts?.map((district, index) => (
                <Option key={index} value={district}>
                  {district}
                </Option>
              ))}
            </Select>
          </div>

          <div className="mx-2 mt-3">
            <p className="m-0">Type: </p>
            <Select
              value={lineChartSelectedProductType}
              placeholder="Select Type"
              onChange={handleLineChartProductTypeSelect}
              showSearch
            >
              <Option value="All Types">All Types</Option>
              <Option value="Crop">Crop</Option>
              <Option value="Livestock">Livestock</Option>
            </Select>
          </div>
          <div className="mx-2 mt-3">
            <p className="m-0">Products: </p>
            <Select
              value={lineChartSelectedProduct}
              placeholder="Select Product Name"
              onChange={handleLineChartProductSelect}
              showSearch
            >
              <Option value="All Products">All Products</Option>
              {distinctProducts?.map((product, index) => (
                <Option key={index} value={product}>
                  {product}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <section className="chartTableSection">
          <div className="lineChartSection pt-5 shadow-sm">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          </div>
          <div className="todaysProductPriceSection shadow-sm mx-2">
            <h5 className="ms-2 mt-4">Product Price Update</h5>
            <div className="d-flex">
              <div className="ms-3 mt-3">
                <p className="m-0">Date: </p>
                <DateRangePicker
                  onChange={handlePriceShortTableDate}
                  value={priceShortTableDate}
                  showOneCalendar
                />
              </div>
            </div>
            <Table
              columns={dailyPriceDataColumns}
              dataSource={todaysData}
              loading={loading}
              rowKey={(record) => record.id}
              pagination={pagination}
            />
            <Button
              className="seeMoreButtonDesign"
              onClick={() => setViewFullData(!viewFullData)}
            >
              {viewFullData ? <>See less</> : <>See more</>}
            </Button>
          </div>
        </section>
        {viewFullData ? (
          <section className="priceAppTotalTable mx-2 shadow">
            <h5 className="text-center pt-4 pb-2 border-bottom">
              Product Price Total Table
            </h5>
            <div className="d-flex">
              <div className="ms-3 mt-3">
                <p className="m-0">Date: </p>
                <DateRangePicker
                  onChange={handleTotalDataDateChange}
                  showOneCalendar
                />
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Location: </p>
                <Select
                  placeholder="Select District"
                  value={totalDataSelectedDistrict}
                  onChange={handleTotalDataLocationSelect}
                  showSearch
                >
                  <Option value="All Districts">All Districts</Option>
                  {distinctDistricts?.map((district, index) => (
                    <Option key={index} value={district}>
                      {district}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Type: </p>
                <Select
                  showSearch
                  placeholder="Select Type"
                  onChange={handleTotalDataProductTypeSelect}
                  value={totalDataSelectedProductType}
                >
                  <Option value="All Types">All Types</Option>
                  <Option value="Crop">Crop</Option>
                  <Option value="Livestock">Livestock</Option>
                </Select>
              </div>
              <div className="ms-3 mt-3">
                <p className="m-0">Product:</p>
                <Select
                  placeholder="Select Product"
                  onChange={handleTotalDataProductSelect}
                  value={totalDataSelectedProduct}
                  showSearch
                >
                  <Option value="All Products">All Products</Option>
                  {distinctProducts?.map((product, index) => (
                    <Option key={index} value={product}>
                      {product}
                    </Option>
                  ))}
                </Select>
              </div>
              {/* <div className="ms-3 mt-3">
                <p className="m-0">Field Officer:</p>
                <Select
                  placeholder="Select FO"
                  onChange={handleTotalDataFOSelect}
                  value={totalDataSelectedFO}
                  showSearch
                >
                  <Option value="All FOs">All FOs</Option>
                  {distinctFO?.map((fo, index) => (
                    <Option key={index} value={fo}>
                      {fo}
                    </Option>
                  ))}
                </Select>
              </div> */}
            </div>
            <Table
              className="mx-2"
              columns={totalTableDataColumns}
              dataSource={totalDataFilteredData}
              rowKey={(record) => record.id}
            />
          </section>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
