import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Spin,
  Modal,
  DatePicker,
  Upload,
  Row,
  Col,
  Table,
} from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import awsS3 from "aws-sdk/clients/s3";
import { UploadOutlined } from "@ant-design/icons";

export default function TransactionSalesModal({
  visible,
  handleTransactionSalesModalClose,
  workOrderInfo,
  fetchIPTransaction,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [productReceiveDate, setProductReceiveDate] = useState("");
  const [billReceiveDate, setBillReceiveDate] = useState("");
  const [grnDate, setGrnDate] = useState("");
  const [WORelatedInfo, setWORelatedInfo] = useState([]);
  const [grnImage, setGrnImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [paymentInfoList, setPaymentInfoList] = useState([]);
  const [sourcingType, setSourcingType] = useState([]);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState("");

  const handleModalClose = () => {
    handleTransactionSalesModalClose();
    form.resetFields();
  };

  const handleProductReceiveDateChange = (date, dateString) => {
    setProductReceiveDate(dateString);
  };

  const handleBillReceiveDateChange = (date, dateString) => {
    setBillReceiveDate(dateString);
  };

  const handleGrnDateChange = (date, dateString) => {
    setGrnDate(dateString);
  };

  const handleSelectWO = (id) => {
    const selectedWO = workOrderInfo?.find((item) => item.work_order_id === id);
    form.setFieldsValue({
      po_no: selectedWO?.purchase_order,
      sourcing_type: null,
    });
    setSelectedWorkOrderId(id);
  };
  const handleSourcingType = (value) => {
    setSourcingType(value);
    form.setFieldsValue({
      gdn_number: null,
    });
    axios
      .get(`${baseUrl}/sourcing/poSourcingBuyingInfo/${selectedWorkOrderId}`, {
        headers: authHeader(),
        params: {
          sourcingType: value,
        },
      })
      .then((data) => {
        console.log(data);
        if (data?.data?.length > 0) {
          setWORelatedInfo(data?.data);
          form.setFieldsValue({
            gdn_number: undefined,
            grn: undefined,
            product_receive_date: undefined,
            product_name: undefined,
            provided_quantity: undefined,
            received_quantity: undefined,
            product_sale_rate_per_kg: undefined,
            other_cost: 0,
            total_receivable: undefined,
            bill_receive_date: undefined,
            received_amount: undefined,
            due_amount: undefined,
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "No assigned GDN for this Purchase Order or existing GDN-related transaction",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGDNSelect = (value) => {
    form.setFieldsValue({
      grn: undefined,
      product_receive_date: undefined,
      product_name: undefined,
      provided_quantity: undefined,
      received_quantity: undefined,
      product_sale_rate_per_kg: undefined,
      other_cost: 0,
      total_receivable: undefined,
      bill_receive_date: undefined,
      received_amount: undefined,
      due_amount: undefined,
    });

    const selectedGDN = WORelatedInfo?.find(
      (item) => item.gdn_number === value
    );
    form.setFieldsValue({
      product_name:
        selectedGDN?.product_name ||
        selectedGDN?.work_order?.sourcing_product?.product_name,
      provided_quantity:
        selectedGDN?.purchase_quantity_kg || selectedGDN?.total_quantity,
      product_sale_rate_per_kg: selectedGDN?.work_order?.rate_tk_per_kg,
    });
  };
  const handleReceivedQuantity = (e) => {
    const receivedQuantity = e.target.value;
    const rate = form.getFieldValue("product_sale_rate_per_kg");
    const parsedReceivedQuantity =
      receivedQuantity === "" ? 0 : parseFloat(receivedQuantity);
    form.setFieldsValue({
      total_receivable: rate
        ? parseFloat(rate) * parseFloat(parsedReceivedQuantity)
        : 0,
    });
  };

  const handleOtherCost = (e) => {
    const otherCost = e.target.value;
    const receivedQuantity = form.getFieldValue("received_quantity");
    const rate = form.getFieldValue("product_sale_rate_per_kg");

    const parsedOtherCost = otherCost === "" ? 0 : parseFloat(otherCost);

    const totalBeforeSubtraction =
      receivedQuantity && rate
        ? Math.round(parseFloat(receivedQuantity) * parseFloat(rate))
        : 0;

    const newTotal = totalBeforeSubtraction
      ? Math.round(totalBeforeSubtraction - parsedOtherCost)
      : totalBeforeSubtraction;

    form.setFieldsValue({
      total_receivable: newTotal,
    });
  };

  const handleReceivedAmount = (e) => {
    const receivedAmount =
      e.target.value === "" ? 0 : parseFloat(e.target.value);
    const receivedQuantity = parseInt(form.getFieldValue("received_quantity"));
    const parsedReceivedQuantity =
      receivedQuantity === "" ? 0 : parseFloat(receivedQuantity);

    const rate = form.getFieldValue("product_sale_rate_per_kg");

    const other = form.getFieldValue("other_cost");
    const parsedOtherCost = other === "" ? 0 : parseFloat(other);

    const totalBeforeSubtraction =
      parsedReceivedQuantity && rate
        ? Math.round(parseFloat(parsedReceivedQuantity) * parseFloat(rate)) -
          parseFloat(parsedOtherCost)
        : 0;

    const newTotal = totalBeforeSubtraction
      ? Math.round(totalBeforeSubtraction - receivedAmount)
      : totalBeforeSubtraction;

    form.setFieldsValue({
      due_amount: newTotal,
      total_received: receivedAmount,
    });
  };

  const handleImageUpload = async (info, setImage) => {
    if (!info?.fileList[0]) return;

    const imageFile = info?.fileList[0];
    const params = {
      Bucket: "sgp-prod-mis-s3",
      Key: `${imageFile?.name}`,
      Body: imageFile?.originFileObj,
      ContentType: "image/jpeg", //<-- this is what you need!
      ContentDisposition: "inline",
    };
    const s3 = new awsS3({
      accessKeyId: "AKIA5J3KD5ACXFPKXVUY",
      secretAccessKey: "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",
      region: "ap-southeast-1",
    });

    try {
      await s3.upload(params).promise();
      const imageUrl = s3.getSignedUrl("getObject", {
        Bucket: params.Bucket,
        Key: params.Key,
        Expires: 315360000,
      });
      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (data) => {
    data.product_receive_date = productReceiveDate;
    data.bill_receive_date = billReceiveDate;
    data.grn_date = grnDate;
    data.grn_image = grnImage;
    axios
      .post(
        `${baseUrl}/ip/ip_transaction_create/`,
        {
          data: data,
        },
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 201) {
          Swal.fire({
            title: "Success",
            text: response?.data?.message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            form.resetFields();
            setBillReceiveDate(null);
            setProductReceiveDate(null);
            handleTransactionSalesModalClose();
            fetchIPTransaction();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message,
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };

  const paymentInfoColumn = [
    {
      title: "Received Amount",
      render: (record) => record?.payment_amount?.toLocaleString(),
    },
    {
      title: "Bill Receive Date",
      dataIndex: "payment_date",
    },
  ];

  return (
    <Modal
      title="Transaction Sales"
      open={visible}
      onCancel={handleModalClose}
      footer={false}
      width="50%"
    >
      <Form
        className="register-form"
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="WO No"
              name="work_order_id"
              rules={[{ required: true, message: "Please select WO" }]}
            >
              <Select
                placeholder="Select WO"
                showSearch
                onChange={handleSelectWO}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {workOrderInfo?.map((workOrder) => (
                  <Option
                    key={workOrder.work_order_id}
                    value={workOrder.work_order_id}
                  >
                    {workOrder.work_order_id}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="PO No"
              name="po_no"
              // rules={[{ required: true, message: "PO required" }]}
            >
              <Input placeholder="PO number" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Sourcing Type"
              name="sourcing_type"
              rules={[{ required: true, message: "Sourcing type required" }]}
            >
              <Select
                placeholder="Select sourcing type"
                onChange={handleSourcingType}
              >
                <Option value="direct">Direct</Option>
                <Option value="warehouse">Warehouse</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GDN"
              name="gdn_number"
              labelCol={{ span: 8 }}
              rules={[{ required: true, message: "Please select GDN" }]}
            >
              <Select
                placeholder="Select GDN"
                showSearch
                onChange={handleGDNSelect}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {WORelatedInfo?.map((item) => (
                  <Option key={item.gdn_number} value={item.gdn_number}>
                    {item.gdn_number}
                  </Option>
                ))}
                {/* {sourcingType.includes("warehouse") &&
                  WORelatedInfo?.warehouse?.map((item) => (
                    <Option key={item.gdn_number} value={item.gdn_number}>
                      {item.gdn_number}
                    </Option>
                  ))} */}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GRN"
              name="grn"
              rules={[
                {
                  required: true,
                  message: "Please enter GRN",
                },
                {
                  pattern: /^[a-zA-Z]?|[0-9]+(\.[0-9]+)+$/,
                  message: "Only English characters",
                },
              ]}
            >
              <Input placeholder="GRN" />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="GRN Date"
              name="grn_date"
              rules={[{ required: true, message: "Please enter grn date" }]}
            >
              <DatePicker format="YYYY-MM-DD" onChange={handleGrnDateChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Product Receive Date"
              name="product_receive_date"
              rules={[
                {
                  required: true,
                  message: "Please enter product receive date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "350px" }}
                format="YYYY-MM-DD"
                onChange={handleProductReceiveDateChange}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Product Name"
              name="product_name"
              rules={[
                {
                  required: true,
                  message: "Required product name",
                },
              ]}
            >
              <Input placeholder="Product Name" readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Provided Quantity"
              name="provided_quantity"
              rules={[
                {
                  required: true,
                  message: "Required provided quantity",
                },
              ]}
            >
              <Input placeholder="Provided Quantity" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Received Quantity"
              name="received_quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter receive quantity",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only english number",
                },
              ]}
            >
              <Input
                placeholder="Receive Quantity"
                onChange={handleReceivedQuantity}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Sale (Rate/KG)"
              name="product_sale_rate_per_kg"
              labelCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: "Required Product Sale (Rate/KG)",
                },
              ]}
            >
              <Input placeholder="Product Sale (Rate/KG)" readOnly />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Other Cost"
              name="other_cost"
              labelCol={{ span: 8 }}
              initialValue={0}
              rules={[
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only english number",
                },
              ]}
            >
              <Input placeholder="Other Cost" onChange={handleOtherCost} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Total Receivable"
              name="total_receivable"
              rules={[
                {
                  required: true,
                  message: "Required total receivable",
                },
              ]}
            >
              <Input placeholder="Total Receivable" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              hasFeedback
              label="Total Received"
              name="total_received"
              rules={[
                {
                  required: true,
                  message: "Required total received",
                },
              ]}
            >
              <Input placeholder="Total Received" readOnly />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item hasFeedback label="Due Amount" name="due_amount">
              <Input placeholder="Due Amount" readOnly />
            </Form.Item>
          </Col>
        </Row>
        {paymentInfoList?.length > 0 && (
          <Table
            className="ms-1"
            columns={paymentInfoColumn}
            // dataSource={paymentInfoList}
            size="small"
            rowKey={(record) => record?.work_order_id}
            pagination={{
              pageSize: 5,
            }}
          />
        )}
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Received Amount"
              name="received_amount"
              rules={[
                {
                  required: true,
                  message: "Please enter received amount",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]+)?$/,
                  message: "Only english number",
                },
              ]}
            >
              <Input
                placeholder="Received Amount"
                onChange={handleReceivedAmount}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Bill Receive Date"
              name="bill_receive_date"
              labelCol={{ span: 8 }}
              rules={[
                { required: true, message: "Please enter bill receive date" },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={handleBillReceiveDateChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Remarks"
              name="remarks"
              labelCol={{ span: 8 }}
            >
              <Input placeholder="Total Remarks" />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              hasFeedback
              label="Image of GRN"
              name="grn_image"
              valuePropName="fileList"
              placeholder="Upload grn image"
              getValueFromEvent={(e) => e.fileList}
              rules={[
                {
                  required: true,
                  message: "Please insert grn image",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length > 0) {
                      const file = value[0];
                      const fileType = file.type;
                      const acceptedFormats = [
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                        "image/heic",
                      ];
                      if (acceptedFormats.includes(fileType)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Please upload a valid image file (JPEG, PNG, JPG OR HEIC)."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Upload
                beforeUpload={() => false}
                onChange={(info) => handleImageUpload(info, setGrnImage)}
                listType="picture"
              >
                <Button
                  icon={<UploadOutlined />}
                  loading={uploading}
                  style={{
                    backgroundColor: "#46604A",
                    borderColor: "#46604A",
                    color: "white",
                  }}
                >
                  {uploading ? "Uploading" : "Upload GRN Image"}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Form.Item>
            <Button
              htmlType="submit"
              style={{
                backgroundColor: "#46604A",
                color: "#ffffff",
              }}
              disabled={loading}
            >
              Create
              {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
