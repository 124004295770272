import { Button, Divider, Modal, Table } from "antd";
import React, { useRef } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

export default function InvoiceModal({
  visible,
  handleInvoiceModalClose,
  data,
  responseData,
}) {
  const modalRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => modalRef.current,
    onAfterPrint: () => {
      handleInvoiceModalClose();
    },
  });

  function calculateNetTotal(data) {
    let netTotal = 0;

    data?.products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      const productTotalPrice = parseInt(product.product_total_price);
      const productNetTotal = productTotalPrice + discount;
      netTotal += productNetTotal;
    });

    return parseInt(netTotal).toLocaleString();
  }

  function calculateTotalDiscount(data) {
    let totalDiscount = 0;

    data?.products?.forEach((product) => {
      const discount = parseInt(product.discount) || 0;
      totalDiscount += discount;
    });

    return parseInt(totalDiscount).toLocaleString();
  }

  const productTableColumn = [
    {
      title: "Product",
      dataIndex: "product_name",
    },
    {
      title: "QTY",
      dataIndex: "quantity",
    },
    {
      title: "MRP",
      dataIndex: "selling_price",
    },
    {
      title: "DISCOUNT",
      render: (record) => record?.discount || 0,
    },
    {
      title: "AMOUNT",
      dataIndex: "product_total_price",
    },
  ];
  return (
    <Modal
      open={visible}
      onCancel={handleInvoiceModalClose}
      footer={false}
      centered
    >
      <div ref={modalRef} className="p-3">
        <h5 className="text-center" style={{ color: "#000000" }}>
          <strong>Agrigate Network Limited</strong>
        </h5>
        <h6 className="text-center" style={{ color: "#000000" }}>
          Center - {responseData?.center?.center_name}
        </h6>
        <div
          className="d-flex justify-content-around my-4 pt-3 fw-medium"
          style={{
            color: "#000000",
            fontSize: "16px",
            border: "solid 1px #000000",
          }}
        >
          <div className="w-50 ms-3">
            <p className="mb-0">Sales ID:</p>
            <p>Date:</p>

            <p className="mb-0">Customer Name:</p>
            <p>Customer Number:</p>
          </div>
          <div className="w-50">
            <p className="mb-0">
              <strong>{responseData?.sales_id}</strong>
            </p>
            <p>{new Date(responseData?.created_at).toLocaleString()}</p>
            <p className="mb-0">
              <strong>
                {responseData?.farmer?.name ||
                  responseData?.retailer?.name ||
                  responseData?.institutional?.institutional_buyer_name}
              </strong>
            </p>
            <p>
              {"0" +
                (responseData?.farmer?.phone ||
                  responseData?.retailer?.phone ||
                  responseData?.institutional?.institutional_buyer_phone)}
            </p>
          </div>
        </div>
        <Divider
          style={{ background: "transparent", border: "1px dashed #808080" }}
        />
        <Table
          columns={productTableColumn}
          dataSource={data?.products}
          pagination={false}
          rowKey={(record) => record?.batch_id}
        />
        <div className="text-end me-2 mt-3">
          <p>Total MRP: {calculateNetTotal(data)} BDT</p>
          <p>Total Discount: {calculateTotalDiscount(data)} BDT</p>
          <p className="fw-bold">
            Net Payable: {parseInt(data?.total_price)} BDT
          </p>
          <p className="fw-bold">
            Total Paid: {parseInt(data?.total_paid)} BDT
          </p>
          <p>Due: {parseInt(data?.due)} BDT</p>
        </div>
        <Divider
          style={{ background: "transparent", border: "1px dashed #000000" }}
        />
        <p className="text-center">
          যে কোন পরামর্শ এবং সহায়তার জন্য এগ্রিগেট নেটওয়ার্ক লিমিটেড এর হটলাইনে
          যোগাযোগ করুনঃ ০১৩২৪৭৪১৬১৯,০১৩২৪৭৪১৬২০
        </p>
        <Divider
          style={{ background: "transparent", border: "1px dashed #000000" }}
        />
        <p className="text-center">
          Powered by <strong>Agrigate Network Limited</strong>
        </p>
      </div>

      <Button
        className="d-flex align-items-center fw-bold"
        style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        onClick={handlePrint}
      >
        <PrinterOutlined />
        Print
      </Button>
    </Modal>
  );
}
