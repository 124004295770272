import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/Agrigate.png";
import { Button, Select, Table, Modal } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { DateRangePicker } from "rsuite";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import html2pdf from "html2pdf.js";

export default function ProductDemandDashboard() {
  const componentRef = useRef(null);
  const { Option } = Select;
  const [salesLoading, setSalesLoading] = useState(false);
  const [contractFarmerList, setContractFarmerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [projects, setProjects] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [contractProducts, setContractProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [fetchedContractInfo, setFetchedContractInfo] = useState({})

  const handleDateRage = (value) => {
    setSelectedDateRange(value);
  };
  const handleSelectedProject = (value) => {
    setSelectedProject(value);
  };
  const handleSelectedYear = (value) => {
    setSelectedYear(value);
  };
  const handleSelectedType = (value) => {
    setSelectedType(value);
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/contractFarming/projects/`, {
        headers: authHeader(),
      })
      .then((response) => setProjects(response?.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSalesLoading(true);
        const response = await axios.get(
          `${baseUrl}/contractFarming/CFDemandRecordList/`,
          {
            headers: authHeader(),
            params: {
              page: currentPage,
              project_id: selectedProject,
              type: selectedType,
              year: selectedYear,
              dateRange: selectedDateRange
            },
          }
        );
        setContractFarmerList(response?.data?.results);
        setTotalItems(response?.data?.count);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setSalesLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    selectedDateRange,
    selectedProject,
    selectedType,
    selectedYear,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleViewModal = (record) => {
    setViewModal(true);
    setSelectedData(record);
    setLoading(true);
    axios
      .get(
        `${baseUrl}/contractFarming/CFProductDemandList/`,
        {
          headers: authHeader(),
          params: {
            cf_demand_record_id: record?.cf_demand_record_id,
            project_id: record?.project?.project_id,
            farmer_id: record?.farmer?.farmer_id,
          },
        }
      )
      .then((data) => {
        setContractProducts(data?.data?.product_list);
        setFetchedContractInfo(data?.data?.contract_farming_details);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDownloadPDF = () => {
    const input = componentRef.current;

    const pdfOptions = {
      margin: 10,
      filename: `Farmer-${selectedData?.farmer?.name}`,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(input, pdfOptions);
  };
  const columns = [
    {
      title: "Date",
      width: "10%",
      render: (record) =>
        record?.created_at ? new Date(record?.created_at).toLocaleDateString() : "",
    },
    {
      title: "Location",
      width: "10%",
      render:(record) => record?.project?.district
    },
    {
      title: "Project",
      render:(record) => record?.project?.project_name,
      width: "10%",
    },
    // {
    //   title: "Group",
    //   width: "10%",
    //   dataIndex: "group_name",
    // },
    // {
    //   title: "BP",
    //   dataIndex: "bp_name",
    //   width: "15%",
    // },
    {
      title: "Farmer Name",
      render:(record) => record?.farmer?.name,
      width: "15%",
    },
    {
      title: "Phone",
      render: (record) => "0" + record?.farmer?.phone,
      width: "15%",
    },
    {
      title: "Agreement",
      render: (record) => (
        <Button onClick={() => handleViewModal(record)}>Agreement</Button>
      ),
      width: "15%",
    },
  ];

  return (
    <>
      <Modal
        title={false}
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
          setSelectedData(null);
          setContractProducts([]);
        }}
        width="800px"
        footer={null}
      >
        <div ref={componentRef}>
          <div className="d-flex justify-content-between my-4">
            <div className="text-center">Agrigate Network Limited</div>
            <div className="text-center">
              Contract Farming
              <br />
              <small>Agreement Sheet</small>
            </div>
          </div>
          <div
            className="my-3 text-center py-1"
            style={{ backgroundColor: "lightgray", color: "#46604A" }}
          >
            FARMER INFO
          </div>
          <div className="d-flex justify-content-between">
            <table
              className="w-50 table-bordered "
              style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
            >
              <tbody className="m-5">
                <tr>
                  <th className="p-1 w-25">Farmer ID</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.farmer_id}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">Name</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.name}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">Phone</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    0{selectedData?.farmer?.phone}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">District</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.district?.district_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">Upazila</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.upazila?.upazila_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">Union</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.union?.union_name}
                  </td>
                </tr>
                <tr>
                  <th className="p-1">NID</th>
                  <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                    {selectedData?.farmer?.nid}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                width: "30%",
                height: "220px",
                border: "solid 1px gray",
              }}
            ></div>
          </div>
          <div
            className="my-3 text-center py-1"
            style={{ backgroundColor: "lightgray", color: "#46604A" }}
          >
            CONTRACT FARMING INFO
          </div>

          <table
            className="w-75 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-1 w-25">Project Name</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {selectedData?.project?.project_name}
                </td>
              </tr>
              <tr>
                <th className="p-1">Project Type</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {selectedData?.project?.project_type}
                </td>
              </tr>
              <tr>
                <th className="p-1">Project Year</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {selectedData?.project?.year}
                </td>
              </tr>
              <tr>
                <th className="p-1">Agri-Product</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {fetchedContractInfo?.crop_or_livestock_name}
                </td>
              </tr>
              <tr>
                <th className="p-1">Group Name</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {fetchedContractInfo?.group_name}
                </td>
              </tr>
              <tr>
                <th className="p-1">BP Name</th>
                <td className="p-1" style={{ backgroundColor: "#E6E6E6" }}>
                  {fetchedContractInfo?.bp_name}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            className="my-3 text-center py-1"
            style={{ backgroundColor: "lightgray", color: "#46604A" }}
          >
            PRODUCT INFO
          </div>
          <table
            className="w-100 table-bordered text-center"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th>Project Type</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total Price</th>
                <th>Signature</th>
              </tr>
              {contractProducts?.map((product, index) => (
                <tr key={index}>
                  <td>{selectedData?.project?.project_type}</td>
                  <td>{product?.product?.product_name_english}</td>
                  <td>{product?.quantity}</td>
                  <td>{product?.product_total_price?.toLocaleString()}</td>
                  <td>{(parseFloat(product?.product_total_price)* parseFloat(product?.quantity)).toLocaleString()}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Button
          className="mt-4"
          style={{ color: "#46604A" }}
          onClick={handleDownloadPDF}
          disabled={loading}
        >
          Save PDF
        </Button>
      </Modal>

      <div className="p-5" style={{color:"#46604A"}}>
        <section className="headSection d-flex align-items-center">
          <div>
            <img
              src={logo}
              style={{ width: "100px", height: "100px" }}
              alt="agrigate"
            />
          </div>
          <div>
            <h5>Contract Farmer Product Demand</h5>
          </div>
        </section>

        <h5 className="ms-2 mt-5">Demand Details</h5>
        <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
          <div className="ms-3 mb-1">
            <p className="m-0">Date: </p>
            <DateRangePicker
              onChange={handleDateRage}
              value={selectedDateRange}
              style={{ width: "250px" }}
              showOneCalendar
            />
          </div>
          <div className="ms-2 mb-1">
            <p className="m-0">Project: </p>
            <Select
              style={{ width: "250px" }}
              placeholder="Select location"
              showSearch
              onChange={handleSelectedProject}
              value={selectedProject}
            >
              <Option value="">All Projects</Option>
              {projects?.map((project) => (
                <Option value={project?.project_id} key={project?.project_id}>
                  {project?.project_name +
                    " - " +
                    project?.project_type +
                    " - " +
                    project?.year}
                </Option>
              ))}
            </Select>
          </div>
          <div className="ms-1 mb-1">
            <p className="m-0">Type: </p>
            <Select
              style={{ width: "200px" }}
              showSearch
              placeholder="Select Type"
              onChange={handleSelectedType}
              value={selectedType}
            >
              <Option value="">All Types</Option>
              <Option value="Livestock">Livestock</Option>
              <Option value="Crop">Crop</Option>
            </Select>
          </div>
          <div className="ms-1 mb-1">
            <p className="m-0">Year: </p>
            <Select
              style={{ width: "200px" }}
              showSearch
              placeholder="Select Type"
              onChange={handleSelectedYear}
              value={selectedYear}
            >
              <Option value="">All Years</Option>
              <Option value="2022">2022</Option>
              <Option value="2023">2023</Option>
              <Option value="2024">2024</Option>
            </Select>
          </div>
        </div>
        <div className="m-2 mt-2 border">
          <Table
            columns={columns}
            dataSource={contractFarmerList}
            loading={salesLoading}
            rowKey={(record, index) => index}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
