import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { Table } from "antd";

export default function OperationsDashboard() {
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState(null);
  const [consultationCount, setConsultationCount] = useState(null);
  const fetchCountData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/contractFarming/projectStats/`,
        {
          headers: authHeader(),
        }
      );
      setCountData(response?.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchConsultationData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/consultation/consultationStats/`,
        {
          headers: authHeader(),
        }
      );
      setConsultationCount(response?.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCountData();
    fetchConsultationData();
  }, []);

  const runningProjectsColumn = [
    {
      title: "Project",
      dataIndex: "project_name",
    },
    {
      title: "Location",
      dataIndex: "district",
    },
    {
      title: "Type",
      dataIndex: "project_type",
    },
  ];
  return (
    <div className="consultationDashboard">
      <div className="dashboardNameSection">
        <h5 style={{ color: "#46604A" }}>OPERATIONS DASHBOARD</h5>
      </div>
      <div className="consultationDashboardContainer shadow-sm rounded" style={{color:"#46604A"}}>
        <div className="mainView px-3">
          <div className="mainViewLeft shadow-sm border text-center mx-2">
            <p
              className="py-1 m-0"
              style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            >
              Contract Farming
            </p>
            <div className="contractGridUpper px-2">
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Projects
                </p>
                <div className="mb-3 mt-4 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    No of Projects
                  </div>
                  <div className="w-50 py-2 border">
                    {countData?.total_projects || 0}
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Farmer Acquisition
                </p>
                <div className="mb-3 mt-4 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    No of Farmer
                  </div>
                  <div className="w-50 py-2 border">
                    {countData?.distinct_contract_farmers || 0}
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Projects
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Complete
                    </div>
                    <div className="w-50 py-2 border">
                      {countData?.complete_status_projects || 0}
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      On going
                    </div>
                    <div className="w-50 py-2 border">
                      {countData?.ongoing_status_projects || 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contractGridLower mb-4 px-2">
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Crop CF
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Farmer
                    </div>
                    <div className="w-50 py-2 border">
                      {countData?.crop_contract_farming_count || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Livestock CF
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Farmer
                    </div>
                    <div className="w-50 py-2 border">
                      {countData?.livestock_contract_farming_count || 0}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Running Projects
                </p>
                <Table
                  columns={runningProjectsColumn}
                  dataSource={countData?.running_projects}
                  loading={loading}
                  rowKey={(record) => record?.project_id}
                  size="small"
                  pagination={{
                    pageSize: 3,
                  }}
                />
              </div>
            </div>

            <p
              className="py-1 m-0"
              style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            >
              Consultation
            </p>
            <div className="contractGridUpper px-2">
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Total Ticket
                </p>
                <div className="mt-4 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Open
                  </div>
                  <div className="w-50 py-2 border">
                    {consultationCount?.open_tickets || 0}
                  </div>
                </div>
                <div className="mt-1 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Closed
                  </div>
                  <div className="w-50 py-2 border">
                    {consultationCount?.closed_tickets || 0}
                  </div>
                </div>
                <div className="mb-3 mt-1 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Total
                  </div>
                  <div className="w-50 py-2 border">
                    {consultationCount?.total_tickets || 0}
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Crop Ticket
                </p>
                <div className="mt-4 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Open
                  </div>
                  <div className="w-50 py-2 border">
                    {consultationCount?.crop_open_tickets || 0}
                  </div>
                </div>
                <div className="mb-3 mt-1 d-flex align-items-center text-center mx-4">
                  <div
                    className="w-50 py-2 border"
                    style={{ backgroundColor: "#E6E6E6" }}
                  >
                    Closed
                  </div>
                  <div className="w-50 py-2 border">
                    {consultationCount?.crop_closed_tickets || 0}
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-2 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Livestock Tickets
                </p>
                <div className="my-3">
                  <div className="mt-4 d-flex align-items-center text-center mx-4">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Open
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.livestock_open_tickets || 0}
                    </div>
                  </div>
                  <div className="mb-3 mt-1 d-flex align-items-center text-center mx-4">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Closed
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.livestock_closed_tickets || 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="consultationGridLower px-2">
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Top Issue Raised in Tickets
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Crop
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.top_crop_issue_raised || ""}
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Livestock
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.top_livestock_issue_raised || ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border mt-4">
                <p
                  className="py-1 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Highest Ticket Raised Location
                </p>
                <div className="my-3">
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Crop
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.highest_crop_ticket_location_district || ""}
                    </div>
                  </div>
                  <div className="d-flex align-items-center text-center mx-4 mb-1">
                    <div
                      className="w-50 py-2 border"
                      style={{ backgroundColor: "#E6E6E6" }}
                    >
                      Livestock
                    </div>
                    <div className="w-50 py-2 border">
                      {consultationCount?.highest_livestock_ticket_location_district || ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mainViewRight shadow-sm border text-center mx-2">
            <p
              className="py-1 m-0"
              style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            >
              Total Survey & KYC
            </p>
            <div className="surveyKYCGrid px-2">
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Dairy
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Beef
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Ricemill
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Warehouse
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Agro Processor
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Crop
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Fishery
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Poultry
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Farmer KYC
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  Retailer KYC
                </p>
                <p className="py-3 m-0">0</p>
              </div>
              <div className="border mt-4">
                <p
                  className="py-3 m-0"
                  style={{ backgroundColor: "lightgray", color: "#46604A" }}
                >
                  LSP KYC
                </p>
                <p className="py-3 m-0">0</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
