import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";

export default function WorkOrderDetailsModal({
  viewModal,
  stockDetailsModalClose,
  data,
}) {
  const [grnRelatedGDNList, setGrnRelatedGDNList] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseUrl}/sourcing/grn_related_gdns/`, {
        headers: authHeader(),
        params:{
          grn: data?.grn
        }
      })
      .then((response) => {
        setGrnRelatedGDNList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data?.grn]);

  return (
    <Modal
      open={viewModal}
      onCancel={stockDetailsModalClose}
      width="50%"
      footer={false}
    >
      <div className="d-flex justify-content-between">
        <table
          className="my-4 table-bordered w-75"
          style={{
            border: "0.3px solid lightgray ",
            color: "#46604A",
            height: "200px",
          }}
        >
          <tbody>
            <tr>
              <td className="p-1 w-50">
                <strong>Warehouse</strong>
              </td>
              <td className="p-1">{data?.warehouse?.warehouse_name}</td>
            </tr>
            <tr>
              <td className="p-1">
                <strong>GRN:</strong>
              </td>
              <td className="p-1">{data?.grn}</td>
            </tr>
            <tr>
              <td className="p-1">
                <strong>Stock In (Sack):</strong>
              </td>
              <td className="p-1">{data?.stock_in_sack} sack</td>
            </tr>
            <tr>
              <td className="p-1">
                <strong>Stock In (Kg):</strong>
              </td>
              <td className="p-1">{data?.stock_in_kg} kg</td>
            </tr>
            <tr>
              <td className="p-1">
                <strong>Remaining Stock (Sack):</strong>
              </td>
              <td className="p-1">{data?.remaining_stock_sack} sack</td>
            </tr>
            <tr>
              <td className="p-1">
                <strong>Remaining Stock (Kg):</strong>
              </td>
              <td className="p-1">{data?.remaining_stock_kg} kg</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pe-2 w-100" style={{ overflowX: "auto" }}>
        <p style={{ fontSize: 16, color: "#46604A",}} className="mb-1">
          <strong>Purchase Details</strong>
        </p>
        <div className="table-responsive">
          <table className="table table-bordered text-center" style={{color: "#46604A",}}>
            <thead>
              <tr>
                <th className="p-1">Purchase Date</th>
                <th className="p-1">GDN</th>
                <th className="p-1">Product name</th>
                <th className="p-1">Number of sack</th>
                <th className="p-1">Quantity (Kg)</th>
              </tr>
            </thead>
            <tbody>
              {grnRelatedGDNList?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item && item.created_at
                      ? new Date(item.created_at).toLocaleString()
                      : ""}
                  </td>
                  <td className="p-1">{item?.gdn_number?.gdn_number}</td>
                  <td className="p-1">{item?.product_name}</td>
                  <td className="p-1">{item?.number_of_sack}</td>
                  <td className="p-1">{item?.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}
