import { Button, Image, Modal, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";

export default function RetailerKyc() {
  const {Option} = Select;
  const [retailerKYC, setRetailerKYC] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [viewImageModal, setViewImageModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [KYCDistinctValues, setKYCDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading,setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbxfPwxb8etbFkQscibU3mCPzDndm7z0h5ewGWH9DapDME5lb1agXOI9aVBlztiNdsqE/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setKYCDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [retailerKYC]);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handleType = (value) => {
    setSelectedType(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbyczXr7BzyABbGmXJ7RbEc68FWaihwPbmx1plkC5Tn7LJtpW11XWb27rlAGDvOBqwnh/exec?page=${currentPage}&district=${selectedDistrict}&type=${selectedType}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setRetailerKYC(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [
    currentPage,
    selectedDateRange,
    selectedDistrict,
    selectedType,
  ]);

  const retailerKycColumns = [
    {
      title: "KYC Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Date);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Retailer ID",
      width: "11%",
      dataIndex: "Retailer_ID",
    },
    {
      title: "Retailer Name",
      width: "12%",
      dataIndex: "Retailer_Name",
    },
    {
      title: "Retailer Phone",
      width: "12%",
      render: (record) => {
        return "0" + record?.Retailer_Phone; 
      },
    },
    {
      title: "Business Type",
      dataIndex: "Business_Type",
      width: "10%",
    },
    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },

    {
      title: "Bazar Name",
      dataIndex: "Bazar_Name",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Employee_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };
  const handleImageClick = (url) => {
    setViewImageModal(true);
    setImageUrl(url);
  };
  return (
    <>
      <Modal
        title="Sign Board Image"
        centered
        visible={viewImageModal} // Use 'visible' to control the visibility of the Modal
        footer={null}
        onCancel={() => {
          setViewImageModal(false);
        }}
      >
        <Image width={200} src={imageUrl} alt="Sign Board Image" />
      </Modal>
      <Modal
        title="Retailer KYC"
        centered
        open={viewModal}
        onCancel={() => setViewModal(false)}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Retailer Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Retailer ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Retailer_ID}
                </td>
              </tr>
              <tr>
                <th className="p-2">Retailer Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Retailer_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Retailer Phone</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Retailer_Phone}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union_Name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Trader Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-25">Trader Name</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Traders_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">Business Type</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Business_Type}
                </td>
              </tr>
              <tr>
                <td className="p-2">Signboard Image</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  <Button
                    // style={{backgroundColor: "unset" }}
                    onClick={() => handleImageClick(modalData?.SIgnboard_Image)}
                  >
                    {" "}
                    click
                  </Button>
                </td>
              </tr>
              <tr>
                <td className="p-2">Years of experience</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Years_Of_Experience}
                </td>
              </tr>
              <tr>
                <td className="p-2">Interested to work with Agrigate</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Interested_to_work_with_Agrigate}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <td className="p-2 w-25">District</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">Upazila</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">Union</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">Bazar Name</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Bazar_Name}
                </td>
              </tr>
              <tr>
                <td className="p-2">MAP</td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Latitude},{modalData?.Longitude}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="my-3 text-center py-1"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Products
        </div>
        <div className="d-flex">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 text-center">Product Name</th>
                <th className="p-2 text-center">Sale Volume</th>
              </tr>
              <tr>
                <td className="p-2 w-25"></td>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                 
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-center w-50 mx-1">
            <div
              className="py-1"
              style={{ backgroundColor: "#46604A", color: "#ffffff" }}
            >
              Most Selling Product's Company
            </div>
            <div
              className="py-1"
              style={{ backgroundColor: "#E6E6E6", color: "#46604A" }}
            >
              {modalData?.Most_Selling_Product_Company}
            </div>
          </div>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>RETAILER KYC DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
        <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {KYCDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Type: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleType}
                value={selectedType}
              >
                <Option value="">All Types</Option>
                {KYCDistinctValues?.distinctType?.map(
                  (type, index) => (
                    <Option value={type} key={index}>
                      {type}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
          <Table
            columns={retailerKycColumns}
            dataSource={retailerKYC}
            loading={tableLoading}
            rowKey={(record) => record.Retailer_ID}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
