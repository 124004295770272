import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieChart = ({ title, data }) => {
  const chartRef = useRef(null);
  const options = {
    chart: {
      type: "pie",
      height: 280,
    },
    title: {
      text: title,
    },
    series: [
      {
        name: ["Count"],
        data: data,
      },
    ],
    colors: ["#7E9B81",'#EBF3E8'],
  };

  useEffect(() => {
    if (chartRef.current) {
      // Create a new options object with updated data
      const newOptions = {
        ...options,
        series: [
          {
            name: ["Count"],
            data: data,
          },
        ],
      };

      // Update the chart with the new options
      chartRef.current.chart.update(newOptions);
    }
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
