import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import baseUrl from "../../../config";
import authHeader from "../../Authentication/authHeader";
import { Button, Form, Modal, Select, Spin } from "antd";

export default function OrderFulfillmentModal({
  visible,
  onCancel,
  modalData,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [orderFulfillProducts, setOrderFulfillProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const handleCancel = () => {
    onCancel();
    setOrderFulfillProducts(null);
  };
  useEffect(() => {
    form.setFieldsValue({
      fulfillment_status: modalData.fulfillment_status,
    });
  },[modalData, form]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/sales_data/${modalData?.sale_id}`, {
        headers: authHeader(),
      })
      .then((data) => {
        if (data?.data) {
          console.log(data?.data?.invoice_products);
          setOrderFulfillProducts(data?.data?.invoice_products);
          setLoading(false);
        } else {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: "Invoice not found",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      });
  }, [modalData?.sale_id]);

  const handleStatusSelect = (value) => {
    setStatus(value);
  };

  const handleUpdate = (value) => {
    setLoading(true);
    value.fulfillment_status = status
    axios
      .patch(
        `${baseUrl}/retailer/orderFulfillmentUpdate/${modalData?.fulfillment_id}/`,
        value,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Fulfillment Updated Successful",
            confirmButtonText: "Ok",
            icon: "success",
          }).then(() => {
            handleCancel();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };

  return (
    <Modal
      title="Order Fulfillment"
      centered
      open={visible}
      onCancel={handleCancel}
      width={"65%"}
      footer={null}
    >
      <div
        className="my-3 py-1 text-center"
        style={{ backgroundColor: "#46604A", color: "#ffffff" }}
      >
        Order Fulfillment Details
      </div>
      <div className="d-flex justify-content-between">
        <table
          className="w-50 table-bordered"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th className="p-2">Date</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.fulfillment_date}
              </td>
            </tr>
            <tr>
              <th className="p-2 w-25">District</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.order?.district}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          className="w-50 table-bordered"
          style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
        >
          <tbody className="m-5">
            <tr>
              <th className="p-2 w-25">Name</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {modalData?.order?.farmer?.name ||
                  modalData?.order?.retailer?.name ||
                  modalData?.order?.agrigate_center_name}
              </td>
            </tr>
            <tr>
              <th className="p-2">Phone</th>
              <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                {0}
                {modalData?.order?.retailer?.phone ||
                  modalData?.order?.farmer?.phone ||
                  null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="my-3 py-1 text-center"
        style={{ backgroundColor: "#46604A", color: "#ffffff" }}
      >
        Product Information
      </div>
      <div className="d-flex">
        <div className="w-50 ms-1">
          <p
            className="border text-center mb-1"
            style={{ backgroundColor: "#46604A", color: "#ffffff" }}
          >
            Order Taken Products & Quantity
          </p>
          <table
            className="w-100  table-bordered text-center"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
              </tr>

              {modalData?.order?.ordered_products?.map((product, index) => (
                <tr key={index}>
                  <td className="py-2">
                    {product.product.product_name_english}
                  </td>
                  <td>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-50 ms-1">
          <p
            className="border text-center mb-1"
            style={{ backgroundColor: "#46604A", color: "#ffffff" }}
          >
            Order Fulfillment Products & Quantity
          </p>
          <table
            className="w-100  table-bordered text-center"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
              </tr>
              {loading ? (
                <tr className="w-100">
                  <td colSpan={5}>
                    <Spin />
                  </td>
                </tr>
              ) : (
                orderFulfillProducts?.map((product, index) => (
                  <tr key={index}>
                    <td className="py-2">{product.product.product_name}</td>
                    <td>{product.quantity}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Form
        form={form}
        layout="horizontal"
        className="mt-5"
        onFinish={handleUpdate}
      >
        <Form.Item
          label={<strong>Status</strong>}
          name="fulfillment_status"
          className="d-flex justify-content-end"
          rules={[
            {
              required: true,
              message: "Status is required",
            },
          ]}
        >
          <Select
            placeholder="Select Status"
            style={{ width: "200px" }}
            onChange={handleStatusSelect}
          >
            <Option value="In progress">In progress</Option>
            <Option value="Completed">Completed</Option>
            <Option value="Due">Due</Option>
          </Select>
        </Form.Item>
        {/* {status.includes("Due") && (
          <Form.Item
            label={<strong>Status</strong>}
            name="fulfillment_status"
            className="d-flex justify-content-end"
            rules={[
              {
                required: true,
                message: "Status is required",
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              style={{ width: "200px" }}
              onChange={handleStatusSelect}
            >
              <Option value="In progress">In progress</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Due">Due</Option>
            </Select>
          </Form.Item>
        )} */}
        <Form.Item className="d-flex justify-content-end ">
          <Button
            htmlType="submit"
            disabled={loading}
            style={{ color: "#ffffff", backgroundColor: "#46604A" }}
          >
            Update
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
