import React, { useEffect, useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Image, Modal, Select, Table, Tooltip } from "antd";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";

export default function WarehouseSurvey() {
  const { Option } = Select;
  const [warehouseSurvey, setWarehouseSurvey] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [viewModal, setViewModal] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [surveyDistinctValues, setSurveyDistinctValues] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://script.google.com/macros/s/AKfycbwL3tcHx7ngiEnaqCQu-7kqkwgvMeFs1brYa_AneJYT67cM8T0lGGdmYq00tUu-3sLL/exec"
    )
      .then((response) => response.json())
      .then((data) => {
        setSurveyDistinctValues(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleDateRange = (value) => {
    setSelectedDateRange(value);
  };
  const handleDistrict = (value) => {
    setSelectedDistrict(value);
  };
  const handleType = (value) => {
    setSelectedType(value);
  };
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTableLoading(true);
        const response = await axios.get(
          `https://script.google.com/macros/s/AKfycbyiaXPfKNzt6I4aMAuIi0-1btkNBYJfl86Osj6-pu6PYTjvWtHUxSpUbIKoGzX9Xt1f/exec?page=${currentPage}&district=${selectedDistrict}&type=${selectedType}&dateRange=${JSON.stringify(
            selectedDateRange
          )}`
        );
        setWarehouseSurvey(response?.data?.data);
        setTotalItems(response?.data?.totalItemCount);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setTableLoading(false);
      }
    };

    fetchData();
  }, [currentPage, selectedDateRange, selectedDistrict, selectedType]);

  const handleViewModal = (record) => {
    setViewModal(true);
    setModalData(record);
  };

  const warehouseColumns = [
    {
      title: "Survey Date",
      width: "8%",
      render: (record) => {
        const date = new Date(record?.Timestamp);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Type",
      width: "12%",
      dataIndex: "Type_Center_Chatal_Warehouse",
    },
    {
      title: "Shop Owner Name",
      width: "12%",
      dataIndex: "Shop_Owner_Name",
    },

    {
      title: "Phone Number",
      width: "12%",
      render: (record) => {
        return "0" + record?.Contract_No;
      },
    },

    {
      title: "District",
      dataIndex: "District_Name",
      width: "10%",
    },
    {
      title: "Center Length(ft)",
      dataIndex: "Center_Length_ft",
      width: "10%",
    },
    {
      title: "Center Width (ft)",
      dataIndex: "Center_Width_ft",
      width: "10%",
    },
    {
      title: "Center Height(ft)",
      dataIndex: "Center_Height_ft",
      width: "10%",
    },
    {
      title: "Area(sqft)",
      dataIndex: "Area_sqft",
      width: "10%",
    },
    {
      title: "Estimated Storage(MT)",
      dataIndex: "Estimated_Storage_MT",
      width: "10%",
    },
    {
      title: "Center Rent(BDT)",
      dataIndex: "Center_Rent_BDT",
      width: "10%",
    },
    {
      title: "FO Name",
      dataIndex: "Officer_Incharge_Name",
      width: "10%",
    },
    {
      title: "Action",
      key: "actions",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title="View Details">
            <Button
              className="d-flex align-items-center"
              onClick={() => handleViewModal(record)}
            >
              View <EyeOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Warehouse Survey"
        centered
        open={viewModal}
        onCancel={() => {
          setViewModal(false);
        }}
        width={"65%"}
        footer={null}
      >
        <div
          className="my-3 py-1 text-center"
          style={{ backgroundColor: "#46604A", color: "#ffffff" }}
        >
          Warehouse Information
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Type of Warehouse</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Type_Center_Chatal_Warehouse}
                </td>
              </tr>
              <tr>
                <th className="p-2">Shop Owner Name</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Shop_Owner_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Phone Number</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Contract_No}
                </td>
              </tr>
              <tr>
                <th className="p-2">National ID</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.NID}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">District</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.District_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Upazila</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Upazila_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">Union</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Union_Name}
                </td>
              </tr>
              <tr>
                <th className="p-2">GPS</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.GPS}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container">
          <div
            className="row mt-3"
            style={{ backgroundColor: "lightgray", color: "#46604A" }}
          >
            <div className="col-md-4 border text-center py-1">Inside</div>
            <div className="col-md-4 border text-center py-1">Outside</div>
            <div className="col-md-4 border text-center py-1">Roadside</div>
          </div>
          <div className="row">
            <div className="col-md-4 border">
              <Image src={modalData?.Photo_Inside} />
            </div>
            <div className="col-md-4 border">
              <Image src={modalData?.Photo_Outside} />
            </div>
            <div className="col-md-4 border">
              <Image src={modalData?.Photo_Road} />
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-between my-3"
          style={{ backgroundColor: "lightgray", color: "#46604A" }}
        >
          <div className="w-50 border text-center py-1">
            Center Measurements
          </div>
          <div className="w-50 border text-center py-1">
            Price and facilities
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Center Length (ft)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Center_Length_ft}
                </td>
              </tr>
              <tr>
                <th className="p-2">Center Width (ft)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Center_Width_ft}
                </td>
              </tr>
              <tr>
                <th className="p-2">Center Height (ft)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  0{modalData?.Center_Height_ft}
                </td>
              </tr>
              <tr>
                <th className="p-2">Area (sqft)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Area_sqft}
                </td>
              </tr>
              <tr>
                <th className="p-2">Estimated Storage (MT)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Estimated_Storage_MT}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className="w-50 table-bordered"
            style={{ border: "0.3px solid lightgray ", color: "#46604A" }}
          >
            <tbody className="m-5">
              <tr>
                <th className="p-2 w-25">Center Rent(BDT)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Center_Rent_BDT}
                </td>
              </tr>
              <tr>
                <th className="p-2">Advance Method (month)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Advance_Method_month}
                </td>
              </tr>
              <tr>
                <th className="p-2">Agreement time (Years)</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Agreement_time_Years}
                </td>
              </tr>
              <tr>
                <th className="p-2">Washroom Facilities</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Washroom_Facilities}
                </td>
              </tr>
              <tr>
                <th className="p-2">Remarks</th>
                <td className="p-2" style={{ backgroundColor: "#E6E6E6" }}>
                  {modalData?.Remarks}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>WAREHOUSE SURVEY DETAILS</h5>
        </div>

        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="d-flex border-bottom mb-4 justify-content-end mx-2">
            <div className="ms-3 mb-2 mt-4">
              <p className="m-0">Date: </p>
              <DateRangePicker
                onChange={handleDateRange}
                value={selectedDateRange}
                style={{ width: "250px" }}
                showOneCalendar
              />
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">District: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleDistrict}
                value={selectedDistrict}
              >
                <Option value="">All Districts</Option>
                {surveyDistinctValues?.distinctDistrict?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="ms-2 mb-2 mt-4">
              <p className="m-0">Warehouse Type: </p>
              <Select
                style={{ width: "250px" }}
                placeholder="Select location"
                showSearch
                onChange={handleType}
                value={selectedType}
              >
                <Option value="">All Types</Option>
                {surveyDistinctValues?.distinctWarehousetype?.map(
                  (district, index) => (
                    <Option value={district} key={index}>
                      {district}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
          <Table
            columns={warehouseColumns}
            dataSource={warehouseSurvey}
            loading={tableLoading}
            rowKey={(record) => record.Date}
            pagination={{
              current: currentPage,
              total: totalItems,
              pageSize: 10, // Set the number of items per page
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </>
  );
}
